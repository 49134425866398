import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';


const AlreadyVerifiedLink = () => {
    const navigate = useNavigate()

    return (
        <div style={{width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Result
                status="warning"
                title="User not found or already verified."
                extra={
                <Button type="primary" key="console" onClick={()=>navigate("/login")}>
                    Click to Login
                </Button>
                }
            />
        </div>
    );
};

export default AlreadyVerifiedLink;