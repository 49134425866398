import React, { useState } from 'react';
import "./verifyOtp.css"
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { HandleError, HandleSuccess } from '../../helpers/toast.helper';
import { ToastContainer } from 'react-toastify';
import { baseApiUrl } from '../../constants/environment.constants';
import Loader from '../../components/Loader/Loader';



const VerifyOTP = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)

    // useState for invalid credentials error
    const [invalidCredErr, setInvalidCredErr] = useState("");

    const postFormDataHandler = async (payload) => {
        try {
          setIsLoading(true);

          const resp = await axios.post(
            baseApiUrl + "verify-otp",
            payload
          );
          
          setTimeout(()=>{
            setIsLoading(false);
          }, 1000);
    
          if (resp?.data?.success === false) {
            HandleError(resp?.data?.message);
            // setTimeout(() => {
            //   navigate("/reset-password");
            // }, 5000);
          }

          if (resp?.data?.success === true) {
            HandleSuccess(resp?.data?.message);
            // setTimeout(() => {
              navigate("/reset-password");
            // }, 1000);
          }
        } catch (err) {
          console.log("Error while login at front-end side-->", err);
          if (err?.response?.data?.success === false) {
            setInvalidCredErr(err?.response?.data?.message);
            setIsLoading(false);
          } else {
            setInvalidCredErr("");
          }
        }
      };

      const loginForm = useFormik({
        initialValues: {
          otp: "",
        //   password: "",
        },
        onSubmit: (values) => {
          let clientEmail = localStorage.getItem("clientEmail")
          let payload = {
            email : clientEmail,
            otp: values?.otp,
          };
          postFormDataHandler(payload);
        },
        validate: (values) => {
          let errors = {};
    
          if (!values.otp) {
            errors.otp = "OTP is required";
          } 
        //   else if (
        //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        //   ) {
        //     errors.email = "Invalid email address";
        //   }
    
    
          return errors;
        },
      });

    return (
        <div className="bg-blue">
          <ToastContainer />
          <Loader isLoading={isLoading}/>
          <div className="container">
            <div className="row p-3 overlay">
              <div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 my-auto justify-content-center text-center">
                <div className="fp-sectionStartForm px-5 py-4">
                  <img
                    src="/cobalt-icon.svg"
                    alt="fintrack-logo"
                    className="form-header img-fluid"
                  />
                  <p className="fp-title-text py-1">Verify OTP</p>

                  {invalidCredErr && invalidCredErr.length ? (
                    <div className="signup-error">{invalidCredErr}</div>
                  ) : null}
    
                  <form onSubmit={loginForm.handleSubmit}>

                    <div className="otp-form-label-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter OTP"
                        name="otp"
                        value={loginForm.values.otp}
                        onChange={loginForm.handleChange}
                        onBlur={loginForm.handleBlur}
                      />
                      <label>Enter OTP</label>
                    </div>
                    {loginForm.errors.otp ? (
                      <div className="login-error">{loginForm.errors.otp}</div>
                    ) : null}

                    <p className="fp-mid-text">
                        Please enter OTP sent to your email.
                    </p>
    
                    <div className="otp-form-label-group mt-4">
                      <button
                        type="submit"
                        className="btn btn-info font-weight-bold text-uppercase w-50"
                      >
                        submit
                      </button>
                    </div>
                  </form>
                  
                  <p className="s-text text-decoration pointer mt-4" onClick={()=> navigate("/forgot-password")}>
                    Need to go the previous page
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};

export default VerifyOTP;