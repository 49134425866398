import React, { useState } from 'react';
import "./resetPassword.css"
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer } from 'react-toastify';
import { HandleSuccess } from '../../helpers/toast.helper';
import { baseApiUrl } from '../../constants/environment.constants';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoMdEyeOff } from "react-icons/io";
import Loader from '../../components/Loader/Loader';


const ResetPassword = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)

    // useState for invalid credentials error
    // const [invalidCredErr, setInvalidCredErr] = useState("");

    /*useState for show and hide password text */
    const [showPassword, setShowPassword] = useState(false);

    const postFormDataHandler = async (payload) => {
        try {
          setIsLoading(true);
          const resp = await axios.patch(
            baseApiUrl + "reset-password",
            payload
          );
          
          setTimeout(()=>{
            setIsLoading(false);
          }, 1000);
    
          if (resp?.data?.success === true) {
            HandleSuccess(resp?.data?.message);
            setTimeout(() => {
              navigate("/login");
            }, 2000);
            localStorage.removeItem("clientEmail")
          }
        } catch (err) {
          console.log("Error while login at front-end side-->", err);
          setIsLoading(false)
        }
      };

      const loginForm = useFormik({
        initialValues: {
          password: "",
          newPassword: "",
        },
        onSubmit: (values) => {
          let clientEmail = localStorage.getItem("clientEmail")
          let payload = {
            email : clientEmail,
            newPassword: values?.newPassword,
          };
          postFormDataHandler(payload);
        },
        validate: (values) => {
          let errors = {};
    
          if (!values.password) {
            errors.password = "Please enter new password.";
          }else if (values.password.length < 8) {
            errors.password = "New password must be at least 8 characters long";
          }else if (/\s/.test(values.password)) {
            errors.password = "Password should not contain spaces.";
          }  

          if (!values.newPassword) {
            errors.newPassword = "Please confirm new password.";
          }else if (values.newPassword.length < 8) {
            errors.newPassword = "New password must be at least 8 characters long";
          } else if (values.password !== values.newPassword) {
            errors.newPassword = "Passwords does not match.";
          }else if (/\s/.test(values.newPassword)) {
            errors.newPassword = "Password should not contain spaces.";
          }

        //   else if (
        //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        //   ) {
        //     errors.email = "Invalid email address";
        //   }
    
    
          return errors;
        },
      });


    return (
        <div className="bg-blue">
          <ToastContainer />
          <Loader isLoading={isLoading}/>
          <div className="container">
            <div className="row p-3 overlay">
              <div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 my-auto justify-content-center text-center">
                <div className="fp-sectionStartForm px-5 py-4">
                  <img
                    src="/cobalt-icon.svg"
                    alt="fintrack-logo"
                    className="form-header img-fluid"
                  />
                  <p className="fp-title-text py-1">Reset Password</p>
    
                  <form onSubmit={loginForm.handleSubmit}>

                    <div className="otp-form-label-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter New Password"
                        name="password"
                        value={loginForm.values.password}
                        onChange={loginForm.handleChange}
                        onBlur={loginForm.handleBlur}
                      />
                      <label>Enter New Password</label>
                    </div>
                      {loginForm.touched.password && loginForm.errors.password ? (
                        <div className="login-error">{loginForm.errors.password}</div>
                      ) : null}

                    
                    <div className="otp-form-label-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        style={{paddingRight : "40px"}}
                        className="form-control"
                        placeholder="Confirm New Password"
                        name="newPassword"
                        value={loginForm.values.newPassword}
                        onChange={loginForm.handleChange}
                        onBlur={loginForm.handleBlur}
                      />
                      <label>Confirm New Password</label>
                      <div
                        className="signup-password-toggle-icon"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <MdOutlineRemoveRedEye className="signup-passwod-icon" /> : <IoMdEyeOff className="signup-passwod-icon"/>}
                    </div>
                      {loginForm.touched.newPassword && loginForm.errors.newPassword ? (
                        <div className="login-error">{loginForm.errors.newPassword}</div>
                      ) : null}
                    </div>


                    {/* <p className="fp-mid-text">
                        Please enter OTP sent to your email.
                    </p> */}
    
                    <div className="otp-form-label-group mt-4">
                      <button
                        type="submit"
                        className="btn btn-info font-weight-bold text-uppercase w-50"
                      >
                        submit
                      </button>
                    </div>
                  </form>
                  
                  <p className="s-text text-decoration pointer mt-4" onClick={()=> navigate("/verify-otp")}>
                    Need to go the previous page
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};

export default ResetPassword;