import { Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/dashboard/Dashboard';
import NewLoginPage from './pages/NewLogin/NewLoginPage';
import NewSignupPage from './pages/NewSignUp/NewSignupPage';
import Transactions from './pages/transactions/Transactions';
import NewVerifyPerson from './pages/newVerifyPerson/NewVerifyPerson';
import VerifyBusiness from './pages/verifyBusiness/VerifyBusiness';
import NewVerifyDocs from './pages/NewVerifyDocs/NewVerifyDocs';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import VerifyOTP from './pages/verifyOTP/VerifyOTP';
import ResetPassword from './pages/resetPassword/ResetPassword';
import useAuth from './hooks/useAuth';
import NotFound from './NotFound';
import AlreadyVerifiedLink from './components/navbar/verifiedLink/AlreadyVerifiedLink';
import NewVerifyWallet from './pages/NewVerifyWallet/NewVerifyWallet';
import PaymentSuccess from './components/PaymentSuccess/PaymentSuccess';
import PaymentFail from './components/PaymentSuccess/PaymentFail';
import ChainButtons from './components/chainButtons/ChainButtons';
import TransactionButtons from './components/chainButtons/TransactionButtons';
import InvestigationButtons from './components/chainButtons/InvestigationButtons';
import Verifications from './pages/verifications/Verifications';
import RiskDetails from './pages/riskDetails/RiskDetails';


function App() {
  const { isLoggedIn } = useAuth();

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={isLoggedIn ? <Dashboard /> : <NewLoginPage />} />
        <Route path='/signup' element={<NewSignupPage />}/>
        <Route path='/login' element={<NewLoginPage />}/> 
        <Route path='/forgot-password' element={<ForgotPassword />}/>
        <Route path='/verify-otp' element={<VerifyOTP />}/>
        <Route path='/reset-password' element={<ResetPassword />}/>
        <Route path="/transactions" element={isLoggedIn ? <Transactions /> : <NewLoginPage />} />
        <Route path="/verify-docs" element={isLoggedIn ? <NewVerifyDocs /> : <NewLoginPage />} />
        <Route path="/verify-person" element={isLoggedIn ? <NewVerifyPerson /> : <NewLoginPage />} />
        <Route path="/verify-business" element={isLoggedIn ? <VerifyBusiness /> : <NewLoginPage />} />
        <Route path="/verify-wallet" element={isLoggedIn ? <NewVerifyWallet /> : <NewLoginPage />}>
          <Route path="check-wallet" element={<ChainButtons />}/>
          <Route path="check-transaction" element={<TransactionButtons />}/>
          <Route path="investigation" element={<InvestigationButtons />}/>
        </Route>
        <Route path="/verifications" element={isLoggedIn ? <Verifications /> : <NewLoginPage />} />
        <Route path="/risk-details" element={isLoggedIn ? <RiskDetails /> : <NewLoginPage />} />
        <Route path="/verify-link" element={<AlreadyVerifiedLink /> } />
        <Route path="/payment-success" element={<PaymentSuccess /> } />
        <Route path="/payment-fail" element={<PaymentFail /> } />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
