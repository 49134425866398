import React, { useRef, useState } from "react";
import "./riskDetails.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { FaRegFilePdf } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Navbar from "../../components/navbar/Navbar";
import { countryCodes } from "../../helpers/countryCodes.helper";
import Loader from "../../components/Loader/Loader";
import { FaListUl } from "react-icons/fa6";
import { AiFillThunderbolt } from "react-icons/ai";
import { coinsPrecisionRates } from "../../helpers/coinsPrecisionRates.helper";
import { walletTxnCheckReport } from "../../helpers/pdfHelpers/walletTxnCheckReport.helper";

const RiskDetails = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const jsonDataString = location?.state?.data;
  const jsonData = JSON.parse(jsonDataString || "{}");
//   console.log("jsonData==>", jsonData);

  let rScore = jsonData?.report?.data?.riskscore * 100 || 0;
  const riskPercentage = Number(rScore)?.toFixed(0);
  const chartRef = useRef(null);

  let assetImg = "";
  let assetName = "";
  if (jsonData?.asset === "BTC") {
    assetImg = "/coins/bitcoin.svg";
    assetName = "Bitcoin";
  } else if (jsonData?.asset === "ETH") {
    assetImg = "/coins/etherium.svg";
    assetName = "Ethereum";
  } else if (jsonData?.asset === "BSC") {
    assetImg = "/coins/pnb-chain.svg";
    assetName = "BNB Chain";
  } else if (jsonData?.asset === "LTC") {
    assetImg = "/coins/lite-coin.svg";
    assetName = "Litecoin";
  } else if (jsonData?.asset === "BCH") {
    assetImg = "/coins/bitcoin-cash.svg";
    assetName = "Bitcoin Cash";
  } else if (jsonData?.asset === "XRP") {
    assetImg = "/coins/ripple.svg";
    assetName = "Ripple";
  } else if (jsonData?.asset === "TetherOMNI") {
    assetImg = "/coins/tether-omni.svg";
    assetName = "TetherOMNI";
  } else if (jsonData?.asset === "ETC") {
    assetImg = "/coins/etherium-classic.svg";
    assetName = "Ethereum Classic";
  } else if (jsonData?.asset === "BSV") {
    assetImg = "/coins/bitcoin-sv.svg";
    assetName = "Bitcoin SV";
  } else if (jsonData?.asset === "DOGE") {
    assetImg = "/coins/dogecoin.svg";
    assetName = "Dogecoin";
  } else if (jsonData?.asset === "TRX") {
    assetImg = "/coins/tron.svg";
    assetName = "TRON";
  } else if (jsonData?.asset === "MATIC") {
    assetImg = "/coins/polygon.svg";
    assetName = "Polygon";
  } else if (jsonData?.asset === "ADA") {
    assetImg = "/coins/cardano.svg";
    assetName = "Cardano";
  } else if (jsonData?.asset === "ZEC") {
    assetImg = "/coins/zcash.svg";
    assetName = "Zcash";
  } else if (jsonData?.asset === "SOL") {
    assetImg = "/coins/solana.svg";
    assetName = "Solana";
  } else if (jsonData?.asset === "ALGO") {
    assetImg = "/coins/algorand.svg";
    assetName = "Algorand";
  } else if (jsonData?.asset === "ARB") {
    assetImg = "/coins/arbitirum.svg";
    assetName = "Arbitrum";
  } else if (jsonData?.asset === "DOT") {
    assetImg = "/coins/palkodot.svg";
    assetName = "Polkadot";
  } else if (jsonData?.asset === "AVAX") {
    assetImg = "/coins/avalanche.svg";
    assetName = "Avalanche";
  } else if (jsonData?.asset === "XLM") {
    assetImg = "/coins/stellar.svg";
    assetName = "Stellar";
  } else if (jsonData?.asset === "TON") {
    assetImg = "/coins/ton.svg";
    assetName = "TON";
  } else if (jsonData?.asset === "NEAR") {
    assetImg = "/coins/near.svg";
    assetName = "Near";
  } else if (jsonData?.asset === "XTZ") {
    assetImg = "/coins/tezos.svg";
    assetName = "Tezos";
  } else {
    assetImg = "/pdf/notLogo.png";
  }

  let direction = jsonData?.report?.data?.direction || "";
  let capitalizedDirection =
    direction.charAt(0).toUpperCase() + direction.slice(1);

  const originalValue = jsonData?.report?.data?.amount || 0;
  const convertedValue =
    originalValue /
    Math.pow(
      10,
      jsonData?.report?.data?.tokenDetails?.precision ||
        coinsPrecisionRates(jsonData?.asset)
    );

  let riskyVolumeFiat = jsonData?.report?.data?.risky_volume_fiat;
  let convertedRiskyVolumeFiat = riskyVolumeFiat / Math.pow(10, 2);

  let riskyVolume = jsonData?.report?.data?.risky_volume;
  let convertedRiskyVolume =
    riskyVolume /
    Math.pow(
      10,
      jsonData?.report?.data?.tokenDetails?.precision ||
        coinsPrecisionRates(jsonData?.asset)
    );

  const handleDownloadPdf = () => {
    try {
      setIsLoading(true);

      walletTxnCheckReport(jsonData);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (err) {
      console.log("Error while download Wallet/Txn Check pdf", err.message);
      setIsLoading(false);
    }
  };

  let riskText = "";
  if (riskPercentage <= 20) {
    riskText = "Low Risk";
  } else if (riskPercentage > 20 && riskPercentage <= 50) {
    riskText = "Medium Risk";
  } else {
    riskText = "High Risk";
  }

  return (
    <div>
      <Navbar />
      <Loader isLoading={isLoading} />
      <div className="vw-wrapper flex-grow-1 p-4">
        <div className="row vw-title-btn-container">
          {/* <!-- Title --> */}
          <div className="col-lg-4 col-md-12 col-sm-12 mt-2">
            <div className="d-flex vw-title-icon-contnr">
              <FaListUl style={{ fontSize: "18px" }} />
              <h5 className="pl-2 mt-2 vw-title">Risk Check Result</h5>
            </div>
          </div>

          {/* col-md-10 */}
          <div className="col-lg-3 col-sm-12 mt-2">
            <button
              onClick={() => navigate("/transactions")}
              className="v-btn v-btn-info form-control font-weight-bold w-100"
              style={{height: "38px"}}
            >
              <ArrowLeftOutlined /> Back to Dashboard
            </button>
          </div>
        </div>
      </div>

      <div className="download-btn-container mt-3 mb-2">
        <div className="download-pdf-btn" onClick={handleDownloadPdf}>
          <FaRegFilePdf size={20} />
          <span>Download PDF</span>
        </div>
      </div>

      <div className="AmlCheckDetails_main__top__ihEup">
        <div className="AmlCheckDetails_main__title__J42hv">Risk Result</div>
        <div className="AmlCheckDetails_details__checked__BWRXA">
          Updated:{" "}
          {moment(
            jsonData?.report?.data?.timestamp || jsonData?.updatedAt
          ).format("DD.MM.YYYY HH:mm")}
        </div>
        <div className="AmlCheckDetails_main__crypto__PWwhW">
          <div className="CryptoLabelPill_crypto__gqseZ">
            <img
              src={assetImg}
              alt=""
              className="CryptoLabelPill_icon__DgzBG"
            />
            <div className="CryptoLabelPill_inner__JJrXN">
              <div className="CryptoLabelPill_label__QD6Sq">{assetName}</div>
            </div>
            <div className="CryptoLabelPill_abbr__J2f26">
              {jsonData?.report?.data?.tokenDetails?.code ||
                jsonData?.report?.data?.asset}
            </div>
          </div>
        </div>
        <div className="AmlCheckDetails_details__Txn_Address">
          {jsonData?.serviceType === "Wallet Check"
            ? `${jsonData?.asset} Address`
            : `${jsonData?.asset} Tx`}
          :{" "}
          {jsonData?.serviceType === "Wallet Check"
            ? `${jsonData?.report?.data?.address}`
            : `${jsonData?.report?.data?.tx}`}
        </div>
      </div>
      {!jsonData?.report?.data?.riskscore && (
        <div className="AmlCheckDetails_risk__title__sMkVl">No risk score</div>
      )}

      {jsonData?.serviceType === "Transaction Check" && (
        <div className="AmlCheckDetails_risk__counterparty_note__puBpE">
          Counterparty Risk Score
        </div>
      )}

      {/* RISK GRAPH */}
      {jsonData?.report?.data?.riskscore && (
        <div className="AmlCheckDetails_risk__W4RvJ" ref={chartRef}>
          {/* <div className='AmlCheckDetails_risk__counterparty_note__puBpE'>Counterparty Risk Score</div> */}

          {/* Graph Container */}
          {/* <div className='AmlCheckDetails_risk__indicator__1nR0C RiskIndicator_risk--suspicious__1qoPO RiskIndicator_risk__mT1lM'> */}
          <div
            className={`AmlCheckDetails_risk__indicator__1nR0C ${
              riskPercentage <= 20
                ? "RiskIndicator_risk--trusted__lMgEX"
                : riskPercentage <= 50
                ? "RiskIndicator_risk--suspicious__1qoPO"
                : "RiskIndicator_risk--high__lMgEX"
            } RiskIndicator_risk__mT1lM`}
          >
            <div className="RiskIndicator_inner__qKAk9">
              <div className="RiskIndicator_gray__eFAyj">
                <div
                  className="RiskIndicator_level__aYnkS"
                  style={{
                    transform: `rotate(${(180 / 100) * riskPercentage}deg)`,
                  }}
                >
                  <div className="RiskIndicator_level-fill__AZoah"></div>
                </div>
              </div>
            </div>

            <div
              className="RiskIndicator_level-white__A27jJ"
              style={{
                transform: `rotate(${(180 / 100) * riskPercentage}deg)`,
              }}
            >
              <div className="RiskIndicator_level-arrow__4lVbK">
                <div className="RiskIndicator_level-arrow-in__3X3xF"></div>
              </div>
            </div>

            <div className="RiskIndicator_value__8sBvk">
              {/* svg icon of flash */}
              {/* <div className='RiskIndicator_value__8sBvk'> */}
              {/* <div className='RiskIndicator_value__icon__pKTzZ SvgIcon_icon__JQ-Ts'> */}
              <AiFillThunderbolt className="RiskIndicator_value__icon__pKTzZ SvgIcon_icon__JQ-Ts" />
              {/* </div> */}
              <span className="RiskIndicator_value__number__S1vyK">
                {riskPercentage}%
              </span>
              {/* </div> */}
            </div>
            <div className="RiskIndicator_from__SPAa-">0</div>
            <div className="RiskIndicator_to__ObTJL">100</div>
          </div>

          <div
            className={`AmlCheckDetails_risk__title__sMkVl ${
              riskPercentage <= 20
                ? "RiskIndicator_risk--trusted__lMgEX"
                : riskPercentage <= 50
                ? "RiskIndicator_risk--suspicious__1qoPO"
                : "RiskIndicator_risk--high__lMgEX"
            } RiskIndicator_risk__mT1lM`}
          >
            {riskText}
          </div>
        </div>
      )}

      <div
        className="main__right-block"
        style={{ paddingTop: "0", marginTop: "0" }}
      >
        <div className="main-content-wrappe">
          <div className="verification-inside">
            {/* Top Details Section */}
            <div className="inside-main-block" style={{ marginTop: "0" }}>
              <div className="inside-title">
                <h2>Transaction Details</h2>
                {/* <p>Transaction Details</p> */}
              </div>

              <div className="inside-info">
                <div
                  className="inside-left"
                  style={{
                    borderBottom: "none",
                    marginBottom: "0",
                    paddingBottom: "0",
                  }}
                >
                  <div className="inside-row">
                    <div>Address</div>
                    <div>{jsonData?.report?.data?.address || "-"}</div>
                  </div>

                  {/* In case of Wallet Check */}
                  {jsonData?.serviceType === "Wallet Check" &&
                    jsonData?.report?.data?.addressDetailsData?.balance_approximate >= 0 && (
                      <div className="inside-row">
                        <div>Balance</div>
                        <div>
                          {jsonData?.report?.data?.addressDetailsData
                            ?.balance_approximate >= 0
                            ? `${jsonData?.report?.data?.addressDetailsData?.balance_approximate} ${jsonData?.asset}`
                            : "-"}
                        </div>
                      </div>
                    )}

                  {/* In case of Txn Check */}
                  {jsonData?.serviceType === "Transaction Check" && (
                    <div className="inside-row">
                      <div>{capitalizedDirection}</div>
                      <div>{`${convertedValue} ${
                        jsonData?.report?.data?.tokenDetails?.code ||
                        jsonData?.report?.data?.asset
                      }`}</div>
                      {/* <div>73'735.00 USDT</div> */}
                    </div>
                  )}
                  <div className="inside-row">
                    <div>Status</div>
                    <div className="GeneralInfo_general__list__fIXNi_dl_dd ">
                      <img
                        src="/pdf/trusted.png"
                        alt=""
                        style={{ height: "16px", width: "16px" }}
                      />
                      <span className="GeneralInfo_general__list">success</span>
                    </div>
                  </div>
                  {jsonData?.serviceType === "Transaction Check" &&
                    jsonData?.report?.data?.risky_volume > 0 &&
                    jsonData?.report?.data?.risky_volume_fiat > 0 && (
                      <div className="inside-row">
                        <div>Risky amount</div>
                        {/* <div>3338.10 USD • 3'338.10 USDT</div> */}
                        <div>{`${convertedRiskyVolumeFiat?.toFixed(2)} ${
                          jsonData?.report?.data?.fiat_code_effective?.toUpperCase() ||
                          ""
                        } • ${convertedRiskyVolume?.toFixed(2)} ${
                          jsonData?.report?.data?.tokenDetails?.code || ""
                        }`}</div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskDetails;
