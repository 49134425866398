import { jsPDF } from "jspdf";
import moment from "moment";
import "jspdf-autotable";
// import axios from 'axios';
// import { declineReasons } from "../declineReasons.helper";
import { countryCodes } from "../countryCodes.helper";
// import html2canvas from "html2canvas";

export const generateKycFullResult = async(data, applicantInfo) => {
  // console.log("data pdf==>", data);
  // console.log("applicantInfo pdf==>", applicantInfo);

  const pdf = new jsPDF();

  // const fontData = "../../fonts/Inter-Regular.ttf";
  // pdf.addFont(fontData, 'Inter', 'normal')
  
  
  /**PDF Header */
  const logoPath = "/fin-logo.png";
  pdf.addImage(logoPath, "PNG", 14, 10, 40, 12);


  pdf.setFont("Inter", "normal");
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(10);
  pdf.text("15130981 Canada Inc", 15, 25);
  pdf.textWithLink("From: finance@fintracking.ca ", 15, 30, {
      url: "mailto:finance@fintracking.ca",
  });

  pdf.setFontSize(16);
  pdf.setFont("Inter", "bold");
  pdf.text("Verification Report", 193, 15, { align: "right" });
  pdf.setFont("Inter", "normal");

  pdf.setFontSize(10);
  pdf.text(`Document ID : ${data?._id?.toUpperCase() || ""}`, 193, 20, { align: "right" });

  pdf.setFontSize(10);
  pdf.text(`From : ${moment(applicantInfo?.created_at || data?.updatedAt).format('DD.MM.YYYY HH:mm')}`, 193, 25, { align: "right" });
  //   pdf.text("Report Generation Block Height", 193, 30, { align: "right" });
  pdf.setTextColor(0, 0, 255); // text color to Blue
  pdf.textWithLink("www.fintracking.ca", 193, 30, {
      url: "http://www.fintracking.ca",
      align: "right",
  });




  pdf.setTextColor(0, 0, 0); // text color set back to black

  pdf.setDrawColor(192, 192, 192);
  pdf.line(15, 34, 193, 34);



  /** To Make cuntents dynamic on Y-axis */
  let currentY = 40; // Initial Y-axis position
  const gap = 10; // Gap between each content
  const pageHeight = pdf.internal.pageSize.height;

  // Update Y-axis position for the next content
  const updateY = (yOffset) => {
    if (currentY + yOffset + gap > pageHeight - 20) {
        pdf.addPage();
        currentY = 20; // Reset Y-axis position for the new page
    } else {
        currentY += yOffset + gap;
    }
    return currentY;
};


  pdf.setFont("arial", "normal");

  pdf.setDrawColor(192, 192, 192); // Gray color
  // pdf.rect(4, 23, 200, 260);

  pdf.setFontSize(24);
  pdf.setTextColor(0, 0, 0);
  pdf.text("Verification Result", 15, updateY(0));

  pdf.setFontSize(12);
  pdf.setTextColor(0, 0, 0);
  pdf.text("Status", 15, updateY(10));


  /* 
        For Status Approved
    */
  if (data?.report?.verified === true) {
    pdf.setTextColor(51, 160, 121); // #33a079
    pdf.setFillColor(229, 244, 239); // #e5f4ef
    pdf.roundedRect(58, currentY - 5, 21.5, 7, 2, 2, "F"); // Draw filled rectangle as background
    pdf.text("Approved", 60, currentY);
  }

  /* 
        For Status Declined
    */
  if (data?.report?.verified === false) {
    pdf.setTextColor(224, 63, 101);
    pdf.setFillColor(244, 229, 229);
    pdf.roundedRect(58, currentY - 5, 19.5, 7, 2, 2, "F"); // Draw filled rectangle as background
    pdf.text("Declined", 60, currentY);
  }

  pdf.setTextColor(0, 0, 0);

  pdf.text("Verification ID", 15, updateY(0));
  pdf.setFont("arial", "bold");
  pdf.text(data?.report?.verification_id || "-", 58, currentY);
  pdf.setFont("arial", "normal");

  pdf.text("Application ID", 15, updateY(0));
  pdf.setFont("arial", "bold");
  pdf.text(data?.report?.applicant_id || "-", 58, currentY);
  pdf.setFont("arial", "normal");

  pdf.text("Price", 15, updateY(0));
  pdf.setFont("arial", "bold");
  pdf.text(
    `${data?.receipt?.transactions[0]?.amount?.total} ${data?.receipt?.transactions[0]?.amount?.currency}`,
    58,
    currentY
  );
  pdf.setFont("arial", "normal");

  pdf.text("Form", 15, updateY(0));
  pdf.setFont("arial", "bold");
  pdf.text(data?.serviceType || "-", 58, currentY);
  pdf.setFont("arial", "normal");

  pdf.text("Verification types", 15, updateY(0));
  pdf.setFont("arial", "bold");
  

  let optionSelected = Object.keys(data?.report?.verifications) ? Object.keys(data?.report?.verifications).toString() : "";
  let words = optionSelected.split(',');
  let formattedOptions = words.map(word => {
    return word.trim().replace(/_/g, ' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  });
  // let formattedOptionSelected = formattedOptions.join(', ');

  // console.log("formattedOptions===>", formattedOptions?.length)
  if(formattedOptions?.length > 7){
    pdf.text(`${formattedOptions[0] || ""}, ${formattedOptions[1]  || ""}, ${formattedOptions[2]  || ""}, ${formattedOptions[3]  || ""}, ${formattedOptions[4]  || ""}, ${formattedOptions[5]  || ""}, ${formattedOptions[6]  || ""},` || "-", 58, currentY);
    pdf.text(`${formattedOptions[7] || ""}, ${formattedOptions[8]  || ""}, ${formattedOptions[9]  || ""}` || "-", 58, updateY(-5));
  }else{
    pdf.text(`${formattedOptions?.join(', ') || ""}` || "-", 58, currentY);
  }
  pdf.setFont("arial", "normal");

  pdf.line(15, updateY(-5), 193, currentY);

  if(applicantInfo?.first_name || data?.serviceType === "KYC Full" || data?.serviceType === "KYC Lite"){
    pdf.text("First Name", 15, updateY(-2));
    pdf.setFont("arial", "bold");
    pdf.text(applicantInfo?.first_name || "-", 58, currentY);
    pdf.setFont("arial", "normal");
  }

  if(applicantInfo?.middle_name){
    pdf.text("Middle Name", 15, updateY(0));
    pdf.setFont("arial", "bold");
    pdf.text(applicantInfo?.middle_name || "-", 58, currentY);
    pdf.setFont("arial", "normal");
  }

  if(applicantInfo?.last_name || data?.serviceType === "KYC Full" || data?.serviceType === "KYC Lite"){
    pdf.text("Last Name", 15, updateY(0));
    pdf.setFont("arial", "bold");
    pdf.text(applicantInfo?.last_name || "-", 58, currentY);
    pdf.setFont("arial", "normal");
  }

  if(applicantInfo?.dob || data?.serviceType === "KYC Full" || data?.serviceType === "KYC Lite"){
    pdf.text("Date of birth", 15, updateY(0));
    pdf.setFont("arial", "bold");
    pdf.text(applicantInfo?.dob || "-", 58, currentY);
    pdf.setFont("arial", "normal");
  }

  if(applicantInfo?.company_name){
    pdf.text("Company name", 15, updateY(0));
    pdf.setFont("arial", "bold");
    pdf.text(applicantInfo?.company_name || "-", 58, currentY);
    pdf.setFont("arial", "normal");
  }

  if(applicantInfo?.business_activity){
    pdf.text("Business activity", 15, updateY(0));
    pdf.setFont("arial", "bold");
    pdf.text(applicantInfo?.business_activity[0]?.label || "-", 58, currentY);
    pdf.setFont("arial", "normal");
  }

  // if(applicantInfo?.registration_country){
    pdf.text("Registration country", 15, updateY(0));
    pdf.setFont("arial", "bold");
    pdf.text(countryCodes[applicantInfo?.residence_country || applicantInfo?.registration_country] || "-", 58, currentY);
    pdf.setFont("arial", "normal");
  // }

  if(applicantInfo?.email){
    pdf.text("Email", 15, updateY(0));
    pdf.setFont("arial", "bold");
    pdf.text(applicantInfo?.email || "-", 58, currentY);
    pdf.setFont("arial", "normal");
  }

  if(applicantInfo?.phone){
    pdf.text("Phone", 15, updateY(0));
    pdf.setFont("arial", "bold");
    pdf.text(applicantInfo?.phone || "-", 58, currentY);
    pdf.setFont("arial", "normal");
  }

  if(applicantInfo?.pep){
    pdf.text("PEP", 15, updateY(0));
    pdf.setFont("arial", "bold");
    pdf.text(applicantInfo?.pep === true ? "Yes" : "No" || "-", 58, currentY);
    pdf.setFont("arial", "normal");
  }

  
  // Create a set to keep track of the document types that have been printed
  const printedDocumentTypes = new Set();
  // console.log("printedDocumentTypes==>", printedDocumentTypes)

  if(applicantInfo?.documents){
    applicantInfo?.documents.forEach(async(doc) => {
      
      if (!printedDocumentTypes.has(doc.type)) {
        printedDocumentTypes.add(doc.type);

      if (doc.type === "DRIVERS_LICENSE") {
        pdf.line(15, updateY(-5), 193, currentY);
        pdf.setFontSize(13);
        pdf.setFont("arial", "bold");
        pdf.text("Drivers license", 15, updateY(-2));
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Document number", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(doc?.document_number || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Document issue date", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(doc?.issue_date || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Document expiry date", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(doc?.expiry_date || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        if(doc?.front_side){
          pdf.text("Front side", 15, updateY(0));
          pdf.setFont("arial", "normal");
          const front_side = doc?.front_side;
          pdf.setTextColor(0, 0, 255); // text color to Blue
          pdf.textWithLink("click to view", 58, currentY, { url: front_side, target: '_blank' });
          pdf.setFont("arial", "normal");
          pdf.setTextColor(0, 0, 0); // text color set back to black
        }

        if(doc?.back_side){
          pdf.text("Back side", 15, updateY(0));
          pdf.setFont("arial", "normal");
          const back_side = doc?.back_side;
          pdf.setTextColor(0, 0, 255); // text color to Blue
          pdf.textWithLink("click to view", 58, currentY, { url: back_side, target: '_blank' });
          pdf.setFont("arial", "normal");
          pdf.setTextColor(0, 0, 0); // text color set back to black
        }
      }

      if (doc.type === "REGISTRATION_COMPANY") {
        pdf.line(15, updateY(-5), 193, currentY);
        pdf.setFontSize(13);
        pdf.setFont("arial", "bold");
        pdf.text("Registration company", 15, updateY(-2));
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Document number", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(doc?.document_number || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        if(doc?.front_side){
          pdf.text("Front side", 15, updateY(0));
          pdf.setFont("arial", "normal");
          const front_side = doc?.front_side;
          pdf.setTextColor(0, 0, 255); // text color to Blue
          pdf.textWithLink("click to view", 58, currentY, { url: front_side, target: '_blank' });
          pdf.setFont("arial", "normal");
          pdf.setTextColor(0, 0, 0); // text color set back to black
        }

        if(doc?.back_side){
          pdf.text("Back side", 15, updateY(0));
          pdf.setFont("arial", "normal");
          const back_side = doc?.back_side;
          pdf.setTextColor(0, 0, 255); // text color to Blue
          pdf.textWithLink("click to view", 58, currentY, { url: back_side, target: '_blank' });
          pdf.setFont("arial", "normal");
          pdf.setTextColor(0, 0, 0); // text color set back to black
        }
      }


      if (doc.type === "COMPANY_LEGAL_ADDRESS") {
        pdf.line(15, updateY(-5), 193, currentY);
        pdf.setFontSize(13);
        pdf.setFont("arial", "bold");
        pdf.text("Company legal address", 15, updateY(-2));
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Document number", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(doc?.document_number || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        if(doc?.front_side){
          pdf.text("Front side", 15, updateY(0));
          pdf.setFont("arial", "normal");
          const front_side = doc?.front_side;
          pdf.setTextColor(0, 0, 255); // text color to Blue
          pdf.textWithLink("click to view", 58, currentY, { url: front_side, target: '_blank' });
          pdf.setFont("arial", "normal");
          pdf.setTextColor(0, 0, 0); // text color set back to black
        }

        if(doc?.back_side){
          pdf.text("Back side", 15, updateY(0));
          pdf.setFont("arial", "normal");
          const back_side = doc?.back_side;
          pdf.setTextColor(0, 0, 255); // text color to Blue
          pdf.textWithLink("click to view", 58, currentY, { url: back_side, target: '_blank' });
          pdf.setFont("arial", "normal");
          pdf.setTextColor(0, 0, 0); // text color set back to black
        }
      }

      // if(!printedDocumentTypes.has(doc.type)){
      //   printedDocumentTypes.add(doc.type);

        if (doc.type === "ADDRESS_DOCUMENT") {
          pdf.line(15, updateY(-5), 193, currentY);
          pdf.setFontSize(13);
          pdf.setFont("arial", "bold");
          pdf.text("Address document", 15, updateY(-2));
          pdf.setFont("arial", "normal");

          if(doc?.document_number){
            pdf.setFontSize(12);
            pdf.text("Document number", 15, updateY(0));
            pdf.setFont("arial", "bold");
            pdf.text(doc?.document_number || "-", 58, currentY);
            pdf.setFont("arial", "normal");
          }

          if(doc?.front_side){
            pdf.text("Front side", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const front_side = doc?.front_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: front_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }

          if(doc?.back_side){
            pdf.text("Back side", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const back_side = doc?.back_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: back_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }
        }
      // }


        if (doc.type === "FINANCIAL_DOCUMENT") {
          pdf.line(15, updateY(-5), 193, currentY);
          pdf.setFontSize(13);
          pdf.setFont("arial", "bold");
          pdf.text("Financial document", 15, updateY(-2));
          pdf.setFont("arial", "normal");

          if(doc?.income_sources){
            pdf.setFontSize(12);
            pdf.text("Income sources", 15, updateY(0));
            pdf.setFont("arial", "bold");
            pdf.text(doc?.income_sources[0]?.charAt(0).toUpperCase() + doc?.income_sources[0]?.slice(1).toLowerCase() || "-", 58, currentY);
            pdf.setFont("arial", "normal");
          }

          if(doc?.document_number){
            pdf.setFontSize(12);
            pdf.text("Document number", 15, updateY(0));
            pdf.setFont("arial", "bold");
            pdf.text(doc?.document_number || "-", 58, currentY);
            pdf.setFont("arial", "normal");
          }

          if(doc?.front_side){
            pdf.text("Front side", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const front_side = doc?.front_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: front_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }

          if(doc?.back_side){
            pdf.text("Back side", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const back_side = doc?.back_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: back_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }
        }


        if (doc.type === "VIDEO") {
          pdf.line(15, updateY(-5), 193, currentY);
          pdf.setFontSize(13);
          pdf.setFont("arial", "bold");
          pdf.text("Video document", 15, updateY(-2));
          pdf.setFont("arial", "normal");

          if(doc?.document_number){
            pdf.setFontSize(12);
            pdf.text("Document number", 15, updateY(0));
            pdf.setFont("arial", "bold");
            pdf.text(doc?.document_number || "-", 58, currentY);
            pdf.setFont("arial", "normal");
          }

          if(doc?.front_side){
            pdf.text("File", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const front_side = doc?.front_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: front_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }

          if(doc?.back_side){
            pdf.text("File", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const back_side = doc?.back_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: back_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }
        }

        if (doc.type === "SELFIE_IMAGE") {
          pdf.line(15, updateY(-5), 193, currentY);
          pdf.setFontSize(13);
          pdf.setFont("arial", "bold");
          pdf.text("Selfie image", 15, updateY(-2));
          pdf.setFont("arial", "normal");

          if(doc?.document_number){
            pdf.setFontSize(12);
            pdf.text("Document number", 15, updateY(0));
            pdf.setFont("arial", "bold");
            pdf.text(doc?.document_number || "-", 58, currentY);
            pdf.setFont("arial", "normal");
          }

          if(doc?.front_side){
            pdf.text("File", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const front_side = doc?.front_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: front_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }

          if(doc?.back_side){
            pdf.text("File", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const back_side = doc?.back_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: back_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }
        }


        if (doc.type === "CREDIT_CARD") {
          pdf.line(15, updateY(-5), 193, currentY);
          pdf.setFontSize(13);
          pdf.setFont("arial", "bold");
          pdf.text("Credit card", 15, updateY(-2));
          pdf.setFont("arial", "normal");

          if(doc?.document_number){
            pdf.setFontSize(12);
            pdf.text("Document number", 15, updateY(0));
            pdf.setFont("arial", "bold");
            pdf.text(doc?.document_number || "-", 58, currentY);
            pdf.setFont("arial", "normal");
          }

          if(doc?.front_side){
            pdf.text("Front side", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const front_side = doc?.front_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: front_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }

          if(doc?.back_side){
            pdf.text("Back side", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const back_side = doc?.back_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: back_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }
        }


        if (doc.type === "TAX_ID_NUMBER") {
          pdf.line(15, updateY(-5), 193, currentY);
          pdf.setFontSize(13);
          pdf.setFont("arial", "bold");
          pdf.text("Tax ID", 15, updateY(-2));
          pdf.setFont("arial", "normal");

          if(doc?.document_number){
            pdf.setFontSize(12);
            pdf.text("Document number", 15, updateY(0));
            pdf.setFont("arial", "bold");
            pdf.text(doc?.document_number || "-", 58, currentY);
            pdf.setFont("arial", "normal");
          }

          if(doc?.front_side){
            pdf.text("Front side", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const front_side = doc?.front_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: front_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }

          if(doc?.back_side){
            pdf.text("Back side", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const back_side = doc?.back_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: back_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }
        }



        if (doc.type === "GOVERNMENT_ID") {
          pdf.line(15, updateY(-5), 193, currentY);
          pdf.setFontSize(13);
          pdf.setFont("arial", "bold");
          pdf.text("Government ID", 15, updateY(-2));
          pdf.setFont("arial", "normal");

          if(doc?.document_number){
            pdf.setFontSize(12);
            pdf.text("Document number", 15, updateY(0));
            pdf.setFont("arial", "bold");
            pdf.text(doc?.document_number || "-", 58, currentY);
            pdf.setFont("arial", "normal");
          }

          if(doc?.expiry_date){
            pdf.setFontSize(12);
            pdf.text("Document expiry date", 15, updateY(0));
            pdf.setFont("arial", "bold");
            pdf.text(doc?.expiry_date || "-", 58, currentY);
            pdf.setFont("arial", "normal");
          }

          if(doc?.front_side){
            pdf.text("Front side", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const front_side = doc?.front_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: front_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }

          if(doc?.back_side){
            pdf.text("Back side", 15, updateY(0));
            pdf.setFont("arial", "normal");
            const back_side = doc?.back_side;
            pdf.setTextColor(0, 0, 255); // text color to Blue
            pdf.textWithLink("click to view", 58, currentY, { url: back_side, target: '_blank' });
            pdf.setFont("arial", "normal");
            pdf.setTextColor(0, 0, 0); // text color set back to black
          }
        }
    }

    })
}




  if(applicantInfo?.addresses && applicantInfo?.addresses?.length > 0){
      pdf.line(15, updateY(-5), 193, currentY);
      pdf.setFontSize(13);
      pdf.setFont("arial", "bold");
      pdf.text(`${applicantInfo?.addresses[0]?.type === "BUSINESS" ? "Business address" : "Registered address"}`, 15, updateY(-2));
      pdf.setFont("arial", "normal");

      pdf.setFontSize(12);
      pdf.text("Country", 15, updateY(0));
      pdf.setFont("arial", "bold");
      pdf.text(countryCodes[applicantInfo?.addresses[0]?.country] || "-", 58, currentY);
      pdf.setFont("arial", "normal");

      pdf.setFontSize(12);
      pdf.text("State/province", 15, updateY(0));
      pdf.setFont("arial", "bold");
      pdf.text(applicantInfo?.addresses[0]?.state_or_province || "-", 58, currentY);
      pdf.setFont("arial", "normal");

      pdf.setFontSize(12);
      pdf.text("City", 15, updateY(0));
      pdf.setFont("arial", "bold");
      pdf.text(applicantInfo?.addresses[0]?.city || "-", 58, currentY);
      pdf.setFont("arial", "normal");

      pdf.setFontSize(12);
      pdf.text("Postal code", 15, updateY(0));
      pdf.setFont("arial", "bold");
      pdf.text(applicantInfo?.addresses[0]?.postal_code || "-", 58, currentY);
      pdf.setFont("arial", "normal");

      pdf.setFontSize(12);
      pdf.text("Street name", 15, updateY(0));
      pdf.setFont("arial", "bold");
      pdf.text(applicantInfo?.addresses[0]?.street_name || "-", 58, currentY);
      pdf.setFont("arial", "normal");

      pdf.setFontSize(12);
      pdf.text("Building number", 15, updateY(0));
      pdf.setFont("arial", "bold");
      pdf.text(applicantInfo?.addresses[0]?.building_number || "-", 58, currentY);
      pdf.setFont("arial", "normal");

      pdf.setFontSize(12);
      pdf.text("Unit number", 15, updateY(0));
      pdf.setFont("arial", "bold");
      pdf.text(applicantInfo?.addresses[0]?.unit_number || "-", 58, currentY);
      pdf.setFont("arial", "normal");
  }


  if(applicantInfo?.affiliated_persons){
    applicantInfo?.affiliated_persons.forEach(async(elem) => {
      if (elem?.type === "AUTHORISED") {
        pdf.line(15, updateY(-5), 193, currentY);
        pdf.setFontSize(13);
        pdf.setFont("arial", "bold");
        pdf.text("Authorised person", 15, updateY(-2));
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("First Name", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(elem?.first_name || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Last Name", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(elem?.last_name || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Country", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(countryCodes[elem?.residence_country]  || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Date of birth", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(elem?.dob || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Email", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(elem?.email || "-", 58, currentY);
        pdf.setFont("arial", "normal");
      }

      if (elem?.type === "BENEFICIAL") {
        pdf.line(15, updateY(-5), 193, currentY);
        pdf.setFontSize(13);
        pdf.setFont("arial", "bold");
        pdf.text("Beneficiary", 15, updateY(-2));
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Share", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(elem?.share?.toString() || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("First Name", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(elem?.first_name || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Last Name", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(elem?.last_name || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Country", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(countryCodes[elem?.residence_country]  || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Date of birth", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(elem?.dob || "-", 58, currentY);
        pdf.setFont("arial", "normal");

        pdf.setFontSize(12);
        pdf.text("Email", 15, updateY(0));
        pdf.setFont("arial", "bold");
        pdf.text(elem?.email || "-", 58, currentY);
        pdf.setFont("arial", "normal");
      }
    })
  }




  pdf.setFontSize(12);

  // Get unique decline reasons from all verifications
  const uniqueDeclineReasons = [
    ...new Set([
      ...(data?.report?.verifications?.profile?.decline_reasons || []),
      ...(data?.report?.verifications?.document?.decline_reasons || []),
      ...(data?.report?.verifications?.address?.decline_reasons || []),
      ...(data?.report?.verifications?.facial?.decline_reasons || []),
      ...(data?.report?.verifications?.live_video?.decline_reasons || []),
      ...(data?.report?.verifications?.database_screening?.decline_reasons || []),
      ...(data?.report?.verifications?.aml?.decline_reasons || []),
      ...(data?.report?.verifications?.payment_method?.decline_reasons || []),
      ...(data?.report?.verifications?.tax_id?.decline_reasons || []),
      ...(data?.report?.verifications?.video?.decline_reasons || []),
      ...(data?.report?.verifications?.company?.decline_reasons || []),
      // Add more verifications as needed profile
    ]),
  ];

  const tableData = [
    {
      // sr_no: 1,
      verification_type: "Profile",
      verification_result: data?.report?.verifications?.profile
        ? data.report.verifications.profile.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.profile?.comment || "-",
      decline_reason: data?.report?.verifications?.profile?.comment || "-",
      // decline_reason: data?.report?.verifications?.profile?.decline_reasons
      //   ? data.report.verifications.profile.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    {
      // sr_no: 2,
      verification_type: "Document",
      verification_result: data?.report?.verifications?.document
        ? data.report.verifications.document.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.document?.comment || "-",
      decline_reason: data?.report?.verifications?.document?.comment || "-",
      // decline_reason: data?.report?.verifications?.document?.decline_reasons
      //   ? data.report.verifications.document.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    {
      // sr_no: 3,
      verification_type: "Address",
      verification_result: data?.report?.verifications?.address
        ? data.report.verifications.address.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.address?.comment || "-",
      decline_reason: data?.report?.verifications?.address?.comment || "-",
      // decline_reason: data?.report?.verifications?.address?.decline_reasons
      //   ? data.report.verifications.address.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    {
      // sr_no: 4,
      verification_type: "Facial",
      verification_result: data?.report?.verifications?.facial
        ? data.report.verifications.facial.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.facial?.comment || "-",
      decline_reason: data?.report?.verifications?.facial?.comment || "-",
      // decline_reason: data?.report?.verifications?.facial?.decline_reasons
      //   ? data.report.verifications.facial.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    {
      // sr_no: 5,
      verification_type: "Live Video",
      verification_result: data?.report?.verifications?.live_video
        ? data.report.verifications.live_video.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.live_video?.comment || "-",
      comdecline_reason: data?.report?.verifications?.live_video?.comment || "-",
      // decline_reason: data?.report?.verifications?.live_video?.decline_reasons
      //   ? data.report.verifications.live_video.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    {
      // sr_no: 6,
      verification_type: "Database Screening",
      verification_result: data?.report?.verifications?.database_screening
        ? data.report.verifications.database_screening.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.database_screening?.comment || "-",
      decline_reason: data?.report?.verifications?.database_screening?.comment || "-",
      // decline_reason: data?.report?.verifications?.database_screening
      //   ?.decline_reasons
      //   ? data.report.verifications.database_screening.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    {
      // sr_no: 7,
      verification_type: "Aml",
      verification_result: data?.report?.verifications?.aml
        ? data.report.verifications.aml.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.aml?.comment || "-",
      decline_reason: data?.report?.verifications?.aml?.comment || "-",
      // decline_reason: data?.report?.verifications?.aml
      //   ?.decline_reasons
      //   ? data.report.verifications.aml.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    {
      // sr_no: 8,
      verification_type: "Payment Method",
      verification_result: data?.report?.verifications?.payment_method
        ? data.report.verifications.payment_method.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.payment_method?.comment || "-",
      decline_reason: data?.report?.verifications?.payment_method?.comment || "-",
      // decline_reason: data?.report?.verifications?.payment_method
      //   ?.decline_reasons
      //   ? data.report.verifications.payment_method.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    {
      // sr_no: 9,
      verification_type: "Tax Id",
      verification_result: data?.report?.verifications?.tax_id
        ? data.report.verifications.tax_id.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.tax_id?.comment || "-",
      decline_reason: data?.report?.verifications?.tax_id?.comment || "-",
      // decline_reason: data?.report?.verifications?.tax_id
      //   ?.decline_reasons
      //   ? data.report.verifications.tax_id.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    {
      // sr_no: 10,
      verification_type: "Video",
      verification_result: data?.report?.verifications?.video
        ? data.report.verifications.video.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.video?.comment || "-",
      decline_reason: data?.report?.verifications?.video?.comment || "-",
      // decline_reason: data?.report?.verifications?.video
      //   ?.decline_reasons
      //   ? data.report.verifications.video.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    {
      // sr_no: 11,
      verification_type: "Company",
      verification_result: data?.report?.verifications?.company
        ? data.report.verifications.company.verified === true
          ? "Pass"
          : "Fail"
        : "-",
      comment: data?.report?.verifications?.company?.comment || "-",
      decline_reason: data?.report?.verifications?.company?.comment || "-",
      // decline_reason: data?.report?.verifications?.company
      //   ?.decline_reasons
      //   ? data.report.verifications.company.decline_reasons
      //       .map((reason) => declineReasons[reason] || "")
      //       .filter(Boolean)
      //       .join(" ")
      //   : "-",
    },
    // Add more rows as needed
  ];



  // const tableRows = tableData.map((item) => [
  //   item.sr_no,
  //   item.verification_type,
  //   item.verification_result,
  //   item.comment,
  //   item.decline_reason,
  // ]);

  const tableRows = tableData
  .filter((item) => {
    // console.log("FILTER Items==>", item);
    const verificationType = item.verification_type.replace(/ /g, '_'); // Replace spaces with underscores
    return (
      data?.report?.verifications?.[item.verification_type.toLowerCase()] ||
      data?.report?.verifications?.[verificationType.toLowerCase()]
    );
  })
  .map((item, index) => [
    index + 1,
    item.verification_type,
    item.verification_result,
    // item.comment,
    item.decline_reason,
  ]);

  const tableHeaders = [
    "Sr No",
    "Verification Type",
    "Verification Result",
    // "Comment",
    "Comments",
  ];
  const startX = 15;
  const startY = updateY(0);

  pdf.autoTable({
    head: [tableHeaders],
    body: tableRows,
    startX,
    startY,
    theme: "grid",
    headStyles: {
      halign: "center",
      fillColor: [192, 192, 192],
      textColor: [0, 0, 0],
      fontStyle: "normal",
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    columnStyles: {
      0: { cellWidth: 15, halign: "left" },
      1: { cellWidth: 35, halign: "left" },
      2: { cellWidth: 35, halign: "left" },
      // 3: { cellWidth: 60, halign: "left" },
      4: { cellWidth: 40, halign: "left" },
    },
    margin: { left: 15, right: 15 },
    styles: { lineColor: [0, 0, 0] }, // Set border color to black
    // style
  });

//   pdf.save("abcd.pdf");
// pdf.save(`${data?.serviceType}_` + `${moment(data?.createdAt).format('MMMM DD, YYYY [at] hh:mm:ss A')}` + ".pdf");
pdf.save(`${data?.serviceType}_${moment(data?.createdAt).format('MMMM DD, YYYY [at] hh:mm:ss A')}.pdf`);
};
