import React, { useEffect, useState } from "react";
import "./transactions.css";
import {
  ArrowLeftOutlined,
  FilePdfOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { HiOutlineExternalLink } from "react-icons/hi";
import { DatePicker, Table, Tooltip } from "antd";
// import { data } from "../../helpers/dummyData.helpers";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  AMLBOT_TOKEN,
  AMLBotAccessId,
  AMLBotAccessKey,
  baseApiUrl,
  baseUrl,
} from "../../constants/environment.constants";
import moment from "moment";
import { selectedAssets } from "../../helpers/assets.helper";
import { Button } from "antd";
import Loader from "../../components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import { HandleSuccess } from "../../helpers/toast.helper";
import { generatePaymentReceipt } from "../../helpers/pdfHelpers/paymentReceipt.helper";
// import { generateKycFullResult } from "../../helpers/pdfHelpers/kycFullVerificationResult.helper";
// import { generateWalletCheckReport } from "../../helpers/pdfHelpers/walletCheckReport.helper";
// import { walletTxnCheckReport } from "../../helpers/pdfHelpers/walletTxnCheckReport.helper";
import debounce from "lodash/debounce";
import { LuFilterX } from "react-icons/lu";


const Transactions = () => {
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  // const [isRecheckEnable, setIsRecheckEnable] = useState(true);
  const [disabledRecheckButtons, setDisabledRecheckButtons] = useState({});
  const [startDate, setStartDate] = useState("");
  const [search, setSearch] = useState("");
  const [endDate, setEndDate] = useState("");
  const [disabledReminderButtons, setDisabledReminderButtons] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  

  const getHistory = async (payload) => {
    try {
      setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      const getAllHistory = await axios.post(
        `${baseUrl}history?searchFields=optionSelected,clientEmail,amountPaid,asset,network,report.verifications,serviceType,status&search=${
        // `${localBaseUrl}history?searchFields=optionSelected,clientEmail,amountPaid,asset,network,report.verifications,serviceType,status&search=${
          payload?.search || ""
        }&start_date=${payload?.startDate || ""}&end_date=${
          payload?.endDate || ""
        }&sortBy=${payload?.sortBy || "createdAt"}&sortOrder=${
          payload?.sortOrder || -1
        }`, //latest first
        {},
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      if (getAllHistory?.data && getAllHistory?.data?.success === true)
        setHistory(getAllHistory?.data?.data);

      setIsLoading(false);
    } catch (err) {
      console.log("Error while getting history-->", err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const payload = {
      search: search || "",
      sortBy: "createdAt",
      sortOrder: "-1",
    };
    getHistory(payload);
  }, []);


  /** Clear filters handler */
  const handleClearFilters = () =>{
    // if (search || startDate && endDate) {
    //   return; // Do not clear filters if all three are empty
    // }

    console.log("handleFilters called")
    setStartDate("")
    setSearch("")
    setEndDate("")

    const payload = {
      search: "",
      sortBy: "createdAt",
      sortOrder: "-1",
    };
    getHistory(payload);
  }

  // disable Recheckt Btn for 5 mins once clicked handler
  const disableRecheckButton = (uid) => {
    const newDisabledRecheckButtons = { ...disabledRecheckButtons };
    newDisabledRecheckButtons[uid] = true;
    setDisabledRecheckButtons(newDisabledRecheckButtons);
    const disableUntil = Date.now() + 5 * 60 * 1000; // 5 minutes in milliseconds
    localStorage.setItem(`recheckDisabledUntil_${uid}`, disableUntil);
    setTimeout(() => {
      const updatedDisabledRecheckButtons = { ...disabledRecheckButtons };
      delete updatedDisabledRecheckButtons[uid];
      setDisabledRecheckButtons(updatedDisabledRecheckButtons);
      localStorage.removeItem(`recheckDisabledUntil_${uid}`);
    }, 5 * 60 * 1000);
  };

  useEffect(() => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("recheckDisabledUntil_")) {
        const uid = key.replace("recheckDisabledUntil_", "");
        const disableUntil = localStorage.getItem(key);
        if (disableUntil && Date.now() < disableUntil) {
          const newDisabledRecheckButtons = { ...disabledRecheckButtons };
          newDisabledRecheckButtons[uid] = true;
          setDisabledRecheckButtons(newDisabledRecheckButtons);
          setTimeout(() => {
            const updatedDisabledRecheckButtons = { ...disabledRecheckButtons };
            delete updatedDisabledRecheckButtons[uid];
            setDisabledRecheckButtons(updatedDisabledRecheckButtons);
            localStorage.removeItem(key);
          }, disableUntil - Date.now());
        } else {
          localStorage.removeItem(key);
        }
      }
    });
  }, []);

  // Recheck handler
  const handleRecheck = async (input) => {
    try {
      setIsLoading(true);

      disableRecheckButton(input?._id);

      const userToken = sessionStorage.getItem("userToken");

      const tokenPayload = {
        uid: input.uid,
        accessKey: AMLBotAccessKey,
        accessId: AMLBotAccessId,
      };

      const generateToken = await axios.post(
        baseApiUrl + "generate-token",
        tokenPayload,
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      const payload = {
        uid: input.uid,
        accessId: AMLBotAccessId,
        token: generateToken?.data?.token,
        recordId: input._id,
      };

      const resp = await axios.post(baseUrl + "recheck", payload, {
        // const resp = await axios.post(localBaseUrl + "recheck", payload, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      setIsLoading(false);
      if (
        resp?.data?.result === true &&
        resp?.data?.data?.status === "success"
      ) {
        // window.location.reload()
        const payload = {
          search: search || "",
          sortBy: "createdAt",
          sortOrder: "-1",
        };
        getHistory(payload);
      }
    } catch (err) {
      console.log("Error while recheck -->", err.message);
      setIsLoading(false);
    }
  };

  /* Code for Disable Reminder button once clicked for 24 hrs */
  const disableReminderButton = (id) => {
    const newDisabledReminderButtons = { ...disabledReminderButtons };
    newDisabledReminderButtons[id] = true;
    setDisabledReminderButtons(newDisabledReminderButtons);
    const disableUntil = Date.now() + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    localStorage.setItem(
      `reminderDisabledUntil_${id}`,
      disableUntil.toString()
    );
    setTimeout(() => {
      const updatedDisabledReminderButtons = { ...disabledReminderButtons };
      delete updatedDisabledReminderButtons[id];
      setDisabledReminderButtons(updatedDisabledReminderButtons);
      localStorage.removeItem(`reminderDisabledUntil_${id}`);
    }, 24 * 60 * 60 * 1000);
  };

  useEffect(() => {
    const disabledButtons = {};
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("reminderDisabledUntil_")) {
        const id = key.replace("reminderDisabledUntil_", "");
        const disableUntil = localStorage.getItem(key);
        if (disableUntil && Date.now() < parseInt(disableUntil)) {
          disabledButtons[id] = true;
        } else {
          localStorage.removeItem(key);
        }
      }
    });
    setDisabledReminderButtons(disabledButtons);
  }, []);

  // Recheck Handler
  const handleReminder = async (info) => {
    try {
      setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      const payload = {
        clientName: info?.clientName,
        clientEmail: info?.clientEmail,
        form_url: info?.report?.form_url,
      };

      const sendReminder = await axios.post(baseUrl + "reminder", payload, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      if (sendReminder?.data?.success === true) {
        HandleSuccess("Reminder email sent successfully.");
        disableReminderButton(info?._id);
      }

      setIsLoading(false);
    } catch (err) {
      console.log("Error while sending reminder-->", err.message);
      setIsLoading(false);
    }
  };

  // Resubmit handler
  const handleResubmit = async (record) => {
    try {
      // seding record json data to their routes as query params
      navigate(
        `${
          record?.errorIn === "kycfull" || record?.errorIn === "kyclight"
            ? "/verify-person"
            : record?.errorIn === "kyb"
            ? "/verify-business"
            : record?.errorIn === "Wallet Check"
            ? "/verify-wallet/check-wallet"
            : record?.errorIn === "Transaction Check"
            ? "/verify-wallet/check-transaction"
            : record?.errorIn === "Investigation"
            ? "/verify-wallet/investigation"
            : ""
        }`,
        { state: { jsonData: JSON.stringify(record) } }
      );
    } catch (err) {
      console.log("Error while re-submit -->", err.message);
    }
  };

  // Download pdf receipt handler
  const handleDownloadReceipt = async (data, serviceType) => {
    try {
      setIsLoading(true);
      generatePaymentReceipt(data, serviceType);
      setIsLoading(false);
    } catch (err) {
      console.log("Error while downloading report-->", err.message);
      setIsLoading(false);
    }
  };

  /* KYC/KYB Report download handler */
  const downloadKycKybReport = async (data) => {
    try {
      setIsLoading(true);

      const headers = {
        Authorization: `Token ${AMLBOT_TOKEN}`,
        "Content-Type": "application/json",
      };

      const applicantData = await axios.get(
        "https://kyc-api.amlbot.com/applicants/" + data?.report?.applicant_id,
        { headers }
      );
      const applicantInfo = applicantData?.data;

      navigate("/verifications", {
        state : {data : JSON.stringify(data), applicantInfo : JSON.stringify(applicantInfo)}
      })

      // await generateKycFullResult(data, applicantInfo);

      setIsLoading(false);
    } catch (err) {
      console.log("Error while downloading kyc/kyb report-->", err.message);
      setIsLoading(false);
    }
  };

  /* KYC/KYB Report download handler */
  const downloadWalletTxnReport = async (data) => {
    try {
      // console.log("download report called..")
      setIsLoading(true);

      // walletTxnCheckReport(data);
      navigate("/risk-details", {
        state : {data : JSON.stringify(data)}
      })

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (err) {
      console.log("Error while downloading Wallet/Txn report-->", err.message);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: <span className="text-truncate">Transaction Date</span>,
      dataIndex: "createdAt",
      key: "createdAt",
      width: "11%",
      className: "custom-header",
      render: (text) => {
        return (
          <span>{moment(text?.split("T")[0]).format("MMMM D, YYYY")}</span>
        );
      },
    },
    {
      title: <span className="text-truncate">Service Type</span>,
      dataIndex: "serviceType",
      key: "serviceType",
      width: "12%",
      className: "custom-header",
    },
    {
      title: <span className="text-truncate">Email Address</span>,
      dataIndex: "clientEmail",
      key: "clientEmail",
      width: "12%",
      className: "custom-header",
      render: (text, record) => {
        return (
          <Tooltip title={text}>
            <span>
              {text?.length > 19 ? text?.substr(0, 18) + "..." : text}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: <span className="text-truncate">Status</span>,
      dataIndex: "status",
      key: "status",
      width: "8%",
      className: "custom-header",
      render: (text) => {
        return (
          <span
            className=""
            style={{
              color:
                text === "success" || text === "Completed"
                  ? "#29ca64"
                  : "#EFA440",
            }}
          >
            {text === "success" || text === "Completed"
              ? "Complete"
              : "Pending"}
          </span>
        );
      },
    },
    {
      title: <span className="text-truncate">Amount Paid ($)</span>,
      dataIndex: "amountPaid",
      key: "amountPaid",
      width: "7%",
      className: "custom-header",
      render: (text) => {
        return (
          <span style={{ display: "flex", justifyContent: "end" }}>
            {text?.toFixed(2)}
          </span>
        );
      },
    },
    {
      title: <span className="text-truncate">Option Selected</span>,
      dataIndex: "network",
      key: "network",
      width: "14%",
      className: "custom-header",
      render: (text, record) => {
        // console.log("TEXT =+=+>", record)
        const assetName = selectedAssets[record.asset];
        let optionSelected = record?.optionSelected || "-";
        let tooltipText = optionSelected
          .split(",")
          .map((word) => {
            return word.trim().replace(/_/g, ' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
          })
          .join(", ");
        // let displayText = "";
        if (optionSelected.includes(",")) {
          optionSelected =
            optionSelected
              .split(",")
              .slice(0, 2)
              .map(
                (word) => word.trim().charAt(0).toUpperCase() + word.slice(1)
              )
              .join(", ") + "...";
        } else {
          optionSelected =
            optionSelected.trim().charAt(0).toUpperCase() +
            optionSelected.slice(1);
        }
        return (
          <div>
            {text ? (
              <span>
                {text}, {assetName}
              </span>
            ) : record?.optionSelected ? (
              <Tooltip title={tooltipText}>
                <span>{optionSelected}</span>
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: <span className="text-truncate">Receipt</span>,
      dataIndex: "receipt",
      key: "receipt",
      // width: "10%",
      className: "custom-header",
      render: (text, record) => {
        return (
          <div className="table-ernder-icons">
            {record?.receipt && record?.receipt?.id ? (
              <FilePdfOutlined
                onClick={() =>
                  handleDownloadReceipt(record?.receipt, record?.serviceType)
                }
                style={{ fontSize: "20px", color: "#FF6666" }}
              />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: <span className="text-truncate">Report</span>,
      dataIndex: "",
      key: "report",
      // width: "10%",
      className: "custom-header",
      render: (text) => {
        // console.log('TEXT===>', text)
        return (
          <div className="table-ernder-icons">
            {/* {text?.report?.pdfReport || text?.report?.xlsReport ? ( */}
            {text?.report?.xlsReport ? (
              <a href={text?.report?.pdfReport || text?.report?.xlsReport}>
                {text?.report?.xlsReport && (
                  <FileExcelOutlined
                    style={{ fontSize: "20px", color: "#3b992e" }}
                  />
                )}
                {/* {text?.report?.pdfReport && (
                  <FilePdfOutlined
                    style={{ fontSize: "20px", color: "#FF6666" }}
                  />
                )} */}
              </a>
            ) : text?.status === "success" && text?.report?.result === true ? (
              <Tooltip title={"View risk check result"}>
                <HiOutlineExternalLink
                  onClick={() => downloadWalletTxnReport(text)}
                  style={{ fontSize: "24px", color: "#0d6efd" }}
                />
              </Tooltip>
              // <FilePdfOutlined
              //   onClick={() => downloadWalletTxnReport(text)}
              //   style={{ fontSize: "20px", color: "#FF6666" }}
              // />
            )
             : text?.report?.status === "completed" ? (
              <Tooltip title={"View verifications result"}>
                <HiOutlineExternalLink
                  onClick={() => downloadKycKybReport(text)}
                  style={{ fontSize: "24px", color: "#0d6efd" }}
                />
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: <span className="text-truncate">Actions</span>,
      dataIndex: "",
      key: "actions",
      width: "10%",
      className: "custom-header",
      render: (text, record) => {
        return (
          <div className="table-ernder-icons">
            {text?.status === "pending" ? (
              <Button
                type="primary"
                onClick={() => handleRecheck(text)}
                style={{
                  backgroundColor: "#144A90",
                  cursor: disabledRecheckButtons[text.uid]
                    ? "not-allowed"
                    : "pointer",
                }}
                className={
                  disabledRecheckButtons[text._id]
                    ? "disabled-recheck-button"
                    : ""
                }
              >
                Recheck
              </Button>
            ) : // in kyc/kyb status pending starts with capital P (Pending)
            text?.status === "Pending" ? (
              <Button
                type="primary"
                className="mr-2"
                onClick={() => handleReminder(text)}
                style={{
                  backgroundColor: "#1445cf",
                  cursor: disabledReminderButtons[text._id]
                    ? "not-allowed"
                    : "pointer",
                }}
                disabled={disabledReminderButtons[text._id]}
              >
                Reminder
              </Button>
            ) : text?.errorIn && text?.errorIn?.length ? (
              <Button
                type="primary"
                style={{ backgroundColor: "#0eb999" }}
                onClick={() => handleResubmit(text)}
              >
                Resubmit
              </Button>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: <span className="text-truncate">Remarks</span>,
      dataIndex: "",
      key: "remark",
      width: "10%",
      className: "custom-header",
      render: (text) => {
        return (
          <span>
            {text?.errorRemark?.err && text?.errorRemark?.err?.length ? (
              <Tooltip title={text?.errorRemark?.err}>
                {text?.errorRemark?.err?.substr(0, 14) + "..."}
              </Tooltip>
            ) : text?.report?.investigationLink ? (
              <a href={text?.report?.investigationLink} target="_blank" rel="noreferrer">
                View Analysis
              </a>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
  ];

  // const tablePagination = {
  //   pageSize: 10, // Set the page size to 5
  // };
  const searchHandler = (e) => {
    console.log(e.target.value, "search valuees");

    setSearch(e.target.value);

    const debouncedSearchHandler = debounce(async () => {
      const payload = {
        search: e.target.value || "",
        sortBy: "createdAt",
        sortOrder: "-1",
      };
      getHistory(payload);
    }, 1000);
    debouncedSearchHandler();
  };
  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    const payload = {
      search: search || "",
      sortBy: "createdAt",
      sortOrder: "-1",
      startDate: startDate,
      endDate: endDate,
    };
    getHistory(payload);
  }, [startDate, endDate]);
  return (
    <div>
      <Navbar />
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <div className="d-flex flex-column overview-wrapper">
        <div className="wrapper flex-grow-1 p-4">
          <div className="row" style={{ justifyContent: "space-between" }}>
            {/* <!-- Title --> */}
            <div className="col-lg-4 col-md-12 col-sm-12 mt-2">
              <div className="d-flex title-icon-container">
                <MenuUnfoldOutlined />
                <h5 className="pl-2 mt-2 title">Dashboard</h5>
              </div>
            </div>

            {/* <!-- Filters --> */}
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="row">
                {/* <!-- Search input --> */}
                <div className="col-lg-3 col-md-12 col-sm-12 mt-2">
                  <div className="inputWithIcon h-100">
                    {/* <em class="fas fa-search"></em> */}
                    <SearchOutlined className="search-icon" />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      autoComplete="off"
                      maxLength="75"
                      value={search}
                      onChange={searchHandler}
                    />
                  </div>
                </div>

                {/* <!-- Date Selections --> */}
                <div className="col-lg-5 col-md-12 mt-2">
                  <form action="">
                    <div className="d-flex date-border">
                      <p className="mb-0 date-range text text-decoration-none font-weight-lighter">
                        Date Range
                      </p>

                      {/* <!-- Form Date --> */}
                      <div className="junk-border input-group">
                        <DatePicker
                          value={startDate ? moment(startDate, "DD/MM/YYYY") : null}
                          style={{ fontWeight: "700" }}
                          onChange={(value) => {
                            const utcDate =
                              value &&
                              moment(new Date(value)).format("DD/MM/YYYY");
                            // console.log(
                            //   utcDate,
                            //   new Date(value).toISOString(),
                            //   "utc start date"
                            // );
                            setStartDate(utcDate);
                          }}
                        />
                      </div>

                      <p className="pl-4 pr-2 my-auto mx-4 text text-decoration-none font-weight-lighter">
                        {" "}
                        To{" "}
                      </p>

                      {/* <!-- To Date --> */}
                      <div className="junk-border input-group ">
                        <DatePicker
                          value={endDate ? moment(endDate, "DD/MM/YYYY") : null}
                          disabledDate={(currentDate) =>
                            currentDate &&
                            currentDate.isBefore(moment(startDate))
                          }
                          onChange={(value) => {
                            const utcDate =
                              value &&
                              moment(new Date(value)).format("DD/MM/YYYY");
                            // console.log(
                            //   utcDate,
                            //   new Date(value).toISOString(),
                            //   "utc end date"
                            // );
                            setEndDate(utcDate);
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>

                {/* Export Buttons */}
                <div className="col-lg-4 col-md-12 col-sm-12 mt-2">
                  <button
                    onClick={() => navigate("/")}
                    className="t-btn t-btn-info form-control font-weight-bold w-100"
                  >
                    <ArrowLeftOutlined /> Back to Product
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="clear-filters-btn-container mt-4 mb-2"  >
            <button className="clear-filters-btn"
            onClick={handleClearFilters}
              disabled={!(search || (startDate && endDate))}
            >
              <LuFilterX size={20} />
              <span >clear filters</span>
            </button>
          </div>

          {/* Table Section */}
          <div className="mt-5 table-responsive">
            <Table
              columns={columns}
              // dataSource={history}
              dataSource={history.map((record) => ({
                ...record,
                key: record._id,
              }))}
              // pagination={tablePagination}
              pagination={{
                pageSize: pageSize,
                current: currentPage,
                total: history.length,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "50"],
                onChange: (page, pageSize) => setCurrentPage(page),
                onShowSizeChange: (current, size) => setPageSize(size),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
