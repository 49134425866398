import React, { useEffect, useState } from "react";
import "./payment.css"
import { Result } from "antd";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";


const PaymentFail = () => {
    const [timer, setTimer] = useState(10);
    const navigate = useNavigate()

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 1000);

        return () => clearInterval(countdown);
    }, []);


    useEffect(() => {
        if (timer === 0) {
            navigate('/');
        }
    }, [timer, navigate]);


    return (
      <div className="">
        <Navbar />
        <div className="success-message-container">
          <div className="fail-message-content-holder">
          {/* <img src="/cobalt-icon.svg" alt="fintrack-logo" className="wallet-img-fluid"/> */}
          <span className="success-title">Something went wrong</span>
            <Result
              status="error"
              // title="Payment Failed."
              // subTitle={`Please try again - you will be automatically redirected to the merchent website in ${timer} seconds.`}
              extra={
                [
                  <div className="" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <span className="success-subtitle">Payment Failed.</span>
                    <span className="success-text-counter">You will be automatically redirected to the product screen in {timer} seconds.</span>
                  </div>
                ]
              }
            />
          </div>
        </div>
      </div>
  );
};

export default PaymentFail;