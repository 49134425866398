import React, { useState } from "react";
import "./newVerifyDocs.css";
import Navbar from "../../components/navbar/Navbar";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { GrDocumentText } from "react-icons/gr";
import { message, Upload } from "antd";
import { RiFileUploadLine } from "react-icons/ri";

const { Dragger } = Upload;

const props = {
  name: "file",
  multiple: true,
  action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const NewVerifyDocs = () => {
  const [docType, setDocType] = useState("");

  const handleDropdownChange = (e) => {
    setDocType(e.target.value);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-column overview-wrapper">
        <div className="wrapper">
          <div className="title-btn-container mt-2">
            {/* <!-- Title --> */}
            <div class="col-lg-3 col-md-10 col-sm-10 mt-2">
              <div class="d-flex title-icon-contnr">
                <GrDocumentText style={{ fontSize: "18px" }} />
                <h5 class="pl-2 mt-2 title">Verify Documents</h5>
              </div>
            </div>

            <div className="col-lg-2 col-md-10 col-sm-10 mt-2">
              <button
                onClick={() => navigate("/")}
                className="v-btn v-btn-info form-control font-weight-bold w-100"
                style={{height: "38px"}}
              >
                <ArrowLeftOutlined /> Back to Product
              </button>
            </div>
          </div>

          <div className="container">
            <div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 my-auto justify-content-center text-center">
              <div className="v-sectionStartForm px-5 py-4">
                <form>
                  <div className="form-wrapper">
                    <div className="top-container">
                      <Dragger {...props} className="dragger" maxCount={1}>
                        <p className="dragIcon">
                          {/* <InboxOutlined /> */}
                          <RiFileUploadLine size={70} />
                        </p>
                        <p className="uploadText">
                          Drag your file here or{" "}
                          <span className="subtext">brows</span> to upload.
                        </p>
                      </Dragger>
                    </div>
                    <div className="mid-container justify-content-left">
                    <span className="mid-first-span mt-4 text-left">
                      The upload time dependent on the size of your files and
                      your internet speed. Uploading the files could take a few minutes for larger
                      files.
                    </span>
                    {/* <span className="mid-first-span mt-1 text-left">
                      Uploading the files could take a few minutes for larger
                      files.
                    </span> */}
                    {/* <p>Select Option :</p> */}
                    {/* <span className="mid-second-span mt-4">
                      Full KYC with liveliness check <b>$215.00</b>
                    </span> */}
                    {/* <span className="price">$15.00</span>
                        <span className="ThirdSpan">
                          Light KYC ID with Face check
                        </span>
                        <span className="price">$7.50</span> */}
                  </div>

                    {/* <div className="instructions">
                      <p>
                        The upload time dependent on the size of your files and
                        your internet speed.
                      </p>
                      <p>
                        Uploading the files could take a few minutes for larger
                        files.
                      </p>
                    </div> */}

                    <div className="dropDown" style={{ alignItems: "start" }}>
                      <label htmlFor="dropdown" className="dLabel mt-3">
                        Select Document Type
                      </label>
                      <select
                        name=""
                        id="dropdown"
                        value={docType}
                        className="drowpDownItem"
                        onChange={handleDropdownChange}
                      >
                        <option value="">Select an document</option>
                        <option value="Driver License">Driver License</option>
                        <option value="Passport">Passport</option>
                        <option value="Age of Majority">Age of Majority</option>
                        <option value="PAL">PAL</option>
                      </select>
                    </div>
                    {/* <div className="form-label-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      name="email"
                      // value={loginForm.values.email}
                      // onChange={loginForm.handleChange}
                      // onBlur={loginForm.handleBlur}
                    />
                    <label>Enter your clients email</label>
                  </div> */}

                    {/* <div className="mid-container justify-content-left">
                    <span className="mid-first-span mt-5">
                      Your client will be sent a link with instructions
                    </span> */}
                    {/* <p>Select Option :</p> */}
                    {/* <span className="mid-second-span mt-4">
                      Full KYC with liveliness check <b>$215.00</b>
                    </span> */}
                    {/* <span className="price">$15.00</span>
                        <span className="ThirdSpan">
                          Light KYC ID with Face check
                        </span>
                        <span className="price">$7.50</span> */}
                    {/* </div> */}

                    {/* <div className="form-label-group mt-4"> */}
                    <div className="wd-form-btn-group">
                      <button className="btn v-btn btn-primary pl-5 pr-5 font-weight-bold text-uppercase ">
                        Submit
                      </button>
                      <button className="btn v-btn btn-cancel font-weight-bold text-uppercase ">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewVerifyDocs;
