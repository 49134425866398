import React from 'react';
import styles from "./dashboard.module.css"
import Navbar from '../../components/navbar/Navbar';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const navigate = useNavigate()

    return (
        <div className={styles.wrapper}>
            <Navbar />
        <div className={styles.container}>
            <span className={styles.title}>Pay-As-You-Go</span>
            <h2 className={styles.subtext}>Verify Now, no signup fees, no setup, instant access,</h2>
            <h2 className={styles.subtext}>pay-as-you-go, volume discounts!</h2>
            <div className={styles.cardContainer}>
                <div className={styles.card}>
                    <img src="/newPerson.png" alt="" className={styles.cardImg}/>
                    <button className={styles.vefiryButton} onClick={()=> navigate("/verify-person")}>Verify Person</button>
                </div>
                <div className={styles.card}>
                    <img src="/new-verify-Business.png" alt="" className={styles.cardImg}/>
                    <button className={styles.vefiryButton} onClick={()=> navigate("/verify-business")}>Verify Business</button>
                </div>
                <div className={styles.card}>
                    <img src="/new-verify-wallet.png" alt="" className={styles.cardImg}/>
                    <button className={styles.vefiryButton} onClick={()=> navigate("/verify-wallet/check-wallet")}>Verify Wallet</button>
                </div>
                <div className={styles.card}>
                    <img src="/dashboard.png" alt="" className={styles.cardImg}/>
                    <button className={styles.vefiryButton} onClick={()=> navigate("/transactions")}>Dashboard</button>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Dashboard;