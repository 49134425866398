import React from 'react';
import styles from './navbar.module.css'
import { useLocation } from 'react-router-dom';
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";

const Navbar = () => {

    const location = useLocation();

    const handleLogout = () =>{
        sessionStorage.clear()
        window.location.reload()
    }

    const handleImageClick = () => {
        window.open('https://fintracking.ca/', '_blank', 'noopener,noreferrer');
    };

    // Function to check if the current route is /payment-success or /payment-fail
    const isPaymentRoute = () => {
        return location.pathname === '/payment-success' || location.pathname === '/payment-fail';
    }

    return (
        <div className={styles.container}>
            <img src="/cob-icon.svg" alt="" className={styles.title} onClick={handleImageClick} style={{cursor: "pointer"}}/>
            <div className={styles.icons}>
                <a href="https://twitter.com/Fin_tracking" target='_blank' rel="noreferrer" className={styles.links}><FaXTwitter size={20}/></a>
                <a href="https://www.linkedin.com/company/fintracking/" target='_blank' rel="noreferrer" className={styles.links}><FaLinkedinIn size={20}/></a>
                {!isPaymentRoute() && <div className={styles.contactus} onClick={()=> handleLogout()}>Logout <LuLogOut size={20}/></div>}
            </div>
        </div>
    );
};

export default Navbar;