import React from 'react';
import HashLoader from "react-spinners/HashLoader";
import "./loader.css";

const Loader = ({isLoading}) => {

    // console.log("isLoading-->", isLoading)

    if (isLoading) {
    return (
        <div className="loader-container" >
          <HashLoader
            color={"#144A90"}
            loading={true}
            // cssOverride={override}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
    );
  } else {
    return <></>;
  }
};

export default Loader;