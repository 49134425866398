import React, { useEffect, useState } from 'react';
import "./chainBtn.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from 'antd';
import { SlPaypal } from "react-icons/sl";
import { TriggerPayments } from '../../helpers/paypal.helper';
import { useFormik } from "formik";
import axios from 'axios';
import { AMLBotAccessId, AMLBotAccessKey, baseApiUrl, baseUrl } from '../../constants/environment.constants';
import Loader from '../Loader/Loader';

const ChainButtons = () => {
    // const [hash, setHash] = useState("")
    const [asset, setAsset] = useState("")
    const [isLimitted, setIsLimitted] = useState(false)
    const [isPayPalEnabled, setIsPayPalEnabled] = useState(false);
    const [payAmount, setPayAmount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();

    // Getting Resubmit handler data as query params
    const location = useLocation();
    const jsonDataString = location?.state?.jsonData;
    const jsonData = JSON.parse(jsonDataString || "{}");




    const getDynamicPrices = async () => {
        try {
          setIsLoading(true);
          const userToken = sessionStorage.getItem("userToken");
    
          /*Get Dynamic Full KYC Price */
          const getWalletCheckPrice = await axios.get(
            baseUrl + "prices?serviceType=Wallet Check",
            {
              headers: {
                Authorization: `Token ${userToken}`,
              },
            }
          );
    
          if (getWalletCheckPrice?.data && getWalletCheckPrice?.data?.price)
            setPayAmount(getWalletCheckPrice?.data?.price);
    
    
          setIsLoading(false);
        } catch (err) {
          console.log(
            "Error while getting dynamic Prices from verify Buisiness -->",
            err.message
          );
          setIsLoading(false);
        }
      };



    /* useEffect to get Dynamic Prices*/
    useEffect(() => {
        getDynamicPrices();
    }, []);


    const checkWalletForm = useFormik({
        initialValues: {
          hash: "",
          asset: "",
        },
        onSubmit: (values) => {},
        validate: (values) => {
            let errors = {};

            if(!values.hash){
            errors.hash = "Field is required";
            }

            return errors;
        },
    })

    const {values} = checkWalletForm
    // console.log("Values-->", values.hash)

    useEffect(()=>{
        if( values?.hash.trim() !== "" && asset !== ""){
            setIsPayPalEnabled(true)
        }else{
            setIsPayPalEnabled(false)
        }
    },[values?.hash, asset])



    const handleSubmit = async () =>{
        try{
            setIsLoading(true);
            const userToken = sessionStorage.getItem("userToken");

            const tokenPayload = {
                hash : values?.hash?.trim(),
                accessKey : AMLBotAccessKey,
                accessId : AMLBotAccessId
            };

            const generateToken = await axios.post(baseApiUrl + "generate-token", tokenPayload, {
                headers : {
                    "Authorization": `Token ${userToken}`
                }
            });


            const payload = {
                hash : values?.hash,
                asset : asset,
                token : generateToken?.data?.token,
                accessId : AMLBotAccessId,
                serviceType : "Wallet Check",
                amountPaid : payAmount,
                userToken : userToken,
            };

            
            TriggerPayments(payload);

            setTimeout(()=>{
                setIsLoading(false);
            }, 2000);
            
        }catch(err){
            console.log("Error while sending Wallet Check-->",err.message)
            setIsLoading(false)
        }
    }


    // Resubmit handler
    const handleReSubmit = async (eData) => {
        // console.log("resubmitFor==>", eData);

        try{
            setIsLoading(true);
            const userToken = sessionStorage.getItem("userToken");

            const tokenPayload = {
                hash : values?.hash?.trim(),
                accessKey : AMLBotAccessKey,
                accessId : AMLBotAccessId
            };

            const generateToken = await axios.post(baseApiUrl + "generate-token", tokenPayload, {
                headers : {
                    "Authorization": `Token ${userToken}`
                }
            });

            const payload = {
                id: eData?._id,
                hash : values?.hash,
                asset : asset,
                token : generateToken?.data?.token,
                accessId : AMLBotAccessId,
                serviceType : "Wallet Check",
                // amountPaid : 1,
            };


            const updateWalletCheck = await axios.put(baseUrl + "resubmit-wallet-check", payload, {
                headers : {
                    "Authorization": `Token ${userToken}`
                }
            });

            if(updateWalletCheck?.status === 200){
                navigate("/transactions")
            }
        
            setIsLoading(false);

        }catch(err){
            console.log("Error while Wallet Check resubmit ", err.message)
            setIsLoading(false);
            navigate("/transactions")
        }
    }

    return (
        <div>
            <Loader isLoading={isLoading}/>
            <div className="top-label-input-container">
                <span className="chain-subtitle" style={{color: "#616161"}}>Check Address</span>
                <input type="text"
                 style={{border : checkWalletForm.errors.hash ? "1px solid #ed274b" : ""}}
                 placeholder='Enter wallet address'
                 className='form-control'
                 id="wallet-custom-input"
                 name='hash'
                 value={checkWalletForm.values.hash}
                //  onChange={(e)=> {
                //     checkWalletForm.handleChange
                //     setHash(e.target.value)
                // }}
                onChange={checkWalletForm.handleChange}
                onBlur={checkWalletForm.handleBlur}
                />
                {checkWalletForm.touched.hash && checkWalletForm.errors.hash ? (
                  <div className="wallet-error">{checkWalletForm.errors.hash}</div>
                ) : null}
            </div>

            <span className="chain-subtitle" style={{color: "#616161"}}>Specify blockchain</span>
              <div className="chain-selector-btns-container">


                <button className={asset === "BTC" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("BTC")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/bitcoin.svg" alt=""/>
                    <span className="chain-btn-txt">Bitcoin</span>
                </button>

                <button className={asset === "ETH" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("ETH")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/etherium.svg" alt=""/>
                    <span className="chain-btn-txt">Ethereum</span>
                </button>

                <button className={asset === "BSC" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("BSC")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/pnb-chain.svg" alt=""/>
                    <span className="chain-btn-txt">BNB Chain</span>
                </button>

                <button className={asset === "LTC" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("LTC")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/lite-coin.svg" alt=""/>
                    <span className="chain-btn-txt">Litecoin</span>
                </button>

                <button className={asset === "BCH" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("BCH")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/bitcoin-cash.svg" alt=""/>
                    <span className="chain-btn-txt">Bitcoin Cash</span>
                </button>

                <button className={asset === "XRP" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("XRP")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/ripple.svg" alt=""/>
                    <span className="chain-btn-txt">Ripple</span>
                </button>

                <button className={asset === "TetherOMNI" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("TetherOMNI")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/tether-omni.svg" alt=""/>
                    <span className="chain-btn-txt">TetherOMNI</span>
                </button>

                <button className={asset === "ETC" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("ETC")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/etherium-classic.svg" alt=""/>
                    <span className="chain-btn-txt">Ethereum Classic</span>
                </button>

                <button className={asset === "BSV" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("BSV")
                        setIsLimitted(true)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/bitcoin-sv.svg" alt=""/>
                    <span className="chain-btn-txt">Bitcoin SV</span>
                </button>

                <button className={asset === "DOGE" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("DOGE")
                        setIsLimitted(true)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/dogecoin.svg" alt=""/>
                    <span className="chain-btn-txt">Dogecoin</span>
                </button>

                <button className={asset === "TRX" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("TRX")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/tron.svg" alt=""/>
                    <span className="chain-btn-txt">TRON</span>
                </button>

                <button className={asset === "MATIC" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("MATIC")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/polygon.svg" alt=""/>
                    <span className="chain-btn-txt">Polygon</span>
                </button>

                <button className={asset === "ADA" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("ADA")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/cardano.svg" alt=""/>
                    <span className="chain-btn-txt">Cardano</span>
                </button>

                <button className={asset === "ZEC" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("ZEC")
                        setIsLimitted(true)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/zcash.svg" alt=""/>
                    <span className="chain-btn-txt">Zcash</span>
                </button>

                <button className={asset === "SOL" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("SOL")
                        setIsLimitted(true)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/solana.svg" alt=""/>
                    <span className="chain-btn-txt">Solana</span>
                </button>

                <button className={asset === "ALGO" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("ALGO")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/algorand.svg" alt=""/>
                    <span className="chain-btn-txt">Algorand</span>
                </button>

                <button className={asset === "ARB" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("ARB")
                        setIsLimitted(true)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/arbitirum.svg" alt=""/>
                    <span className="chain-btn-txt">Arbitrum</span>
                </button>

                <button className={asset === "DOT" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("DOT")
                        setIsLimitted(true)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/palkodot.svg" alt=""/>
                    <span className="chain-btn-txt">Polkadot</span>
                </button>

                <button className={asset === "AVAX" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("AVAX")
                        setIsLimitted(true)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/avalanche.svg" alt=""/>
                    <span className="chain-btn-txt">Avalanche</span>
                </button>

                <button className={asset === "XLM" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("XLM")
                        setIsLimitted(false)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/stellar.svg" alt=""/>
                    <span className="chain-btn-txt">Stellar</span>
                </button>

                <button className={asset === "TON" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("TON")
                        setIsLimitted(true)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/ton.svg" alt=""/>
                    <span className="chain-btn-txt">TON</span>
                </button>

                <button className={asset === "NEAR" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("NEAR")
                        setIsLimitted(true)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/near.svg" alt=""/>
                    <span className="chain-btn-txt">Near</span>
                </button>

                <button className={asset === "XTZ" ? "chain-selector-btn-active" : "chain-selector-btn"}
                    onClick={()=> {
                        setAsset("XTZ")
                        setIsLimitted(true)
                    }}
                >
                    <img className="chain-btn-icon" src="/coins/tezos.svg" alt=""/>
                    <span className="chain-btn-txt">Tezos</span>
                </button>
              </div>

              {isLimitted === true && 
              (<div className="alert-box">
                <img src='/coins/up-cion.png' alt='' className='alert-box-icon'/>
                <div className="alert-box-text-container">
                    <span className='alert-box-title'>Please note that analysis of this blockchain is limited.</span>
                    <span className='alert-box-text'>Clusterization and risk score propagation are not available for this check result. Risk score may be provided for a counterparty only if the counterparty is attributed to an entity.</span>
                </div>
              </div>)}

              {asset === "DOT" && <div className="polkatod-message">To check Polkadot TX, you need to enter or paste the extrinsic id of transaction</div>}

              {jsonData?.errorIn !== "Wallet Check" && (
              <div className='paypal-btn-container'>
                    <Button type="submit" id={isPayPalEnabled ? "custom-paypal-btn" : "disabled-button"} icon={<SlPaypal id='paypal-btn-icon' />}
                    onClick={() => {
                        console.log('Pay with PayPal')
                        // TriggerPayments()
                        handleSubmit()

                        }}>
                        Pay with PayPal - ${payAmount ? payAmount?.toFixed(2) : 0}
                    </Button>
              </div>)}

              
              {jsonData?.errorIn === "Wallet Check" && (
              <div className='paypal-btn-container'>
                    <Button type="submit" id={isPayPalEnabled ? "custom-paypal-btn" : "disabled-button"} 
                    // icon={<SlPaypal id='paypal-btn-icon' />}
                    onClick={() => {
                        console.log("Resubmit W/O PayPal WC");
                        handleReSubmit(jsonData);

                        }}>
                        Resubmit
                    </Button>
              </div>)}
        </div>
    );
};

export default ChainButtons;