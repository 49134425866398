import React, { useState } from "react";
import "./newLoginPage.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoMdEyeOff } from "react-icons/io";
import {
  baseApiUrl,
} from "../../constants/environment.constants";
import Loader from "../../components/Loader/Loader";


const NewLoginPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)

  // useState for invalid credentials error
  const [invalidCredErr, setInvalidCredErr] = useState("");

  /*useState for show and hide password text */
  const [showPassword, setShowPassword] = useState(false);

  const postFormDataHandler = async (payload) => {
    try {
      setIsLoading(true);
      const resp = await axios.post(baseApiUrl + "login", payload);
      // const resp = await axios.post(localBaseApiUrl + "login", payload);

      if (resp?.data?.success === true) {
        sessionStorage.setItem("isLoggedIn", true);
        sessionStorage.setItem("userToken", resp?.data?.token);
        sessionStorage.setItem(
          "loggedUserData",
          JSON.stringify(resp?.data?.data)
        );
        navigate("/");
        window.location.reload();
      }
      setIsLoading(false);
    } catch (err) {
      // console.log("Error while login at front-end side-->", err);
      if (
        err?.response?.data?.isInvalidCreds === true &&
        err?.response?.status === 401
      ) {
        setInvalidCredErr(err?.response?.data?.message);
        setIsLoading(false);
      } else {
        setInvalidCredErr("");
      }
    }
  };

  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      let payload = {
        // username : values?.username,
        email: values?.email,
        password: values?.password,
      };
      postFormDataHandler(payload);
    },
    validate: (values) => {
      let errors = {};
      // if(!values.username){
      //     errors.username = "Username is required";
      // }

      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      } else if (/^\s+|\s+$/.test(values.email)) {
        errors.email = "Email should not contain spaces.";
      }

      if (!values.password) {
        errors.password = "Password is required";
      } else if (/^\s+|\s+$/.test(values.password)) {
        errors.password = "Password should not contain spaces.";
      }else if (/\s/.test(values.password)) {
        errors.password = "Password should not contain spaces.";
      }

      return errors;
    },
  });

  return (
    <div className="bg-blue">
      <Loader isLoading={isLoading}/>
      <div className="container">
        <div className="row p-3 overlay">
          <div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 my-auto justify-content-center text-center">
            <div className="sectionStartForm px-5 py-4">
              <img
                src="/cobalt-icon.svg"
                alt="fintrack-logo"
                className="form-header img-fluid"
              />
              {/* <p className="font-weight-bold py-2">Merchant Login</p> */}
              {invalidCredErr && invalidCredErr.length ? (
                <div className="login-error">{invalidCredErr}</div>
              ) : null}

              <form onSubmit={loginForm.handleSubmit}>

                <div className="lg-form-label-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    value={loginForm.values.email}
                    onChange={loginForm.handleChange}
                    onBlur={loginForm.handleBlur}
                  />
                  <label>Email Address</label>
                </div>
                {loginForm.touched.email && loginForm.errors.email ? (
                  <div className="login-error">{loginForm.errors.email}</div>
                ) : null}


                <div className="lg-form-label-group">
                  <input
                    // type="password"
                    type={showPassword ? "text" : "password"}
                    style={{paddingRight : "40px"}}
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={loginForm.values.password}
                    onChange={loginForm.handleChange}
                    onBlur={loginForm.handleBlur}
                  />
                  <label>Password</label>
                  <div
                    className="password-toggle-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <MdOutlineRemoveRedEye className="passwod-icon" />
                    ) : (
                      <IoMdEyeOff className="passwod-icon" />
                    )}
                  </div>
                </div>
                {loginForm.touched.password && loginForm.errors.password ? (
                  <div className="login-error">{loginForm.errors.password}</div>
                ) : null}

                <div className="lg-form-label-group mt-4">
                  <button
                    type="submit"
                    className="btn btn-info font-weight-bold text-uppercase w-50"
                  >
                    login
                  </button>
                </div>
              </form>
              <p className="login-btm-text text-decoration mt-3">
                <span onClick={() => navigate("/forgot-password")}>
                  Forgot Your Password?
                </span>
              </p>
              <p className="lg-text text-decoration pointer">
                Don't have an account?{" "}
                <span onClick={() => navigate("/signup")}>Sign up</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLoginPage;
