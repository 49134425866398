
export const localBaseApiUrl = "http://localhost:5000/fintracking/api/v1/auth/"
export const localBaseUrl = "http://localhost:5000/fintracking/api/v1/"

/* environment variables for Dev Server on http://99.79.181.87/ */ 
// export const baseApiUrl = "http://99.79.181.87/fintracking/api/v1/auth/"
// export const baseUrl = "http://99.79.181.87/fintracking/api/v1/"

/* environment variables for Prod Server*/
export const baseApiUrl = "https://api.fintracking.ca/fintracking/api/v1/auth/"
export const baseUrl = "https://api.fintracking.ca/fintracking/api/v1/"


/*
    Credentials for OAuth with Google
*/ 
export const GoogleClientId = "280995316977-3lkkruagj3t86iuuiumosvmrln4kgtc6.apps.googleusercontent.com"
export const GoogleClientSecret = "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"


export const AMLBotAccessKey = "Q5O1Lv8tq3N-LdmwLaa-Oe974L4U-kwdqPuF-Ueexqp5Vf-IcxGKOwe-ilMH03Y87U8"
export const AMLBotAccessId = "8E294-AEB8D-977742A"
export const AMLBOT_TOKEN = "bf33bfcb0ac4c148c11a8dd189dd2ba08a29"