import React, { useState } from "react";
import "./verifications.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { FaListUl } from "react-icons/fa6";
import { FaRegFilePdf } from "react-icons/fa";
import Navbar from "../../components/navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { countryCodes } from "../../helpers/countryCodes.helper";
import { generateKycFullResult } from "../../helpers/pdfHelpers/kycFullVerificationResult.helper";
import Loader from "../../components/Loader/Loader";

const Verifications = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // Getting data from query params
  const location = useLocation();
  const jsonDataString = location?.state?.data;
  const jsonData = JSON.parse(jsonDataString || "{}");
  // console.log("jsonData==>", jsonData);

  const applicantInfoString = location?.state?.applicantInfo;
  const applicantInfoData = JSON.parse(applicantInfoString || "{}");
  // console.log("applicantInfoData==>", applicantInfoData);

  let apiDate;
  if (applicantInfoData?.created_at) apiDate = applicantInfoData?.created_at;

  const formattedDate = moment(apiDate, "YYYY-MM-DD HH:mm:ss").format(
    "DD-MM-YYYY HH:mm"
  );

  // let optionSelected = jsonData?.optionSelected || "-";
  // if (optionSelected.includes(",")) {
  //   optionSelected = optionSelected
  //     .split(",")
  //     // .slice(0, 2)
  //     .map((word) => word.trim().charAt(0).toUpperCase() + word.slice(1))
  //     .join(", ");
  // } else {
  //   optionSelected =
  //     optionSelected.trim().charAt(0).toUpperCase() + optionSelected.slice(1);
  // }

  let optionSelected = Object.keys(jsonData?.report?.verifications)
    ? Object.keys(jsonData?.report?.verifications).toString()
    : "";
  let words = optionSelected.split(",");
  let formattedOptions = words.map((word) => {
    return word
      .trim()
      .replace(/_/g, " ")
      .replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  });
  let formattedOptionSelected = formattedOptions.join(", ");

  // let options = jsonData?.optionSelected?.split(",");
  // console.log("OPTIONS==>", options)

  const handleDownloadPdf = () => {
    try {
      setIsLoading(true);
      // console.log('PDF DOWNL CALLED')

      generateKycFullResult(jsonData, applicantInfoData);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (err) {
      console.log("Error while download pdf", err.message);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <Loader isLoading={isLoading} />
      <div className="vw-wrapper flex-grow-1 p-4">
        <div className="row vw-title-btn-container">
          {/* <!-- Title --> */}
          <div className="col-lg-4 col-md-12 col-sm-12 mt-2">
            <div className="d-flex vw-title-icon-contnr">
              <FaListUl style={{ fontSize: "18px" }} />
              <h5 className="pl-2 mt-2 vw-title">Verifications Result</h5>
            </div>
          </div>

          {/* col-md-10 */}
          <div className="col-lg-3 col-sm-12 mt-2">
            <button
              onClick={() => navigate("/transactions")}
              className="v-btn v-btn-info form-control font-weight-bold w-100"
              style={{height: "38px"}}
            >
              <ArrowLeftOutlined /> Back to Dashboard
            </button>
          </div>
        </div>
      </div>

      <div className="download-btn-container mt-3 mb-2">
        <div className="download-pdf-btn" onClick={handleDownloadPdf}>
          <FaRegFilePdf size={20} />
          <span>Download PDF</span>
        </div>
      </div>

      <div className="main__right-block">
        <div className="main-content-wrappe">
          <div className="verification-inside">
            {/* Top Details Section */}
            <div className="inside-main-block">
              <div className="inside-title">
                {/* <h2>John Doe or Company Name</h2> */}
                <h2>{jsonData?.clientName || ""}</h2>
                <p className="">{formattedDate}</p>
              </div>

              <div className="inside-info">
                <div className="inside-left">
                  {/* <div className="inside-row">
                    <div>Mode</div>
                    <div className="inside-mode">
                      <i className="test"></i>
                      <span>Test</span>
                    </div>
                  </div> */}
                  <div className="inside-row">
                    <div>Status</div>
                    <div className="inside-status">
                      <span
                        className={
                          jsonData?.report?.verified === false
                            ? "decline-status"
                            : "approved-status"
                        }
                      >
                        {jsonData?.report?.verified === false
                          ? "Declined"
                          : "Approved"}
                      </span>
                    </div>
                  </div>
                  <div className="inside-row">
                    <div>Verification ID</div>
                    <div>{jsonData?.report?.verification_id || "-"}</div>
                  </div>
                  <div className="inside-row">
                    <div>Application ID</div>
                    <div>{jsonData?.report?.applicant_id || "-"}</div>
                  </div>
                  <div className="inside-row">
                    <div>External ID</div>
                    <div>{applicantInfoData?.external_applicant_id || "-"}</div>
                  </div>
                  <div className="inside-row">
                    <div>Price</div>
                    <div>
                      {`${jsonData?.amountPaid?.toFixed(2)} CAD` || "-"}
                    </div>
                  </div>
                  <div className="inside-row">
                    <div>Form</div>
                    <div>{jsonData?.serviceType || "-"}</div>
                  </div>
                  <div className="inside-row">
                    <div>Verification types</div>
                    <div>{formattedOptionSelected}</div>
                  </div>
                </div>

                <div className="inside-right">
                  {applicantInfoData?.company_name && (
                    <div className="inside-row">
                      <div>Company name</div>
                      <div>{applicantInfoData?.company_name || "-"}</div>
                    </div>
                  )}

                  {applicantInfoData?.company_name && (
                    <div className="inside-row">
                      <div>Business activity</div>
                      <div>
                        {applicantInfoData?.business_activity[0]?.label || "-"}
                      </div>
                    </div>
                  )}

                  {/* {jsonData?.serviceType === "KYC Full" ||
                    (jsonData?.serviceType === "KYC Lite" && ( */}
                      <div className="inside-row">
                        <div>First Name</div>
                        <div>{applicantInfoData?.first_name || "-"}</div>
                      </div>
                    {/* ))} */}

                  {jsonData?.serviceType === "KYC Full" ||
                    (jsonData?.serviceType === "KYC Lite" && (
                      <div className="inside-row">
                        <div>Middle Name</div>
                        <div>{applicantInfoData?.middle_name || "-"}</div>
                      </div>
                    ))}

                  {/* {jsonData?.serviceType === "KYC Full" ||
                    (jsonData?.serviceType === "KYC Lite" && ( */}
                      <div className="inside-row">
                        <div>Last Name</div>
                        <div>{applicantInfoData?.last_name || "-"}</div>
                      </div>
                    {/* ))} */}

                  <div className="inside-row">
                    <div>Residence country</div>
                    <div>
                      {countryCodes[
                        applicantInfoData?.residence_country ||
                          applicantInfoData?.registration_country
                      ] || "-"}
                    </div>
                  </div>

                  {jsonData?.serviceType === "KYC Full" ||
                    (jsonData?.serviceType === "KYC Lite" && (
                      <div className="inside-row">
                        <div>Date of birth</div>
                        <div>{applicantInfoData?.dob || "-"}</div>
                      </div>
                    ))}

                  <div className="inside-row">
                    <div>Email</div>
                    <div>{applicantInfoData?.email || "-"}</div>
                  </div>

                  {
                    <div className="inside-row">
                      <div>Phone</div>
                      <div>{applicantInfoData?.phone || "-"}</div>
                    </div>
                  }

                  {applicantInfoData?.pep && (
                    <div className="inside-row">
                      <div>PEP</div>
                      <div>
                        {applicantInfoData?.pep === true ? "Yes" : "No"}
                      </div>
                    </div>
                  )}

                  {jsonData?.report?.verifications?.profile?.comment && (
                    <div className="inside-row">
                      <div>Decline reasons</div>
                      <div>
                        {jsonData?.report?.verifications?.profile?.comment ||
                          "-"}
                      </div>
                    </div>
                  )}

                  {/* <div className="inside-row">
                    <div>Comment</div>
                    <div>Verification request was declined.</div>
                  </div> */}

                  {/* <div className="inside-row">
                    <div>Ludoman</div>
                    <div>Unknown</div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="inside-second-block">
              {applicantInfoData?.documents?.map((doc, index) => {
                // console.log("CHECK DOCS==>", doc);
                if (doc.type === "DRIVERS_LICENSE") {
                  return(
                    <div className="second-block-left" key={index}>
                      <h3>Drivers license</h3>
                      <div className="passport-info">
                        <div className="passport-row">
                          <div>Document number</div>
                          <div>{doc?.document_number || "-"}</div>
                        </div>
                        <div className="passport-row">
                          <div>Document issue date</div>
                          <div>{doc?.issue_date || "-"}</div>
                        </div>
                        <div className="passport-row">
                          <div>Document expiry date</div>
                          <div>{doc?.expiry_date || "-"}</div>
                        </div>
                        {doc?.portrait && (
                        <div className="passport-row">
                          <div>Portrait photo</div>
                          <img src={doc?.portrait} alt="" style={{height: "150px", width: "120px", objectFit: "cover"}}/>
                        </div>)}
                      </div>

                      <div className="passport-foto">
                        {doc?.front_side && (
                          <div className="passport-foto-left">
                            <div>
                              <span>Front side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.front_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.front_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}

                        {doc?.back_side && (
                          <div className="passport-foto-right">
                            <div>
                              <span>Back side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.back_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.back_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }
                
                
                
                
                // console.log("CHECK DOCS==>", doc);
                if (doc.type === "REGISTRATION_COMPANY") {
                  return (
                    <div className="second-block-left" key={index}>
                      <h3>Registration company</h3>
                      <div className="passport-info">
                        <div className="passport-row">
                          <div>Document number</div>
                          <div>{doc?.document_number || "-"}</div>
                        </div>

                        {doc?.expiry_date && (
                          <div className="passport-row">
                            <div>Document expiry date</div>
                            <div>{doc?.expiry_date || "-"}</div>
                          </div>
                        )}

                        {/* <div className="passport-row">
                          <div>Decline reasons</div>
                          <div>
                            Please, resubmit the form and upload the document of
                            a higher quality. Make sure that all 4 corners of
                            the document are visible, the image is clear (no
                            blurriness), it is not cropped or edited.
                          </div>
                        </div> */}

                        {/* <div className="passport-row">
                          <div>Comment</div>
                          <div>Provided document is wrong.</div>
                        </div> */}
                      </div>

                      <div className="passport-foto">
                        {doc?.front_side && (
                          <div className="passport-foto-left">
                            <div>
                              <span>Front side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.front_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.front_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}

                        {doc?.back_side && (
                          <div className="passport-foto-right">
                            <div>
                              <span>Back side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.back_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.back_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className=""></div>
                    </div>
                  );
                }

                if (doc.type === "COMPANY_LEGAL_ADDRESS") {
                  return (
                    <div className="second-block-left" key={index}>
                      <h3>Company legal address</h3>

                      <div className="passport-info">
                        {doc?.document_number && (
                          <div className="passport-row">
                            <div>Document number</div>
                            <div>{doc?.document_number || "-"}</div>
                          </div>
                        )}

                        {doc?.expiry_date && (
                          <div className="passport-row">
                            <div>Document expiry date</div>
                            <div>{doc?.expiry_date || "-"}</div>
                          </div>
                        )}
                      </div>
                      {/* <div className="passport-info">
                            <div className="passport-row">
                                <div>Document number</div>
                                <div>45345454545</div>
                            </div>
                            <div className="passport-row">
                                <div>Document expiry date</div>
                                <div>2029-04-25</div>
                            </div>
                            <div className="passport-row">
                                <div>Decline reasons</div>
                                <div>Please, resubmit the form and upload the document of a higher quality. Make sure that all 4 corners of the document are visible, the image is clear (no blurriness), it is not cropped or edited.</div>
                            </div>
                            <div className="passport-row">
                                <div>Comment</div>
                                <div>Provided document is wrong.</div>
                            </div>
                        </div> */}

                      <div className="passport-foto">
                        {doc?.front_side && (
                          <div className="passport-foto-left">
                            <div>
                              <span>Front side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.front_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.front_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}

                        {doc?.back_side && (
                          <div className="passport-foto-right">
                            <div>
                              <span>Back side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.back_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.back_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className=""></div>
                    </div>
                  );
                }

                if (doc.type === "CREDIT_CARD") {
                  return (
                    <div className="second-block-left" key={index}>
                      <h3>Credit card</h3>
                      <div className="passport-info">
                        {/* <div className="passport-row">
                                <div>Document number</div>
                                <div>45345454545</div>
                            </div> */}
                        {/* <div className="passport-row">
                                <div>Document expiry date</div>
                                <div>2029-04-25</div>
                            </div> */}
                        {jsonData?.report?.verifications?.document?.comment && (
                          <div className="passport-row">
                            <div>Decline reasons</div>
                            <div>
                              {jsonData?.report?.verifications?.document
                                ?.comment || "-"}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="passport-foto">
                        {doc?.front_side && (
                          <div className="passport-foto-left">
                            <div>
                              <span>Front side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.front_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.front_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}

                        {doc?.back_side && (
                          <div className="passport-foto-right">
                            <div>
                              <span>Back side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.back_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.back_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }

                if (doc.type === "ADDRESS_DOCUMENT") {
                  return (
                    <div className="second-block-left" key={index}>
                      <h3>Address document</h3>
                      <div className="passport-info">
                        {/* <div className="passport-row">
                                <div>Document number</div>
                                <div>45345454545</div>
                            </div> */}
                        {/* <div className="passport-row">
                                <div>Document expiry date</div>
                                <div>2029-04-25</div>
                            </div> */}
                        {jsonData?.report?.verifications?.address?.comment && (
                          <div className="passport-row">
                            <div>Decline reasons</div>
                            <div>
                              {jsonData?.report?.verifications?.address
                                ?.comment || "-"}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="passport-foto">
                        {doc?.front_side && (
                          <div className="passport-foto-left">
                            <div>
                              <span>Front side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.front_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.front_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}

                        {doc?.back_side && (
                          <div className="passport-foto-right">
                            <div>
                              <span>Back side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.back_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.back_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }

                if (doc.type === "FINANCIAL_DOCUMENT") {
                  return (
                    <div className="second-block-left" key={index}>
                      <h3>Financial document</h3>
                      <div className="passport-info">
                        <div className="passport-row">
                          <div>Income sources</div>
                          <div>
                            {doc?.income_sources[0]?.charAt(0).toUpperCase() +
                              doc?.income_sources[0]?.slice(1).toLowerCase() ||
                              "-"}
                          </div>
                        </div>
                        {/* <div className="passport-row">
                                <div>Document expiry date</div>
                                <div>2029-04-25</div>
                            </div> */}
                        {jsonData?.report?.verifications?.document?.comment && (
                          <div className="passport-row">
                            <div>Decline reasons</div>
                            <div>
                              {jsonData?.report?.verifications?.document
                                ?.comment || "-"}
                            </div>
                          </div>
                        )}

                        {/* <div className="passport-row">
                          <div>Comment</div>
                          <div>{jsonData?.report?.verifications?.document?.comment || "-"}</div>
                        </div> */}
                      </div>

                      <div className="passport-foto">
                        {doc?.front_side && (
                          <div className="passport-foto-left">
                            <div>
                              <span>Front side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.front_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.front_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}

                        {doc?.back_side && (
                          <div className="passport-foto-right">
                            <div>
                              <span>Back side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.back_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.back_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }

                if (doc.type === "VIDEO") {
                  return (
                    <div className="second-block-left" key={index}>
                      <h3>Video document</h3>
                      <div className="passport-foto">
                        <div className="passport-foto-left">
                          <video
                            src={doc?.front_side}
                            width="100%"
                            height="100%"
                            controls
                          >
                            {/* <source src={doc?.front_side} type="video/mp4"/> */}
                          </video>
                        </div>
                      </div>
                    </div>
                  );
                }

                if (doc.type === "TAX_ID_NUMBER") {
                  return (
                    <div className="second-block-left" key={index}>
                      <h3>Tax ID</h3>
                      <div className="passport-info">
                        {/* <div className="passport-row">
                                <div>Document number</div>
                                <div>45345454545</div>
                            </div> */}
                        {/* <div className="passport-row">
                                <div>Document expiry date</div>
                                <div>2029-04-25</div>
                            </div> */}
                        {jsonData?.report?.verifications?.tax_id?.comment && (
                          <div className="passport-row">
                            <div>Decline reasons</div>
                            <div>
                              {jsonData?.report?.verifications?.tax_id
                                ?.comment || "-"}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="passport-foto">
                        {doc?.front_side && (
                          <div className="passport-foto-left">
                            <div>
                              <span>Front side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.front_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.front_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}

                        {doc?.back_side && (
                          <div className="passport-foto-right">
                            <div>
                              <span>Back side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.back_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.back_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }

                if (doc.type === "SELFIE_IMAGE") {
                  return (
                    <div className="second-block-left" key={index}>
                      <h3>Selfie image</h3>
                      <div className="passport-info">
                        {/* <div className="passport-row">
                                <div>Document number</div>
                                <div>45345454545</div>
                            </div>
                            <div className="passport-row">
                                <div>Document expiry date</div>
                                <div>2029-04-25</div>
                            </div> */}
                        {jsonData?.report?.verifications?.document?.comment && (
                          <div className="passport-row">
                            <div>Decline reasons</div>
                            <div>
                              {jsonData?.report?.verifications?.document
                                ?.comment || "-"}
                            </div>
                          </div>
                        )}
                      </div>

                      <div>
                        <div className="passport-foto">
                          {doc?.front_side && (
                            <div className="passport-foto-left">
                              <div>
                                <a
                                  href={doc?.front_side}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img src={doc?.front_side} alt="" />
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }

                if (doc.type === "GOVERNMENT_ID") {
                  return (
                    <div className="second-block-left" key={index}>
                      <h3>Government ID</h3>
                      <div className="passport-info">
                        {doc?.document_number && (
                          <div className="passport-row">
                            <div>Document number</div>
                            <div>{doc?.document_number || "-"}</div>
                          </div>
                        )}

                        {doc?.expiry_date && (
                          <div className="passport-row">
                            <div>Document expiry date</div>
                            <div>{doc?.expiry_date || "-"}</div>
                          </div>
                        )}

                        {jsonData?.report?.verifications?.document?.comment && (
                          <div className="passport-row">
                            <div>Decline reasons</div>
                            <div>
                              {jsonData?.report?.verifications?.document
                                ?.comment || "-"}
                            </div>
                          </div>
                        )}

                        {/* <div className="passport-row">
                          <div>Comment</div>
                          <div>Provided document is wrong.</div>
                        </div> */}
                      </div>

                      <div className="passport-foto">
                        {doc?.front_side && (
                          <div className="passport-foto-left">
                            <div>
                              <span>Front side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.front_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.front_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}

                        {doc?.back_side && (
                          <div className="passport-foto-right">
                            <div>
                              <span>Back side</span>
                            </div>
                            <div>
                              <a
                                href={doc?.back_side}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={doc?.back_side} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className=""></div>
                    </div>
                  );
                }
                return null;
              })}

              {applicantInfoData?.addresses &&
                applicantInfoData?.addresses?.length > 0 && (
                  <div className="second-block-left">
                    {/* <h3>Registered address / Business address</h3> */}
                    <h3>
                      {applicantInfoData?.addresses[0]?.type === "BUSINESS"
                        ? "Business address"
                        : "Registered address"}
                    </h3>
                    <div className="passport-info">
                      <div className="passport-row">
                        <div>Country</div>
                        <div>
                          {countryCodes[
                            applicantInfoData?.addresses[0]?.country
                          ] || "-"}
                        </div>
                      </div>
                      <div className="passport-row">
                        <div>State/province</div>
                        <div>
                          {applicantInfoData?.addresses[0]?.state_or_province ||
                            "-"}
                        </div>
                      </div>
                      <div className="passport-row">
                        <div>City</div>
                        <div>
                          {applicantInfoData?.addresses[0]?.city || "-"}
                        </div>
                      </div>
                      <div className="passport-row">
                        <div>Postal code</div>
                        <div>
                          {applicantInfoData?.addresses[0]?.postal_code || "-"}
                        </div>
                      </div>
                      <div className="passport-row">
                        <div>Street name</div>
                        <div>
                          {applicantInfoData?.addresses[0]?.street_name || "-"}
                        </div>
                      </div>
                      <div className="passport-row">
                        <div>Building number</div>
                        <div>
                          {applicantInfoData?.addresses[0]?.building_number ||
                            "-"}
                        </div>
                      </div>
                      <div className="passport-row">
                        <div>Unit number</div>
                        <div>
                          {applicantInfoData?.addresses[0]?.unit_number || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>

            {applicantInfoData?.affiliated_persons?.map((elem, index) => {
              if (elem?.type === "AUTHORISED") {
                return (
                  <div className="inside-main-block" key={index}>
                    <div className="inside-title">
                      <h2>Authorised person</h2>
                    </div>

                    <div className="inside-info">
                      <div className="inside-left">
                        <div className="inside-row">
                          <div>First Name</div>
                          <div>{elem?.first_name || "-"}</div>
                        </div>
                        <div className="inside-row">
                          <div>Last Name</div>
                          <div>{elem?.last_name || "-"}</div>
                        </div>
                      </div>

                      <div className="inside-right">
                        <div className="inside-row">
                          <div>Country</div>
                          <div>
                            {countryCodes[elem?.residence_country] || "-"}
                          </div>
                        </div>
                        <div className="inside-row">
                          <div>Date of birth</div>
                          <div>{elem?.dob || "-"}</div>
                        </div>
                        <div className="inside-row">
                          <div>Email</div>
                          <div>{elem?.email || "-"}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }

              if (elem?.type === "BENEFICIAL") {
                return (
                  <div className="inside-main-block" key={index}>
                    <div className="inside-title">
                      <h2>Beneficiary</h2>
                    </div>

                    <div className="inside-info">
                      <div className="inside-left">
                        <div className="inside-row">
                          <div>Share</div>
                          <div>{elem?.share || "-"}</div>
                        </div>
                        <div className="inside-row">
                          <div>First Name</div>
                          <div>{elem?.first_name || "-"}</div>
                        </div>
                        <div className="inside-row">
                          <div>Last Name</div>
                          <div>{elem?.last_name || "-"}</div>
                        </div>
                      </div>

                      <div className="inside-right">
                        <div className="inside-row">
                          <div>Country</div>
                          <div>
                            {countryCodes[elem?.residence_country] || "-"}
                          </div>
                        </div>
                        <div className="inside-row">
                          <div>Date of birth</div>
                          <div>{elem?.dob || "-"}</div>
                        </div>
                        <div className="inside-row">
                          <div>Email</div>
                          <div>{elem?.email || "-"}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verifications;
