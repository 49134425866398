import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is logged in.
    const userToken = sessionStorage.getItem('userToken');
    setLoggedIn(!!userToken);

    // If not logged in, redirect to the login page
    // if (!userToken) {
    //   navigate('/login');
    // }
  }, [navigate]);

  return { isLoggedIn };
};

export default useAuth;
