import React, { useEffect, useState } from "react";
import "./newVerifyPerson.css";
import Navbar from "../../components/navbar/Navbar";
import { IdcardOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "antd";
import { SlPaypal } from "react-icons/sl";
import { useFormik } from "formik";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { TriggerPayments } from "../../helpers/paypal.helper";
import { captitalizeEveryField } from "../../helpers/capitalize.helper";
import { baseUrl } from "../../constants/environment.constants";

const NewVerifyPerson = () => {
  const navigate = useNavigate();

  // Getting Resubmit handler data as query params
  const location = useLocation();
  const jsonDataString = location?.state?.jsonData;
  const jsonData = JSON.parse(jsonDataString || "{}");

  // console.log("JSON-DATA-->", jsonData);

  const [fullKycPrice, setFullKycPrice] = useState(0);
  const [lightKycPrice, setLightKycPrice] = useState(0);

  const [serviceType, setServiceType] = useState("");
  const [payAmount, setPayAmount] = useState();
  const [isPayPalEnabled, setIsPayPalEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getDynamicPrices = async () => {
    try {
      setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      /*Get Dynamic Full KYC Price */
      const getFullKycPrices = await axios.get(
        baseUrl + "prices?serviceType=KYC Full",
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      if (getFullKycPrices?.data && getFullKycPrices?.data?.price)
        setFullKycPrice(getFullKycPrices?.data?.price);

      /*Get Dynamic Light KYC Price */
      const getLightKycPrices = await axios.get(
        baseUrl + "prices?serviceType=KYC Lite",
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      if (getLightKycPrices?.data && getLightKycPrices?.data?.price)
        setLightKycPrice(getLightKycPrices?.data?.price);

      setIsLoading(false);
    } catch (err) {
      console.log(
        "Error while getting dynamic Prices from verify Person -->",
        err.message
      );
      setIsLoading(false);
    }
  };

  /* useEffect to get Dynamic Prices*/
  useEffect(() => {
    getDynamicPrices();
  }, []);

  const kycForm = useFormik({
    initialValues: {
      clientName: "",
      clientEmail: "",
    },
    onSubmit: {},
    validate: (values) => {
      let errors = {};

      /*Validations for clientName */
      if (!values.clientName) {
        errors.clientName = "Client full name is required";
      } else if (/^\s+|\s+$/.test(values.clientName)) {
        errors.clientName = "Full name should not contain extra spaces.";
      } else if (!/^[a-zA-Z\s]+$/.test(values.clientName)) {
        errors.clientName = "Only letters are allowed for the Full Name";
      }

      /*Validations for clientEmail */
      if (!values.clientEmail) {
        errors.clientEmail = "Client email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.clientEmail)
      ) {
        errors.clientEmail = "Invalid email address";
      } else if (/^\s+|\s+$/.test(values.clientEmail)) {
        errors.clientEmail = "Email should not contain spaces.";
      }

      return errors;
    },
  });

  const { values } = kycForm;

  useEffect(() => {
    if (
      values?.clientName.trim() !== "" &&
      values?.clientEmail.trim() !== "" &&
      serviceType !== "" &&
      !kycForm.errors.clientName &&
      !kycForm.errors.clientEmail
    ) {
      setIsPayPalEnabled(true);
    } else {
      setIsPayPalEnabled(false);
    }
  }, [
    values?.clientName,
    values?.clientEmail,
    serviceType,
    kycForm.errors.clientName,
    kycForm.errors.clientEmail,
  ]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const userToken = sessionStorage.getItem("userToken");

      const payload = {
        clientName: values?.clientName?.trim(),
        clientEmail: values?.clientEmail?.trim(),
        serviceType: serviceType,
        amountPaid: payAmount,
        userToken: userToken,
      };

      TriggerPayments(payload);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (err) {
      console.log("Error while Verify Person-->", err.message);
      setIsLoading(false);
    }
  };

  // Resubmit handler
  const handleReSubmit = async (eData) => {
    // console.log("resubmitFor==>", eData);

    try{
      setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      const kycPayload = {
        clientName : kycForm?.values?.clientName,
        clientEmail : kycForm?.values?.clientEmail,
        id : eData?._id
    };

      const resp = await axios.put(baseUrl + `kyc?serviceType=${eData?.errorIn}`, kycPayload, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      
      if(resp?.data?.success === true){
        navigate("/transactions")
      }

      setIsLoading(false);
    }catch(err){
      console.log("Error while resubmit KYC ", err.message)
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <Loader isLoading={isLoading} />
      <div className="d-flex flex-column overview-wrapper">
        <div className="wrapper flex-grow-1 p-4">
          <div className="row title-btn-container">
            {/* <!-- Title --> */}
            <div className="col-lg-4 col-md-12 col-sm-12 mt-2">
              <div className="d-flex title-icon-contnr">
                <IdcardOutlined style={{ fontSize: "18px" }} />
                <h5 className="pl-2 mt-2 title">Verify Person</h5>
              </div>
            </div>

            {/* col-md-10 */}
            <div className="col-lg-3 col-sm-12 mt-2">
              <button
                onClick={() => navigate("/")}
                className="v-btn v-btn-info form-control font-weight-bold w-100"
                style={{height: "38px"}}
              >
                <ArrowLeftOutlined /> Back to Product
              </button>
            </div>
          </div>

          <div className="container">
            <div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 my-auto justify-content-center">
              <div className="v-sectionStartForm px-5 py-4">
                <form>
                  <div
                    className="form-label-group"
                    style={{ marginBottom: "2px" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="clientName"
                      value={captitalizeEveryField(kycForm.values.clientName)}
                      onChange={kycForm.handleChange}
                      onBlur={kycForm.handleBlur}
                    />
                    <label>Enter full name of your client</label>
                  </div>
                  {kycForm.touched.clientName && kycForm.errors.clientName ? (
                    <div className="signup-error">
                      {kycForm.errors.clientName}
                    </div>
                  ) : null}

                  <div
                    className="form-label-group"
                    style={{ marginBottom: "2px", marginTop: "1.5rem" }}
                  >
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                      name="clientEmail"
                      value={kycForm.values.clientEmail}
                      onChange={kycForm.handleChange}
                      onBlur={kycForm.handleBlur}
                    />
                    <label>Enter email of your client</label>
                  </div>
                  {kycForm.touched.clientEmail && kycForm.errors.clientEmail ? (
                    <div className="signup-error">
                      {kycForm.errors.clientEmail}
                    </div>
                  ) : null}

                  <div className="mid-container justify-content-left">
                    <span className="vp-mid-first-span">
                      Your client will be sent a link with instructions
                    </span>
                    <p>Select an Option :</p>

                    {jsonData?.errorIn === "kycfull" && (
                      <div className="radio-container mt-3">
                        <input
                          type="radio"
                          name="verificationOption"
                          value="fullKYC"
                          id="fullKYC"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setPayAmount(fullKycPrice);
                            setServiceType("kycfull");
                          }}
                        />
                        <label htmlFor="fullKYC" className="radio-label">
                          Full KYC with liveliness check :{" "}
                          <span className="vp-price">
                            ${fullKycPrice?.toFixed(2)}/-
                          </span>
                        </label>
                      </div>
                    )}

                    {jsonData?.errorIn === "kyclight" && (
                      <div className="radio-container mt-3">
                        <input
                          type="radio"
                          name="verificationOption"
                          value="lightKYC"
                          className="lightKYC"
                          id="lightKYC"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setPayAmount(lightKycPrice);
                            setServiceType("kyclight");
                          }}
                        />
                        <label htmlFor="lightKYC" className="radio-label">
                          Lite KYC ID with Face check :{" "}
                          <span className="vp-price">
                            ${lightKycPrice?.toFixed(2)}/-
                          </span>
                        </label>
                      </div>
                    )}

                    {jsonData?.errorIn !== "kyclight" &&
                      jsonData?.errorIn !== "kycfull" && (
                        <>
                          <div className="radio-container mt-3">
                            <input
                              type="radio"
                              name="verificationOption"
                              value="fullKYC"
                              id="fullKYC"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setPayAmount(fullKycPrice);
                                setServiceType("kycfull");
                              }}
                            />
                            <label htmlFor="fullKYC" className="radio-label">
                              Full KYC with liveliness check :{" "}
                              <span className="vp-price">
                                ${fullKycPrice?.toFixed(2)}/-
                              </span>
                            </label>
                          </div>

                          <div className="radio-container mt-3">
                            <input
                              type="radio"
                              name="verificationOption"
                              value="lightKYC"
                              className="lightKYC"
                              id="lightKYC"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setPayAmount(lightKycPrice);
                                setServiceType("kyclight");
                              }}
                            />
                            <label htmlFor="lightKYC" className="radio-label">
                              Lite KYC ID with Face check :{" "}
                              <span className="vp-price">
                                ${lightKycPrice?.toFixed(2)}/-
                              </span>
                            </label>
                          </div>
                        </>
                      )}
                  </div>

                  {jsonData?.errorIn !== "kyclight" &&
                    jsonData?.errorIn !== "kycfull" && (
                      <div className="paypal-btn-container mt-5">
                        <Button
                          type="submit"
                          id={
                            isPayPalEnabled
                              ? "custom-paypal-btn"
                              : "disabled-button"
                          }
                          icon={<SlPaypal id="paypal-btn-icon" />}
                          onClick={() => {
                            console.log("Pay with PayPal");
                            handleSubmit();
                          }}
                        >
                          Pay with PayPal{" "}
                          {payAmount ? "- $" + payAmount?.toFixed(2) : ""}
                        </Button>
                      </div>
                    )}

                  
                  {jsonData?.errorIn === "kyclight" ||
                    jsonData?.errorIn === "kycfull" ? (
                      <div className="paypal-btn-container mt-5">
                        <Button
                          type="submit"
                          id={
                            isPayPalEnabled
                              ? "custom-paypal-btn"
                              : "disabled-button"
                          }
                          // icon={<SlPaypal id="paypal-btn-icon" />}
                          onClick={() => handleReSubmit(jsonData)}>
                          Resubmit
                        </Button>
                      </div>
                    ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewVerifyPerson;
