import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

const options = {
    icon: true,
    theme: "colored",
    hideProgressBar: false,
    position: "top-right",
};


export const HandleSuccess = (successData) => {
    console.log("toast called")
    toast.dismiss();
    setTimeout(() => {
      toast.success(successData, options);
    }, 1000);
};


export const HandleError = (errorData) => {
    // console.log("toast called")
    toast.dismiss();
    setTimeout(() => {
      toast.error(errorData, options);
    }, 1000);
};