import React, { useEffect, useState } from "react";
import "./newVerifyWallet.css";
import Navbar from "../../components/navbar/Navbar";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { GiWallet } from "react-icons/gi";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

const NewVerifyWallet = () => {
  const navigate = useNavigate();
  const location = useLocation()

  const [isCheckWallet, setIsCheckWallet] = useState(true);
  const [isCheckTransaction, setIsCheckTransaction] = useState(false);
  const [isInvestigation, setIsInvestigation] = useState(false);



  useEffect(()=>{
    if(location?.pathname === "/verify-wallet/check-transaction"){
      setIsCheckWallet(false)
      setIsCheckTransaction(true)
      setIsInvestigation(false)
    }else if(location?.pathname === "/verify-wallet/investigation"){
      setIsCheckWallet(false)
      setIsCheckTransaction(false)
      setIsInvestigation(true)
    }else{
      setIsCheckWallet(true)
      setIsCheckTransaction(false)
      setIsInvestigation(false)
    }
  }, [location])

  

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-column vw-overview-wrapper">
        <div className="vw-wrapper flex-grow-1 p-4">
          <div className="row vw-title-btn-container">
            {/* <!-- Title --> */}
            <div className="col-lg-4 col-md-12 col-sm-12 mt-2">
              <div className="d-flex vw-title-icon-contnr">
                <GiWallet style={{ fontSize: "18px" }} />
                <h5 className="pl-2 mt-2 vw-title">Verify a Wallet</h5>
              </div>
            </div>
            
            {/* col-md-10 */}
            <div className="col-lg-3 col-sm-12 mt-2">
              <button
                onClick={() => navigate("/")}
                className="v-btn v-btn-info form-control font-weight-bold w-100"
                style={{height: "38px"}}
              >
                <ArrowLeftOutlined /> Back to Product
              </button>
            </div>
          </div>

          <div className="container">
            <div className="my-dashboard-tabs-section">
              <div className="my-dashboard-top-tabs flex justify-between">
                <div
                  className={
                    isCheckWallet
                      ? "my-dashboard-tab-active"
                      : "my-dashboard-tab-inactive"
                  }
                  onClick={() => {
                    setIsCheckWallet(true);
                    setIsCheckTransaction(false);
                    setIsInvestigation(false);
                    navigate("/verify-wallet/check-wallet")
                  }}
                >
                  <p>Check Wallet</p>
                </div>

                <div
                  className={
                    isCheckTransaction
                      ? "my-dashboard-tab-active"
                      : "my-dashboard-tab-inactive"
                  }
                  onClick={() => {
                    setIsCheckWallet(false);
                    setIsCheckTransaction(true);
                    setIsInvestigation(false);
                    navigate("/verify-wallet/check-transaction")
                  }}
                >
                  <p>Check Transaction</p>
                </div>

                <div
                  className={
                    isInvestigation
                      ? "my-dashboard-tab-active"
                      : "my-dashboard-tab-inactive"
                  }
                  onClick={() => {
                    setIsCheckWallet(false);
                    setIsCheckTransaction(false);
                    setIsInvestigation(true);
                    navigate("/verify-wallet/investigation")
                  }}
                >
                  <p>Investigation</p>
                </div>
              </div>

              <Outlet />

              {/* { isCheckWallet && <ChainButtons />}
              { isCheckTransaction && <TransactionButtons />}
              { isInvestigation && <InvestigationButtons />} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewVerifyWallet;
