import axios from "axios";
import { baseUrl, localBaseUrl } from "../constants/environment.constants";


export const TriggerPayments = async(payload) => {

    try{

        // const resp = await axios.post("http://localhost:5000/fintracking/api/v1/pay", payload);
        const resp = await axios.post(`${baseUrl}pay`, payload);
        // const resp = await axios.post("https://api.fintracking.ca/fintracking/api/v1/pay", payload);
            console.log("Checking paypal resp-->", resp.data);

            const  redirectUrl  = resp.data.redirectUrl;//"https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=EC-2SM72374RJ147740L";
            if (redirectUrl) {
                // Check if redirectUrl is an external URL
                if (redirectUrl.startsWith('http')) {
                    // Redirect to external URL using window.location.href
                    window.location.href = redirectUrl;
                } else {
                    // Navigate to internal URL using the navigate function
                    // navigate(redirectUrl, { replace: true });
                }
            }
    }catch(err){
        console.log("Error while Paypal...!", err.message);
    }
}