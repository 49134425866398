

export const selectedAssets = {
    BTC : "Bitcoin",
    ETH : "Ethereum",
    BSC : "BNB Chain",
    LTC : "Litecoin",
    BCH : "Bitcoin Cash",
    XRP : "Ripple",
    TetherOMNI : "TetherOMNI",
    ETC : "Ethereum Classic",
    BSV : "Bitcoin SV",
    DOGE : "Dogecoin",
    TRX : "TRON",
    MATIC : "Polygon",
    ADA : "Cardano",
    ZEC : "Zcash",
    SOL : "Solana",
    ALGO : "Algorand",
    ARB : "Arbitrum",
    DOT : "Polkadot",
    AVAX : "Avalanche",
    XLM : "Stellar",
    TON : "TON",
    NEAR : "Near",
    XTZ : "Tezos",
}