
export const captitalizeEveryField = (originalText) => {
    if (originalText !== undefined) {
      const words = originalText.split(" ");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      const capitalizedText = capitalizedWords.join(" ");
      return capitalizedText;
    } else {
      return "";
    }
  };