

export const coinsPrecisionRates = (coin) => {
  let precisionRate = 0;

  if (coin === "ETC") {
    precisionRate = 9;
  } else if (coin === "ETH") {
    precisionRate = 9;
  } else if (coin === "BTC") {
    precisionRate = 8;
  } else if (coin === "TetherOMNI") {
    precisionRate = 8;
  } else if (coin === "LTC") {
    precisionRate = 8;
  } else if (coin === "BCH") {
    precisionRate = 8;
  } else if (coin === "BSV") {
    precisionRate = 8;
  } else if (coin === "XRP") {
    precisionRate = 6;
  } else if (coin === "TRX") {
    precisionRate = 6;
  } else if (coin === "BSC") {
    precisionRate = 9;
  } else if (coin === "MATIC") {
    precisionRate = 18;
  } else if (coin === "DOGE") {
    precisionRate = 8;
  } else if (coin === "ZEC") {
    precisionRate = 8;
  } else if (coin === "ADA") {
    precisionRate = 6;
  } else if (coin === "SOL") {
    precisionRate = 9;
  } else if (coin === "ALGO") {
    precisionRate = 6;
  } else if (coin === "DOT") {
    precisionRate = 10;
  } else if (coin === "AVAX") {
    precisionRate = 18;
  } else if (coin === "ARB") {
    precisionRate = 18;
  } else if (coin === "XLM") {
    precisionRate = 7;
  } else if (coin === "TON") {
    precisionRate = 9;
  } else if (coin === "NEAR") {
    precisionRate = 24;
  } else {
    precisionRate = 6;
  }

  return precisionRate;
};
