import React, { useEffect, useState } from "react";
import "./chainBtn.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Select } from "antd";
import { SlPaypal } from "react-icons/sl";
import { TriggerPayments } from "../../helpers/paypal.helper";
import { useFormik } from "formik";
import axios from "axios";
import Loader from "../Loader/Loader";
import {
  AMLBotAccessId,
  AMLBotAccessKey,
  baseApiUrl,
  baseUrl,
} from "../../constants/environment.constants";

const InvestigationButtons = () => {
  // const [hash, setHash] = useState("")
  const [asset, setAsset] = useState("");
  const [isLimitted, setIsLimitted] = useState(false);
  const [isPayPalEnabled, setIsPayPalEnabled] = useState(false);
  const [payAmount, setPayAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [investigationTokensData, setInvestigationTokensData] = useState([]); // useState to set investigation tokens
  const [tokenData, setTokenData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const { Option } = Select;

  // console.log("PAGE ===>", page)
  // console.log("tokenData ===>", tokenData)
  // console.log("searchQuery VALUE===>", searchQuery)

  const navigate = useNavigate();

  // Getting Resubmit handler data as query params
  const location = useLocation();
  const jsonDataString = location?.state?.jsonData;
  const jsonData = JSON.parse(jsonDataString || "{}");

  // console.log("jsonData", jsonData)

  const getDynamicPrices = async () => {
    try {
      setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      /*Get Dynamic Full KYC Price */
      const getInvestigationPrice = await axios.get(
        baseUrl + "prices?serviceType=Investigation",
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      if (getInvestigationPrice?.data && getInvestigationPrice?.data?.price)
        setPayAmount(getInvestigationPrice?.data?.price);

      setIsLoading(false);
    } catch (err) {
      console.log(
        "Error while getting dynamic Prices from investigation check -->",
        err.message
      );
      setIsLoading(false);
    }
  };

  /* useEffect to get Dynamic Prices*/
  useEffect(() => {
    getDynamicPrices();
  }, []);

  const checkWalletForm = useFormik({
    initialValues: {
      hash: "",
      asset: "",
    },
    onSubmit: (values) => {},
    validate: (values) => {
      let errors = {};

      if (!values.hash) {
        errors.hash = "Field is required";
      }

      return errors;
    },
  });

  const { values } = checkWalletForm;
  // console.log("Values-->", values.hash)

  useEffect(() => {
    if (values?.hash.trim() !== "" && asset !== "") {
      setIsPayPalEnabled(true);
    } else {
      setIsPayPalEnabled(false);
    }
  }, [values?.hash, asset]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      const tokenPayload = {
        hash: values?.hash?.trim(),
        accessKey: AMLBotAccessKey,
        accessId: AMLBotAccessId,
      };

      const generateToken = await axios.post(
        baseApiUrl + "generate-token",
        tokenPayload,
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      const payload = {
        accessId: AMLBotAccessId,
        hash: values?.hash,
        asset: asset,
        token: generateToken?.data?.token,
        serviceType: "Investigation",
        amountPaid: payAmount,
        expanded: 1,
        tokenData: tokenData,
        userToken: userToken,
      };

      TriggerPayments(payload);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (err) {
      console.log("Error while sending Investigation Check-->", err.message);
      setIsLoading(false);
    }
  };

  // Resubmit handler
  const handleReSubmit = async (eData) => {
    try {
      setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      const tokenPayload = {
        hash: values?.hash?.trim(),
        accessKey: AMLBotAccessKey,
        accessId: AMLBotAccessId,
      };

      const generateToken = await axios.post(
        baseApiUrl + "generate-token",
        tokenPayload,
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      const payload = {
        id: eData?._id,
        accessId: AMLBotAccessId,
        hash: values?.hash,
        asset: asset,
        token: generateToken?.data?.token,
        expanded: 1,
        tokenData: tokenData,
        serviceType: "Investigation",
      };

      // const updateInestigationCheck = await axios.put(localBaseUrl + "resubmit-investigation", payload, {
      const updateInestigationCheck = await axios.put(
        baseUrl + "resubmit-investigation",
        payload,
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      if (updateInestigationCheck?.status === 200) {
        navigate("/transactions");
      }

      setIsLoading(false);
    } catch (err) {
      console.log("Error while Investigation Check resubmit ", err.message);
      setIsLoading(false);
      navigate("/transactions");
    }
  };

  /** Getting Investigation Tokens */
  const getInvestigationTokens = async () => {
    try {
      setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      const getInvestigationTokens = await axios.get(
        baseUrl + `investigationTokens?asset=${asset}&page=${page}`,
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      if (
        getInvestigationTokens?.data &&
        getInvestigationTokens?.data?.success === true
      ) {
        if (page === 1) {
          // Reset investigationTokensData if fetching the first page
          setInvestigationTokensData(getInvestigationTokens?.data?.result);
        } else {
          // Append to investigationTokensData if not the first page
          setInvestigationTokensData((prevData) => [
            ...prevData,
            ...getInvestigationTokens?.data?.result,
          ]);
        }
      }

      setIsLoading(false);
    } catch (err) {
      console.log("Error while getting investigation tokens -->", err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      (values?.hash.trim() !== "" && asset === "BSC") ||
      asset === "ETH" ||
      asset === "MATIC" ||
      asset === "TRX"
    ) {
      getInvestigationTokens();
    }
  }, [page]);

  /** Code to re-initialize the investigationTokensData if asset changed */
  const resetTokensData = () => {
    setInvestigationTokensData([]);
    // setPage(1);
    setTokenData("");
  };

  useEffect(() => {
    setPage(1);
    if (
      values?.hash.trim() !== "" &&
      (asset === "BSC" ||
        asset === "ETH" ||
        asset === "MATIC" ||
        asset === "TRX")
    ) {
      resetTokensData();
      getInvestigationTokens();
    }
  }, [asset]);

  // Search Handler
  const searchDataHandler = async () => {
    // console.log('THIS IS CALLEDDDD')
    try {
      // setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      const getInvestigationTokens = await axios.get(
        baseUrl +
          `investigationTokens?asset=${asset}&page=1&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      if (
        getInvestigationTokens?.data &&
        getInvestigationTokens?.data?.success === true
      ) {
        setInvestigationTokensData((prevData) => [
          ...getInvestigationTokens?.data?.result,
          ...prevData,
        ]);
        // if (page === 1) {
        //     setInvestigationTokensData(getInvestigationTokens?.data?.result);
        // } else {
        //     setInvestigationTokensData((prevData) => [...prevData, ...getInvestigationTokens?.data?.result]);
        // }
      }

      // setIsLoading(false);
    } catch (err) {
      console.log("Error while Search investigation tokens -->", err.message);
      //   setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery?.length > 0 || searchQuery !== "") {
      // setPage(1)
      const getData = setTimeout(() => {
        searchDataHandler();
      }, 1000);

      return () => clearTimeout(getData);
    }
  }, [searchQuery]);

  /*Remove Duplicates from drop down*/
  const uniqueTokens = investigationTokensData.filter(
    (token, index, self) =>
      index === self.findIndex((t) => t.issuer === token.issuer)
  );

  return (
    <div>
      <Loader isLoading={isLoading} />
      <div className="top-label-input-container">
        <span className="chain-subtitle" style={{ color: "#616161" }}>
          Investigation Address
        </span>
        <input
          type="text"
          style={{
            border: checkWalletForm.errors.hash ? "1px solid #ed274b" : "",
          }}
          placeholder="Enter address you want to investigate"
          className="form-control"
          id="wallet-custom-input"
          name="hash"
          value={checkWalletForm.values.hash}
          //  onChange={(e)=> setHash(e.target.value)}
          onChange={checkWalletForm.handleChange}
          onBlur={checkWalletForm.handleBlur}
        />
        {checkWalletForm.touched.hash && checkWalletForm.errors.hash ? (
          <div className="wallet-error">{checkWalletForm.errors.hash}</div>
        ) : null}
      </div>

      <span className="chain-subtitle" style={{ color: "#616161" }}>
        Specify blockchain
      </span>
      <div className="chain-selector-btns-container">
        <button
          className={
            asset === "BTC" ? "chain-selector-btn-active" : "chain-selector-btn"
          }
          onClick={() => {
            setAsset("BTC");
            setIsLimitted(false);
          }}
        >
          <img className="chain-btn-icon" src="/coins/bitcoin.svg" alt="" />
          <span className="chain-btn-txt">Bitcoin</span>
        </button>

        <button
          className={
            asset === "ETH" ? "chain-selector-btn-active" : "chain-selector-btn"
          }
          onClick={() => {
            setAsset("ETH");
            setIsLimitted(false);
          }}
        >
          <img className="chain-btn-icon" src="/coins/etherium.svg" alt="" />
          <span className="chain-btn-txt">Ethereum</span>
        </button>

        <button
          className={
            asset === "BSC" ? "chain-selector-btn-active" : "chain-selector-btn"
          }
          onClick={() => {
            setAsset("BSC");
            setIsLimitted(false);
          }}
        >
          <img className="chain-btn-icon" src="/coins/pnb-chain.svg" alt="" />
          <span className="chain-btn-txt">BNB Chain</span>
        </button>

        <button
          className={
            asset === "LTC" ? "chain-selector-btn-active" : "chain-selector-btn"
          }
          onClick={() => {
            setAsset("LTC");
            setIsLimitted(false);
          }}
        >
          <img className="chain-btn-icon" src="/coins/lite-coin.svg" alt="" />
          <span className="chain-btn-txt">Litecoin</span>
        </button>

        <button
          className={
            asset === "BCH" ? "chain-selector-btn-active" : "chain-selector-btn"
          }
          onClick={() => {
            setAsset("BCH");
            setIsLimitted(false);
          }}
        >
          <img
            className="chain-btn-icon"
            src="/coins/bitcoin-cash.svg"
            alt=""
          />
          <span className="chain-btn-txt">Bitcoin Cash</span>
        </button>

        <button
          className={
            asset === "TRX" ? "chain-selector-btn-active" : "chain-selector-btn"
          }
          onClick={() => {
            setAsset("TRX");
            setIsLimitted(false);
          }}
        >
          <img className="chain-btn-icon" src="/coins/tron.svg" alt="" />
          <span className="chain-btn-txt">TRON</span>
        </button>

        <button
          className={
            asset === "MATIC"
              ? "chain-selector-btn-active"
              : "chain-selector-btn"
          }
          onClick={() => {
            setAsset("MATIC");
            setIsLimitted(false);
          }}
        >
          <img className="chain-btn-icon" src="/coins/polygon.svg" alt="" />
          <span className="chain-btn-txt">Polygon</span>
        </button>
      </div>

      {isLimitted === true && (
        <div className="alert-box">
          <img src="/coins/up-cion.png" alt="" className="alert-box-icon" />
          <div className="alert-box-text-container">
            <span className="alert-box-title">
              Please note that analysis of this blockchain is limited.
            </span>
            <span className="alert-box-text">
              Clusterization and risk score propagation are not available for
              this check result. Risk score may be provided for a counterparty
              only if the counterparty is attributed to an entity.
            </span>
          </div>
        </div>
      )}

      {asset === "DOT" && (
        <div className="polkatod-message">
          To check Polkadot TX, you need to enter or paste the extrinsic id of
          transaction
        </div>
      )}

      {values.hash.trim() !== "" &&
      (asset === "BSC" ||
        asset === "ETH" ||
        asset === "MATIC" ||
        asset === "TRX") ? (
        <div className="dropdown-input-wrapper" style={{ margin: "3% 0" }}>
          <Select
            showSearch
            // placeholder={asset === "BSC" ? "BSC (Main network coin)" :  asset === "ETH" ? "ETH (Main network coin)" : asset === "MATIC" ? "MATIC (Main network coin)" : asset === "TRX" ? "TRX (Main network coin)" : "Select"}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
            onChange={(value) => {
              setTokenData(value);
              setSearchQuery("");
            }}
            value={
              tokenData === "" ? `${asset} (Main network coin)` : tokenData
            }
            onSearch={(value) => {
              if (value.trim() !== "") {
                setSearchQuery(value.trim());
              }
            }}
            onPopupScroll={(e) => {
              const { scrollTop, clientHeight, scrollHeight } = e.target;
              if (scrollHeight - scrollTop === clientHeight) {
                setPage((prevPage) => prevPage + 1); // Increase the page number
              }
            }}
          >
            {uniqueTokens.map((token) => (
              <Option key={token._id} value={token.issuer}>
                {`${token.tokenName} (${token.issuer})`}
              </Option>
            ))}
          </Select>
        </div>
      ) : null}

      {jsonData?.errorIn !== "Investigation" && (
        <div className="paypal-btn-container">
          <Button
            type="primary"
            id={isPayPalEnabled ? "custom-paypal-btn" : "disabled-button"}
            icon={<SlPaypal id="paypal-btn-icon" />}
            onClick={() => {
              console.log("Pay with PayPal");
              // TriggerPayments()
              handleSubmit();
            }}
          >
            Pay with PayPal - ${payAmount ? payAmount?.toFixed(2) : 0}
          </Button>
        </div>
      )}

      {jsonData?.errorIn === "Investigation" && (
        <div className="paypal-btn-container">
          <Button
            type="primary"
            id={isPayPalEnabled ? "custom-paypal-btn" : "disabled-button"}
            // icon={<SlPaypal id='paypal-btn-icon' />}
            onClick={() => {
              console.log("Resubmit W/O PayPal from Inxn");
              handleReSubmit(jsonData);
            }}
          >
            Resubmit
          </Button>
        </div>
      )}
    </div>
  );
};

export default InvestigationButtons;
