import { jsPDF } from "jspdf";
import moment from "moment";

export const generatePaymentReceipt = (data, serviceType) =>{


    const pdf = new jsPDF();
    

    const logoPath = "/fin-logo.png"
    pdf.addImage(logoPath, 'PNG', 149, 5, 55, 15);

    
    pdf.setFont('arial', 'normal');
    pdf.setDrawColor(192, 192, 192);
    pdf.rect(4, 23, 200, 230)


    pdf.setFontSize(16);
    pdf.setTextColor(0,0,0);
    pdf.text('Transaction details', 8, 30);

    pdf.setFontSize(10);
    pdf.setTextColor(0,0,0);
    pdf.text(`Transaction ID : ${data?.id}`, 200, 30, { align: 'right' });
    pdf.text(moment(data?.create_time).format('MMMM DD, YYYY [at] hh:mm:ss A'), 200, 35, { align: 'right' });

    pdf.line(8, 39, 200, 39);
    
    pdf.setFontSize(10);
    pdf.setTextColor(0,0,0);

    pdf.text('Paid with :', 8, 45);
    pdf.text("PayPal", 25, 45);
    pdf.text('Payment status :', 8, 52);
    pdf.text("Completed", 32, 52);

    pdf.text('Gross amount', 181, 45);
    pdf.setFontSize(17);
    pdf.setTextColor(0,0,0);
    pdf.text(`$${data?.transactions[0]?.amount?.total} ${data?.transactions[0]?.amount?.currency}`, 200, 53, {align : "right"})

    pdf.line(8, 57, 200, 57);

    pdf.setFontSize(13);
    pdf.setTextColor(0,0,0);
    pdf.text('Ship to :', 8, 64);

    pdf.setFontSize(10);
    pdf.setTextColor(0,0,0);
    pdf.text(`${data?.transactions[0]?.item_list?.shipping_address?.recipient_name}`, 8, 72);
    pdf.text(`${data?.transactions[0]?.item_list?.shipping_address?.line1},`, 8, 77);
    pdf.text(`${data?.transactions[0]?.item_list?.shipping_address?.city} ${data?.transactions[0]?.item_list?.shipping_address?.state} ${data?.transactions[0]?.item_list?.shipping_address?.postal_code},`, 8, 82);
    pdf.text(`${data?.transactions[0]?.item_list?.shipping_address?.country_code === "CA" && "CANADA"}`, 8, 87);
    pdf.setTextColor(12, 155, 129); // CHANGE COLOR
    pdf.text('Confirmed', 8, 92);

    pdf.setFillColor(192, 192, 192); // Gray color for background
    pdf.rect(8, 105, 192, 10, 'F'); // Draw the filled rectangle


    pdf.setFont('helvetica', 'normal');
    pdf.setTextColor(0,0,0);
    pdf.text('Purchase details', 10, 111);
    pdf.text('Quantity', 120, 111);
    pdf.text('Price', 159, 111);
    pdf.text('Amount', 185, 111);


    pdf.setFont('arial', 'normal'); // Reset font to normal for subsequent text
    pdf.setTextColor(0,0,0);
    pdf.text(`${serviceType}`, 10, 120);

    pdf.text('1', 132, 120);


    pdf.text(`$${data?.transactions[0]?.amount?.total} ${data?.transactions[0]?.amount?.currency}`, 167, 120, { align: 'right' })

    pdf.text(`$${data?.transactions[0]?.amount?.total} ${data?.transactions[0]?.amount?.currency}`, 197.5, 120, { align: 'right' })

    pdf.line(8, 125, 200, 125);

    pdf.setFont('helvetica', 'bold');
    pdf.setTextColor(0,0,0);
    pdf.text('Purchase total', 145, 130);
    pdf.text(`$${data?.transactions[0]?.amount?.total} ${data?.transactions[0]?.amount?.currency}`, 197.5, 130, { align: 'right' })
    pdf.setFont('arial', 'normal'); // Reset font to normal for subsequent text

    pdf.setFillColor(192, 192, 192); // Gray color for background
    pdf.rect(8, 140, 192, 10, 'F'); // Draw the filled rectangle
    pdf.setFont('helvetica', 'normal');
    pdf.setTextColor(0,0,0);
    pdf.text('Payment details', 10, 146);
    pdf.setFont('arial', 'normal'); // Reset font to normal for subsequent text


    pdf.setFontSize(10);
    pdf.setTextColor(0,0,0);
    pdf.text('Purchase total', 10, 154);
    pdf.text(`$${data?.transactions[0]?.amount?.details?.subtotal} ${data?.transactions[0]?.amount?.currency}`, 98, 154, { align: 'right' });

    pdf.text('Sales tax', 10, 159);
    pdf.text('$0.00 CAD', 98, 159, { align: 'right' });

    pdf.text('Shipping amount', 10, 164);
    pdf.text(`$${data?.transactions[0]?.amount?.details?.shipping} ${data?.transactions[0]?.amount?.currency}`, 98, 164, { align: 'right' });

    pdf.text('Handling amount', 10, 169);
    pdf.text(`$${data?.transactions[0]?.amount?.details?.handling_fee} ${data?.transactions[0]?.amount?.currency}`, 98, 169, { align: 'right' });

    pdf.text('Insurance amount', 10, 174);
    pdf.text(`$${data?.transactions[0]?.amount?.details?.insurance} ${data?.transactions[0]?.amount?.currency}`, 98, 174, { align: 'right' });
    pdf.line(8, 176, 100, 176);

    pdf.text('Gross amount', 10, 180);
    pdf.text(`$${data?.transactions[0]?.amount?.details?.subtotal} ${data?.transactions[0]?.amount?.currency}`, 98, 180, { align: 'right' });
    pdf.text('PayPal transaction fee', 10, 185);
    pdf.text(`$${data?.transactions[0]?.related_resources[0]?.sale?.transaction_fee?.value} ${data?.transactions[0]?.related_resources[0]?.sale?.transaction_fee?.currency}`, 98, 185, { align: 'right' });
    pdf.line(8, 187, 100, 187);

    pdf.setFont('helvetica', 'bold');
    pdf.text('Net amount', 10, 191);
    pdf.text(`$${data?.transactions[0]?.related_resources[0]?.sale?.amount?.total} ${data?.transactions[0]?.related_resources[0]?.sale?.amount?.currency}`, 98, 191, { align: 'right' });

    pdf.line(8, 201, 200, 201);


    pdf.setFont('arial', 'normal'); // Reset font to normal for subsequent text
    pdf.setFontSize(11);
    pdf.setTextColor(0,0,0);
    pdf.text('Paid by', 8, 206);
    pdf.text(`${data?.payer?.payer_info?.first_name} ${data?.payer?.payer_info?.last_name}`, 78, 206, { align: 'left' });
    pdf.text(`${data?.payer?.payer_info?.email}`, 78, 211, { align: 'left' });

    pdf.text('Payment sent to', 8, 221);
    pdf.text(`${data?.transactions[0]?.payee?.email}`, 78, 221, { align: 'left' });
    



    // pdf.save("aad.pdf");
    pdf.save(`Transaction_Receipt_${moment(data?.create_time).format('MMMM DD, YYYY [at] hh:mm:ss A')}.pdf`);
    
}