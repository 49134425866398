import React, { useState } from "react";
import "./forgotPassword.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { HandleSuccess } from "../../helpers/toast.helper";
import { ToastContainer } from "react-toastify";
import { baseApiUrl } from "../../constants/environment.constants";
import Loader from "../../components/Loader/Loader";

const ForgotPassword = () => {
  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(false)

  // useState for invalid credentials error
  const [invalidCredErr, setInvalidCredErr] = useState("");

  const postFormDataHandler = async (payload) => {
    try {
      setIsLoading(true);
      const resp = await axios.post(
        baseApiUrl + "forgot-password",
        payload
      );

      setTimeout(()=>{
        setIsLoading(false);
      }, 1000);

      if (resp?.data?.success === true) {
        HandleSuccess("OTP has been sent successfully.");
        // console.log("EMAIL-->", payload.email)
        localStorage.setItem("clientEmail", payload?.email);
        setTimeout(() => {
          navigate("/verify-otp");
        }, 5000);
      }
    } catch (err) {
      console.log("Error while login at front-end side-->", err);
      
      if (err?.response?.data?.success === false) {
        setInvalidCredErr(err?.response?.data?.message);
        setIsLoading(false);
      } else {
        setInvalidCredErr("");
      }
    }
  };

  const loginForm = useFormik({
    initialValues: {
      email: "",
      //   password: "",
    },
    onSubmit: (values) => {
      let payload = {
        email: values?.email,
      };
      postFormDataHandler(payload);
    },
    validate: (values) => {
      let errors = {};

      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      return errors;
    },
  });

  return (
    <div className="bg-blue">
      <ToastContainer />
      <Loader isLoading={isLoading}/>
      <div className="container">
        <div className="row p-3 overlay">
          <div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 my-auto justify-content-center text-center">
            <div className="fp-sectionStartForm px-5 py-4">
              <img
                src="/cobalt-icon.svg"
                alt="fintrack-logo"
                className="form-header img-fluid"
              />
              <p className="fp-title-text py-1">Forgot your password?</p>

              {invalidCredErr && invalidCredErr.length ? (
                <div className="signup-error">{invalidCredErr}</div>
              ) : null}

              <form onSubmit={loginForm.handleSubmit}>
                
                <div className="fp-form-label-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    value={loginForm.values.email}
                    onChange={loginForm.handleChange}
                    onBlur={loginForm.handleBlur}
                  />
                  <label>Email Address</label>
                </div>
                {loginForm.errors.email ? (
                  <div className="login-error">{loginForm.errors.email}</div>
                ) : null}

                <p className="fp-mid-text">
                  An OTP will be sent to your email to confirm your identity.
                </p>

                <div className="fp-form-label-group mt-4">
                  <button
                    type="submit"
                    className="btn btn-info font-weight-bold text-uppercase w-50"
                  >
                    submit
                  </button>
                </div>
              </form>

              <p className="fp-bottom-text text-decoration mt-4">
                <span onClick={()=> navigate("/login")}>Need to go the previous page</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
