import React, { useEffect, useState } from "react";
import "./payment.css"
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";


const PaymentSuccess = () => {
    const [timer, setTimer] = useState(10);
    const navigate = useNavigate()

    useEffect(() => {

      // Extract the paymentData query parameter from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const paymentDataJson = urlParams.get('paymentData');

        if(paymentDataJson){
          const paymentData = JSON.parse(paymentDataJson);

          console.log("paymentData--->", paymentData);
        }

        const countdown = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 1000);

        return () => clearInterval(countdown);
    }, []);


    useEffect(() => {
        if (timer === 0) {
            navigate('/transactions');
        }
    }, [timer, navigate]);

  return (
    <div className="">
      <Navbar />
      <div className="success-message-container">
        <div className="content-container">
          {/* <img src="/cobalt-icon.svg" alt="fintrack-logo" className="wallet-img-fluid"/> */}
            <span className="success-title">Thank You!</span>
                <div className="mt-5" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                  <div className="success-icon-wrapper mb-4">
                    <div className="success-icon">&#10003;</div>
                  </div>
                  <span className="success-subtitle">Payment Successful.</span>
                  <span className="success-text-counter">You will be automatically redirected to the Dashboard in {timer} seconds.</span>
                </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
