import React, { useEffect, useState } from "react";
import "./verifyBusiness.css";
import Navbar from "../../components/navbar/Navbar";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { FaBriefcase } from "react-icons/fa";
import { Button } from "antd";
import { SlPaypal } from "react-icons/sl";
import { useFormik } from "formik";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { TriggerPayments } from "../../helpers/paypal.helper";
import { captitalizeEveryField } from "../../helpers/capitalize.helper";
import { baseUrl } from "../../constants/environment.constants";

const VerifyBusiness = () => {
  const navigate = useNavigate();

  // Getting Resubmit handler data as query params
  const location = useLocation();
  const jsonDataString = location?.state?.jsonData;
  const jsonData = JSON.parse(jsonDataString || "{}");

  // console.log("jsonDataAAA", jsonData)

  const [kybPrice, setKybPrice] = useState(0);
  const [serviceType, setServiceType] = useState("");
  const [payAmount, setPayAmount] = useState(0);
  const [isPayPalEnabled, setIsPayPalEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getDynamicPrices = async () => {
    try {
      setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      /*Get Dynamic Full KYC Price */
      const getKybPrice = await axios.get(baseUrl + "prices?serviceType=KYB", {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      if (getKybPrice?.data && getKybPrice?.data?.price)
        setKybPrice(getKybPrice?.data?.price);

      setIsLoading(false);
    } catch (err) {
      console.log(
        "Error while getting dynamic Prices from verify Buisiness -->",
        err.message
      );
      setIsLoading(false);
    }
  };

  /* useEffect to get Dynamic Prices*/
  useEffect(() => {
    getDynamicPrices();
  }, []);

  const kybForm = useFormik({
    initialValues: {
      clientName: "",
      clientEmail: "",
    },
    onSubmit: {},
    validate: (values) => {
      let errors = {};

      /*Validations for clientName */
      if (!values.clientName) {
        errors.clientName = "Client full name is required";
      } else if (/^\s+|\s+$/.test(values.clientName)) {
        errors.clientName = "Full name should not contain extra spaces.";
      } else if (!/^[a-zA-Z\s]+$/.test(values.clientName)) {
        errors.clientName = "Only letters are allowed for the Full Name";
      }

      /*Validations for clientEmail */
      if (!values.clientEmail) {
        errors.clientEmail = "Client email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.clientEmail)
      ) {
        errors.clientEmail = "Invalid email address";
      } else if (/^\s+|\s+$/.test(values.clientEmail)) {
        errors.clientEmail = "Email should not contain spaces.";
      }

      return errors;
    },
  });

  const { values } = kybForm;

  useEffect(() => {
    if (
      values?.clientName.trim() !== "" &&
      values?.clientEmail.trim() !== "" &&
      serviceType !== "" &&
      !kybForm.errors.clientName &&
      !kybForm.errors.clientEmail
    ) {
      setIsPayPalEnabled(true);
    } else {
      setIsPayPalEnabled(false);
    }
  }, [
    values?.clientName,
    values?.clientEmail,
    serviceType,
    kybForm.errors.clientName,
    kybForm.errors.clientEmail,
  ]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const userToken = sessionStorage.getItem("userToken");

      const payload = {
        clientName: values?.clientName?.trim(),
        clientEmail: values?.clientEmail?.trim(),
        serviceType: serviceType,
        amountPaid: payAmount,
        userToken: userToken,
      };

      TriggerPayments(payload);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (err) {
      console.log("Error while KYB-->", err.message);
      setIsLoading(false);
    }
  };


  // Resubmit Handler 
  const handleResubmit = async (eData) =>{
    // console.log("eData-->", eData)
    try{
      setIsLoading(true);
      const userToken = sessionStorage.getItem("userToken");

      const kycPayload = {
        clientName : kybForm?.values?.clientName,
        clientEmail : kybForm?.values?.clientEmail,
        id : eData?._id
    };

      const resp = await axios.put(baseUrl + `kyc?serviceType=${eData?.errorIn}`, kycPayload, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      if(resp?.data?.success === true){
        navigate("/transactions")
      }

      setIsLoading(false);
    }catch(err){
      console.log("Error while resubmit VB ", err.message)
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Loader isLoading={isLoading} />
      <Navbar />
      <div className="d-flex flex-column overview-wrapper">
        <div className="wrapper flex-grow-1 p-4">
          <div className="row title-btn-container">
            {/* <!-- Title --> */}
            <div className="col-lg-4 col-md-12 col-sm-12 mt-2">
              <div className="d-flex title-icon-contnr">
                <FaBriefcase style={{ fontSize: "18px" }} />
                <h5 className="pl-2 mt-2 title">Verify Business</h5>
              </div>
            </div>

            {/* col-md-10 */}
            <div className="col-lg-3 col-sm-12 mt-2">
              <button
                onClick={() => navigate("/")}
                className="v-btn v-btn-info form-control font-weight-bold w-100"
                style={{height: "38px"}}
              >
                <ArrowLeftOutlined /> Back to Product
              </button>
            </div>
          </div>

          <div className="container">
            <div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 my-auto justify-content-center text-center">
              <div className="v-sectionStartForm px-5 py-4">
                <form>
                  <div
                    className="form-label-group"
                    style={{ marginBottom: "2px" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="clientName"
                      value={captitalizeEveryField(kybForm.values.clientName)}
                      onChange={kybForm.handleChange}
                      onBlur={kybForm.handleBlur}
                    />
                    <label>Enter full name of your client</label>
                  </div>
                  {kybForm.touched.clientName && kybForm.errors.clientName ? (
                    <div
                      className="signup-error"
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      {kybForm.errors.clientName}
                    </div>
                  ) : null}

                  <div
                    className="form-label-group"
                    style={{ marginBottom: "2px", marginTop: "1.5rem" }}
                  >
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                      name="clientEmail"
                      value={kybForm.values.clientEmail}
                      onChange={kybForm.handleChange}
                      onBlur={kybForm.handleBlur}
                    />
                    <label>Enter email of your client</label>
                  </div>
                  {kybForm.touched.clientEmail && kybForm.errors.clientEmail ? (
                    <div
                      className="signup-error"
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      {kybForm.errors.clientEmail}
                    </div>
                  ) : null}

                  <div className="mid-container justify-content-left">
                    <span className="vb-mid-first-span">
                      Your client will be sent a link with instructions
                    </span>
                    <p style={{ marginTop: "8%" }}>Select Option :</p>

                    <div className="radio-container">
                      <input
                        type="radio"
                        name="verificationOption"
                        value="kyb"
                        id="kyb"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setPayAmount(kybPrice);
                          setServiceType("kyb");
                        }}
                      />
                      <label htmlFor="kyb" className="radio-label">
                        KYB with liveliness check :{" "}
                        {/* <span className="vb-price">$215.00/-</span> */}
                        <span className="vb-price">
                          ${kybPrice?.toFixed(2)}/-
                        </span>
                      </label>
                    </div>
                  </div>
                </form>

                {!jsonData?.errorIn && (
                <div className="paypal-btn-container mt-5"
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  <Button
                    type="submit"
                    id={
                      isPayPalEnabled ? "custom-paypal-btn" : "disabled-button"
                    }
                    icon={<SlPaypal id="paypal-btn-icon" />}
                    onClick={() => {
                      console.log("Pay with PayPal");
                      handleSubmit();
                    }}
                  >
                    Pay with PayPal{" "}
                    {payAmount ? "- $" + payAmount?.toFixed(2) : ""}
                  </Button>
                </div>)}

                {jsonData?.errorIn === "kyb" && (
                <div className="paypal-btn-container mt-5"
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  <Button
                    type="submit"
                    id={
                      isPayPalEnabled ? "custom-paypal-btn" : "disabled-button"
                    }
                    // icon={<SlPaypal id="paypal-btn-icon" />}
                    onClick={() => {
                      console.log("Resubmit KYB W/O PayPal")
                      handleResubmit(jsonData)
                    }}
                  >
                    Resubmit
                  </Button>
                </div>)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyBusiness;
