import { jsPDF } from "jspdf";
import "jspdf-autotable";
// import { createCanvas } from 'canvas';
import html2canvas from "html2canvas";
import moment from "moment";
import { coinsPrecisionRates } from "../coinsPrecisionRates.helper";
import { pdfGraphTemplate } from "../pdfGraphTemplate.helper";


export const walletTxnCheckReport = async (data) =>{

    try{

    // console.log("DATA===>", data)
    // console.log("ASSET===>", data?.asset)
    // console.log("PRECISION===>",coinsPrecisionRates(data?.asset))

    const pdf = new jsPDF();

    // const fontData = "../../fonts/Inter-Regular.ttf";


    /**PDF Header */
    const logoPath = "/fin-logo.png";
    pdf.addImage(logoPath, "PNG", 14, 10, 40, 12);


    pdf.setFont("Inter", "normal");
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(10);
    pdf.text("15130981 Canada Inc", 15, 25);
    pdf.textWithLink("From: finance@fintracking.ca ", 15, 30, {
        url: "mailto:finance@fintracking.ca",
    });

    pdf.setFontSize(16);
    pdf.setFont("Inter", "bold");
    pdf.text("Risk Report", 193, 15, { align: "right" });
    pdf.setFont("Inter", "normal");

    pdf.setFontSize(10);
    pdf.text(`Document ID : ${data?.uid?.toUpperCase() || ""}`, 193, 20, { align: "right" });

    pdf.setFontSize(10);
    pdf.text(`From : ${moment(data?.report?.data?.timestamp || data?.updatedAt).format('DD.MM.YYYY HH:mm')}`, 193, 25, { align: "right" });
    //   pdf.text("Report Generation Block Height", 193, 30, { align: "right" });
    pdf.setTextColor(0, 0, 255); // text color to Blue
    pdf.textWithLink("www.fintracking.ca", 193, 30, {
        url: "http://www.fintracking.ca",
        align: "right",
    });

    pdf.setTextColor(0, 0, 0); // text color set back to black

    pdf.setDrawColor(192, 192, 192);
    pdf.line(15, 34, 193, 34);



    /** To Make cuntents dynamic on Y-axis */
    let currentY = 50; // Initial Y-axis position
    const gap = 10; // Gap between each content
    const pageHeight = pdf.internal.pageSize.height;
    let prevFillColor; // Store the previous fill color

    // function to convert color into rgb values
    function hexToRgb(hex) {
        // Remove the hash sign if present
        hex = hex.replace('#', '');
        // Convert hex to RGB
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    }

    // Update Y-axis position for the next content
    const updateY = (yOffset) => {
        if (currentY + yOffset + gap > pageHeight - 28) {
             // Store the current fill color
            prevFillColor = hexToRgb(pdf.getFillColor());
            pdf.addPage();
            pdf.setFillColor(prevFillColor[0], prevFillColor[1], prevFillColor[2]);
            currentY = 28; // Reset Y-axis position for the new page
        } else {
            currentY += yOffset + gap;
        }
        return currentY;
    };


    if(data?.serviceType === "Wallet Check") {
    pdf.setFontSize(11);
    pdf.setFont("helvetica", "normal");
    pdf.text(`${data?.asset} Address`, 15, updateY(0), { align: "left" });
    pdf.setFont("helvetica", "bold");
    pdf.text(data?.report?.data?.address, 55, currentY, { align: "left" });
    pdf.setFont("helvetica", "normal");
    }

    // In case of Txn check
    if(data?.serviceType === "Transaction Check") {
    pdf.setFontSize(11);
    pdf.setFont("helvetica", "normal");
    pdf.text(`${data?.asset} Tx`, 15, updateY(0), { align: "left" });
    pdf.setFont("helvetica", "bold");
    pdf.text(`${data?.report?.data?.tx}`, 55, currentY, { align: "left" });
    pdf.setFont("helvetica", "normal");
    }


    // In case of Txn check - will be Withdrawal  or Deposit
    if(data?.serviceType === "Transaction Check") {
    pdf.setFontSize(11);
    pdf.setFont("helvetica", "normal");
    let direction = data?.report?.data?.direction || "";
    let capitalizedDirection = direction.charAt(0).toUpperCase() + direction.slice(1);
    pdf.text(`${capitalizedDirection}`, 15, updateY(0), { align: "left" });
    pdf.setFont("helvetica", "bold");

    const originalValue = data?.report?.data?.amount || 0;
    const convertedValue = originalValue / Math.pow(10, data?.report?.data?.tokenDetails?.precision || coinsPrecisionRates(data?.asset));
    const fiatValue = data?.report?.data?.fiat;
    const formattedValue = (fiatValue / 100).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    let textToShow = `${convertedValue} ${data?.report?.data?.tokenDetails?.code || data?.report?.data?.asset}`;
    if (data?.report?.data?.fiat) {
        textToShow += ` (~$${formattedValue})`;
    }
    pdf.text(textToShow, 55, currentY, { align: "left" });
    pdf.setFont("helvetica", "normal");
    }


    // In case of Txn check To = wllet address
    if(data?.serviceType === "Transaction Check") {
    pdf.setFontSize(11);
    pdf.setFont("helvetica", "normal");
    pdf.text("To", 15, updateY(0), { align: "left" });
    pdf.setFont("helvetica", "bold");
    pdf.text(`${data?.report?.data?.address}`, 55, currentY, { align: "left" });
    pdf.setFont("helvetica", "normal");
    }


    if(data?.serviceType === "Wallet Check" && data?.report?.data?.addressDetailsData?.balance_approximate !== 0 && data?.report?.data?.addressDetailsData?.balance_approximate) {
    pdf.text("Balance", 15, updateY(0), { align: "left" });
    pdf.setFont("helvetica", "bold");
    pdf.text(`${data?.report?.data?.addressDetailsData?.balance_approximate}`, 55, currentY, { align: "left" });
    pdf.setFont("helvetica", "normal");
    }

    if(data?.serviceType === "Transaction Check" && data?.report?.data?.risky_volume > 0 && data?.report?.data?.risky_volume_fiat > 0) {
        pdf.text("Risky amount", 15, updateY(0), { align: "left" });
        pdf.setFont("helvetica", "bold");
        let riskyVolumeFiat = data?.report?.data?.risky_volume_fiat
        let convertedRiskyVolumeFiat = riskyVolumeFiat / Math.pow(10, 2);

        let riskyVolume = data?.report?.data?.risky_volume
        let convertedRiskyVolume = riskyVolume / Math.pow(10, data?.report?.data?.tokenDetails?.precision || coinsPrecisionRates(data?.asset));
        pdf.text(`${convertedRiskyVolumeFiat?.toFixed(2)} ${data?.report?.data?.fiat_code_effective?.toUpperCase() || ""} - ${convertedRiskyVolume?.toFixed(2)} ${data?.report?.data?.tokenDetails?.code || ""}`, 55, currentY, { align: "left" });
        pdf.setFont("helvetica", "normal");
    }


    pdf.text("Blacklist", 15, updateY(0), { align: "left" });
    pdf.setFont("helvetica", "bold");
    const rightIcon = "/pdf/right.png";
    pdf.addImage(rightIcon, "PNG", 55, currentY - 3, 4, 4);
    pdf.text("Free", 60, currentY + 0.3, { align: "left" });
    pdf.setFont("helvetica", "normal");



    /* 
        RISK GRAPH SECTION STARTS HERE...!
    */
    let rScore = data?.report?.data?.riskscore * 100;
    const riskPercentage = rScore.toFixed(0);


        // const iframe = document.createElement("iframe");
        // iframe.style.display = "none"; // Hide the iframe

        // iframe.style.position = "fixed"; // Set the iframe to a fixed position
        // iframe.style.top = "50%"; // Position the iframe vertically centered
        // iframe.style.left = "50%"; // Position the iframe horizontally centered
        // iframe.style.zIndex = "-9999";
        // iframe.style.transform = "translate(50%, 1%)"; 

        // iframe.width = "600"; // Set the desired width of the iframe
        // iframe.height = "600"; // Set the desired height of the iframe
        // document.body.appendChild(iframe);

        // const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        // iframeDocument.open();
        // // iframeDocument.write(graphHTML);
        // const graphHTMLL = await pdfGraphTemplate(riskPercentage);
        // iframeDocument.write(graphHTMLL);
        // iframeDocument.close();

        // const canvas = await html2canvas(iframeDocument.body, { useCORS: true, scale: 2 });
        // const imageData = canvas.toDataURL("image/png");

        

        // Centerly align image
        const pageWidth = pdf.internal.pageSize.getWidth();
        const imageWidth = 70;
        const x = (pageWidth - imageWidth) / 2;
        // const riskGraphImage = generateRiskGraphImage(riskPercentage, riskColor, 280, 140);

        if(data?.report?.data?.riskscore){
            // Render the graph as an image using html2canvas
            const chartRef = document.querySelector('.AmlCheckDetails_risk__indicator__1nR0C');
            const imageData = await html2canvas(chartRef).then(canvas => canvas.toDataURL('image/png'));

            if(data?.serviceType === "Transaction Check"){
                pdf.setFontSize(12);
                pdf.setTextColor(0, 0, 0);
                pdf.setFont("helvetica", "bold");
                pdf.text("Counterparty Risk Score", 105, updateY(5), { align: "center" });
                pdf.setFont("helvetica", "normal");
            }


            pdf.addImage(imageData, 'PNG', x, updateY(0), imageWidth, 40);
            

            /*Risk Text*/ 
            let riskText = ""
            pdf.setFontSize(28);
            let textColor = [0, 0, 0];
            if(riskPercentage <= 20){
                textColor = [0, 191, 89];
                riskText = "Low Risk"
            }else if(riskPercentage > 20 && riskPercentage <= 50){
                textColor = [249, 144, 0];
                riskText = "Medium Risk"
            }else{
                textColor = [231, 76, 60];
                riskText = "High Risk"
            }

            pdf.setFontSize(24);
            pdf.setTextColor(textColor[0], textColor[1], textColor[2]);
            pdf.setFont("helvetica", "bold");
            pdf.text(riskText, 105, updateY(50), { align: "center" });
            pdf.setFont("helvetica", "normal");

        }else{

            // No Risk Section
            const noRiskImg = "/pdf/noRisk.png";
            const noRiskPageWidth = pdf.internal.pageSize.getWidth();
            const noRiskImgWidth = 55;
            const noRiskImgX = (noRiskPageWidth - noRiskImgWidth) / 2;
            pdf.addImage(noRiskImg, "PNG", noRiskImgX, updateY(0), 55, 45); // set this image X & Y axis same as Graph - based on conditional render

            pdf.setTextColor(0, 0, 0); // text color set back to black
            pdf.setFontSize(18);
            pdf.setFont("helvetica", "bold");
            pdf.text("We haven't found risks", 105, updateY(45), { align: "center" }); // also set this text X & Y axis same with Graph bottom text
            pdf.setFont("helvetica", "normal");

            pdf.setFontSize(11);
            pdf.text("We have checked our databases and haven't found any risks.", 105, updateY(0), { align: "center" });
        }


        if(data?.report?.data?.addressDetailsData){

            if(data?.report?.data?.addressDetailsData && (data?.report?.data?.addressDetailsData?.final_balance || data?.report?.data?.addressDetailsData?.received || data?.report?.data?.addressDetailsData?.sent)){
                pdf.setTextColor(0, 0, 0); // text color set back to black
                pdf.setFontSize(14);
                pdf.setFont("helvetica", "bold");
                pdf.text("Balance", 15, updateY(15), { align: "left" });
                pdf.setFont("helvetica", "normal");

                if(data?.report?.data?.addressDetailsData?.final_balance >= 0 && data?.report?.data?.addressDetailsData?.balance_usd >= 0){
                    pdf.setFontSize(11);
                    pdf.text("Total balance", 15, updateY(0), { align: "left" });
                    pdf.setFont("helvetica", "bold");
                    pdf.setFontSize(13);
                    
                    let originalValue = parseFloat(data?.report?.data?.addressDetailsData?.final_balance);
                    let convertedValue = originalValue / Math.pow(10, data?.report?.data?.addressDetailsData?.reported_balance_precision || coinsPrecisionRates(data?.asset));
                    pdf.text(`$${data?.report?.data?.addressDetailsData?.balance_usd?.toFixed(2)} - ${convertedValue} ${data?.report?.data?.asset}`, 193, updateY(-10), { align: "right" });
                    pdf.setFont("helvetica", "normal");
                }
            
                if(data?.report?.data?.addressDetailsData?.received >= 0 && data?.report?.data?.addressDetailsData?.balance_usd >= 0){
                    pdf.setFontSize(11);
                    pdf.text("Total recieved", 15, updateY(0), { align: "left" });
                    pdf.setFont("helvetica", "bold");
                    pdf.setFontSize(11);

                    let recValue = parseFloat(data?.report?.data?.addressDetailsData?.received);
                    let convertedRecValue = recValue / Math.pow(10, data?.report?.data?.addressDetailsData?.reported_balance_precision || coinsPrecisionRates(data?.asset));
                    pdf.text(`$${data?.report?.data?.addressDetailsData?.balance_usd?.toFixed(2)} - ${convertedRecValue} ${data?.report?.data?.asset}`, 193, updateY(-10), { align: "right" });
                    pdf.setFont("helvetica", "normal");

                    pdf.setDrawColor(192, 192, 192);
                    pdf.line(15, currentY + 5, 193, currentY + 5);
                }


                if(data?.report?.data?.addressDetailsData?.sent >= 0 && data?.report?.data?.addressDetailsData?.sent_usd >= 0){
                    pdf.setFontSize(11);
                    pdf.text("Total spent", 15, updateY(2), { align: "left" });
                    pdf.setFont("helvetica", "bold");
                    pdf.setFontSize(11);

                    let spentValue = parseFloat(data?.report?.data?.addressDetailsData?.sent);
                    let convertedSpentValue = spentValue / Math.pow(10, data?.report?.data?.addressDetailsData?.reported_balance_precision || coinsPrecisionRates(data?.asset));
                    pdf.text(`$${data?.report?.data?.addressDetailsData?.sent_usd?.toFixed(2)} - ${convertedSpentValue} ${data?.report?.data?.asset}`, 193, updateY(-10), { align: "right" });
                    pdf.setFont("helvetica", "normal");
                }
            }

            pdf.setTextColor(0, 0, 0); // text color set back to black
        
            if(data?.report?.data?.addressDetailsData?.first_tx || data?.report?.data?.addressDetailsData?.last_tx || data?.report?.data?.addressDetailsData?.n_txs >= 0){
                pdf.setFontSize(14);
                pdf.setFont("helvetica", "bold");
                pdf.text("Info", 15, updateY(15), { align: "left" });
                pdf.setFont("helvetica", "normal");

                if(data?.report?.data?.addressDetailsData?.first_tx){
                    pdf.setFontSize(11);
                    pdf.text("First change of balance", 15, updateY(0), { align: "left" });
                    pdf.setFont("helvetica", "bold");
                    pdf.setFontSize(11);

                    let firstChangeDate = moment(data?.report?.data?.addressDetailsData?.first_tx).utc().format("DD MMM YYYY HH:mm [UTC]");
                    pdf.text(`${firstChangeDate}`, 193, updateY(-10), { align: "right" });
                    pdf.setFont("helvetica", "normal");
                }

                if(data?.report?.data?.addressDetailsData?.last_tx){
                    pdf.setFontSize(11);
                    pdf.text("Last change of balance", 15, updateY(2), { align: "left" });
                    pdf.setFont("helvetica", "bold");
                    pdf.setFontSize(11);

                    let lastChangeDate = moment(data?.report?.data?.addressDetailsData?.last_tx).utc().format("DD MMM YYYY HH:mm [UTC]");
                    pdf.text(`${lastChangeDate}`, 193, updateY(-10), { align: "right" });
                    pdf.setFont("helvetica", "normal");
                }

                if(data?.report?.data?.addressDetailsData?.n_txs >= 0){
                    pdf.setFontSize(11);
                    pdf.text("Number of transactions", 15, updateY(2), { align: "left" });
                    pdf.setFont("helvetica", "bold");
                    pdf.setFontSize(11);
                    pdf.text(`${data?.report?.data?.addressDetailsData?.n_txs}`, 193, updateY(-10), { align: "right" });
                    pdf.setFont("helvetica", "normal");
                }
            }
        }


        /**SPECIAL NOTE SECTION FOR LIMITED BLOCKCHAINS */
        if(data?.asset === "BSV" || data?.network === "BSV" || 
            data?.asset === "DOGE" || data?.network === "DOGE" || 
            data?.asset === "ZEC" || data?.network === "ZEC" ||
            data?.asset === "SOL" || data?.network === "SOL" || 
            data?.asset === "ARB" || data?.network === "ARB" || 
            data?.asset === "DOT" || data?.network === "DOT" ||
            data?.asset === "AVAX" || data?.network === "AVAX" ||
            data?.asset === "TON" || data?.network === "TON" ||
            data?.asset === "NEAR" || data?.network === "NEAR" ||
            data?.asset === "XTZ" || data?.asset === "XTZ" ){
            pdf.setFillColor(233, 243, 255);
            pdf.roundedRect(15, updateY(10), 178, 50, 3, 3, 'F');
            pdf.setTextColor(18, 72, 182);
            pdf.setFontSize(10);
            pdf.text("Please note that analysis of this blockchain is limited.", 20, updateY(0), { align: "left" });
            pdf.text("Clusterization and risk score propagation are not available for this check. We verify if the wallet addresses", 20, updateY(0), { align: "left" });
            pdf.text("involved in the transaction have connections with blacklists or other high-risk indicators. If no such", 20, updateY(-5), { align: "left" });
            pdf.text(`onnections are identified, we indicate that "We haven't found risks". If a connection with high-risk`, 20, updateY(-5), { align: "left" });
            pdf.text("indicators is revealed, the screening report provides details of this connection. Risk score may be", 20, updateY(-5), { align: "left" });
            pdf.text("provided only if any of the wallet addresses involved in the transaction is linked to an identified entity, for", 20, updateY(-5), { align: "left" });
            pdf.text("example a crypto exchange.", 20, updateY(-5), { align: "left" });
        }

        pdf.setTextColor(0, 0, 0); // text color set back to black



        let tokens = data?.report?.data?.addressDetailsData?.tokens;
        let notLogo = "/pdf/notLogo.png"

        if (tokens) {
            // Iterate over the keys of the tokens object
            Object.keys(tokens).forEach((key) => {
                let tokenArray = tokens[key];
                pdf.setFontSize(14);
                pdf.setFont("helvetica", "bold");
                pdf.setTextColor(0, 0, 0); // Black text color
                
                // Print the key (array name) in capital letters
                pdf.text(`${key.toUpperCase()} balance`, 15, updateY(15), { align: "left" });
                pdf.setFont("helvetica", "normal");

                pdf.setFillColor(230, 230, 230);
                pdf.roundedRect(60, currentY - 5, 22, 7, 3, 3, 'F')
                pdf.setTextColor(0, 0, 0); // Black text color
                pdf.setFontSize(9);

                // Print the length of the array
                pdf.text(`${tokenArray.length} assets`, 65, currentY - 0.5)

                // Iterate over each token in the array
                tokenArray.forEach((token, index) => {
                    pdf.setFontSize(11);
                    const tokenLogo = token?.token_logo || notLogo;
                    const yPosition = index === 0 ? updateY(0) : updateY(5); // Use updateY(0) for the first record, updateY(5) for the rest
                    pdf.addImage(tokenLogo, "PNG", 15, yPosition, 10, 10);
                    pdf.setFont("helvetica", "bold");
                    pdf.setTextColor(0, 0, 0); // Black text color
                    pdf.text(`${token?.token_symbol}`, 30, currentY + 5, { align: "left" });
                    pdf.setFontSize(11);
                    pdf.text((Number(token.balance) / Math.pow(10, token.token_decimals)).toFixed(5), 193, currentY + 5, { align: "right" });
                    pdf.setFont("helvetica", "normal");
                    pdf.setFontSize(9);
                    pdf.setTextColor(140, 140, 140);
                    pdf.text(`${token?.token_name?.toUpperCase()}`, 30, currentY + 10, { align: "left" });
                    if(token?.balance_usd){
                        pdf.setFont("helvetica", "bold");
                        pdf.text(`$${token?.balance_usd?.toFixed(2)}`, 193, currentY + 10, { align: "right" });
                        pdf.setFont("helvetica", "normal");
                    }
                });
            });
        }

            
        pdf.setTextColor(0, 0, 0);

        let signals = data?.report?.data?.signals

        if(signals?.child_exploitation || signals?.dark_market || signals?.dark_service || signals?.enforcement_action || signals?.exchange_fraudulent || signals?.gambling || signals?.illegal_service || signals?.mixer || signals?.ransom || signals?.sanctions || signals?.scam || signals?.stolen_coins || signals?.terrorism_financing){
            const dangerIcon = "/pdf/danger.png";
            pdf.addImage(dangerIcon, "PNG", 15, updateY(15), 5, 5);
            pdf.setFontSize(14);
            pdf.setFont("helvetica", "bold");
            pdf.text("Danger", 23, currentY + 4, { align: "left" });
            pdf.setFont("helvetica", "normal");
            pdf.setDrawColor(192, 192, 192);
            pdf.line(43, currentY + 3, 193, currentY + 3);

            /* Child Exploitation*/
            if(signals?.child_exploitation > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.child_exploitation * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(0), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Child Exploitation", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Dark Market*/
            if(signals?.dark_market > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.dark_market * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Dark Market", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Dark Service*/
            if(signals?.dark_service > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.dark_service * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Dark Service", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Enforcement action*/
            if(signals?.enforcement_action > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.enforcement_action * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Enforcement action", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Fraudulent Exchange*/
            if(signals?.exchange_fraudulent > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.exchange_fraudulent * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Fraudulent Exchange", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }

            /* Fraudulent Exchange*/
            if(signals?.exchange_fraudulent > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.exchange_fraudulent * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Fraudulent Exchange", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Gambling*/
            if(signals?.gambling > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.gambling * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Gambling", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Illegal Service*/
            if(signals?.illegal_service > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.illegal_service * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Illegal Service", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Mixer*/
            if(signals?.mixer > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.mixer * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Mixer", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Ransom*/
            if(signals?.ransom > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.ransom * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Ransom", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Sanctions*/
            if(signals?.sanctions > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.sanctions * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Sanctions", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Scam*/
            if(signals?.scam > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.scam * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Scam", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }

            /* Stolen Coins*/
            if(signals?.stolen_coins > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.stolen_coins * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Stolen Coins", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Terrorism Financing*/
            if(signals?.terrorism_financing > 0){
                pdf.setFillColor(370, 224, 207);
                // Graph Percentage
                let graph = signals?.terrorism_financing * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(255, 109, 92);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Terrorism Financing", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(60, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }
            
        }

        
        /**Suspicious sources Part */

        if(signals?.atm || signals?.risky_exchange || signals?.p2p_exchange_mlrisk_high || signals?.p2p_exchange_mlrisk_low || signals?.liquidity_pools || signals?.unnamed_service){
            pdf.setTextColor(0, 0, 0);
            const suspiciousIcon = "/pdf/suspicious.png";
            pdf.addImage(suspiciousIcon, "PNG", 15, updateY(15), 5, 5);
            pdf.setFontSize(14);
            pdf.setFont("helvetica", "bold");
            pdf.text("Suspicious sources", 23, currentY + 4, { align: "left" });
            pdf.setFont("helvetica", "normal");
            pdf.setDrawColor(192, 192, 192);
            pdf.line(72, currentY + 3, 193, currentY + 3);

            
            /* ATM*/
            if(signals?.atm > 0){
                pdf.setFillColor(380, 234, 214);
                // Graph Percentage
                let graph = signals?.atm * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(0), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(249, 144, 0);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("ATM", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(70, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Exchange | High Risk*/
            if(signals?.risky_exchange > 0){
                pdf.setFillColor(380, 234, 214);
                // Graph Percentage
                let graph = signals?.risky_exchange * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(249, 144, 0);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Exchange | High Risk", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(70, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* High-Risk P2P Exchange*/
            if(signals?.p2p_exchange_mlrisk_high > 0){
                pdf.setFillColor(380, 234, 214);
                // Graph Percentage
                let graph = signals?.p2p_exchange_mlrisk_high * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(249, 144, 0);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("High-Risk P2P Exchange", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(70, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }

            /* Low-Risk P2P Exchange*/
            if(signals?.p2p_exchange_mlrisk_low > 0){
                pdf.setFillColor(380, 234, 214);
                // Graph Percentage
                let graph = signals?.p2p_exchange_mlrisk_low * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(249, 144, 0);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Low-Risk P2P Exchange", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(70, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Liquidity Pools*/
            if(signals?.liquidity_pools > 0){
                pdf.setFillColor(380, 234, 214);
                // Graph Percentage
                let graph = signals?.liquidity_pools * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(249, 144, 0);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Liquidity Pools", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(70, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }

            /* Unnamed Service*/
            if(signals?.unnamed_service > 0){
                pdf.setFillColor(380, 234, 214);
                // Graph Percentage
                let graph = signals?.unnamed_service * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(249, 144, 0);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Unnamed Service", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(70, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }

        }


        /**Trusted Part */
        if(signals?.exchange || signals?.ico || signals?.marketplace || signals?.miner || signals?.merchant_services || signals?.payment || signals?.wallet || signals?.other || signals?.seized_assets || signals?.p2p_exchange){
            pdf.setTextColor(0, 0, 0);
            const trustedIcon = "/pdf/trusted.png";
            pdf.addImage(trustedIcon, "PNG", 15, updateY(15), 5, 5);
            pdf.setFontSize(14);
            pdf.setFont("helvetica", "bold");
            pdf.text("Trusted sources", 23, currentY + 4, { align: "left" });
            pdf.setFont("helvetica", "normal");
            pdf.setDrawColor(192, 192, 192);
            pdf.line(65, currentY + 3, 193, currentY + 3);

            /* Exchange*/
            if(signals?.exchange > 0){
                pdf.setFillColor(193, 240, 216);
                // Graph Percentage
                let graph = signals?.exchange * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(0), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(0, 191, 89);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Exchange", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(66, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }

            /* ICO*/
            if(signals?.ico > 0){
                pdf.setFillColor(193, 240, 216);
                // Graph Percentage
                let graph = signals?.ico * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(0, 191, 89);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("ICO", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(66, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }

            /* Marketplace*/
            if(signals?.marketplace > 0){
                pdf.setFillColor(193, 240, 216);
                // Graph Percentage
                let graph = signals?.marketplace * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(0, 191, 89);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Marketplace", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(66, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Miner*/
            if(signals?.miner > 0){
                pdf.setFillColor(193, 240, 216);
                // Graph Percentage
                let graph = signals?.miner * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(0, 191, 89);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Miner", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(66, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Merchant Services*/
            if(signals?.merchant_services > 0){
                pdf.setFillColor(193, 240, 216);
                // Graph Percentage
                let graph = signals?.merchant_services * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(0, 191, 89);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Merchant Services", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(66, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }

            /* Payment Management*/
            if(signals?.payment > 0){
                pdf.setFillColor(193, 240, 216);
                // Graph Percentage
                let graph = signals?.payment * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(0, 191, 89);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Payment Management", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(66, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Wallet*/
            if(signals?.wallet > 0){
                pdf.setFillColor(193, 240, 216);
                // Graph Percentage
                let graph = signals?.wallet * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(0, 191, 89);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Wallet", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(66, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Other*/
            if(signals?.other > 0){
                pdf.setFillColor(193, 240, 216);
                // Graph Percentage
                let graph = signals?.other * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(0, 191, 89);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Other", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(66, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* Seized Assets*/
            if(signals?.seized_assets > 0){
                pdf.setFillColor(193, 240, 216);
                // Graph Percentage
                let graph = signals?.seized_assets * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(0, 191, 89);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("Seized Assets", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(66, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


            /* P2P Exchange*/
            if(signals?.p2p_exchange > 0){
                pdf.setFillColor(193, 240, 216);
                // Graph Percentage
                let graph = signals?.p2p_exchange * 100;
                // Graph width = right margin - left margin
                let graphWidh = 193 - 15
                pdf.roundedRect(15, updateY(5), graphWidh / 100 * graph, 10, 1, 1, 'F');
                pdf.setTextColor(0, 191, 89);
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "bold");
                pdf.text(`${graph?.toFixed(1)}%`, 23, currentY + 6, { align: "left" });
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text("P2P Exchange", 40, currentY + 6, { align: "left" });
                pdf.setLineDash([1, 1]);
                pdf.setDrawColor(231, 227, 227);
                pdf.line(66, currentY + 5, 193, currentY + 5);
                pdf.setLineDash(); // Reset the dash pattern to solid
            }


        }
        

        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold");
        pdf.text("What is a Risk Score", 15, updateY(15), { align: "left" });
        pdf.setFont("helvetica", "normal");

        pdf.setFontSize(13);
        pdf.text("Risk Score is a metric that estimates the likelihood that an address/transaction is", 15, updateY(0), { align: "left" });
        pdf.text("related to illegal activities. The value can range from", 15, updateY(-4), { align: "left" });
        pdf.setFont("helvetica", "bold");
        pdf.text("Low Risk", 122, currentY, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.text("(min. 0%) to", 143, currentY, { align: "left" });
        pdf.setFont("helvetica", "bold");
        pdf.text("High Risk", 169, currentY, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.text("(max. 100%).", 15, updateY(-4), { align: "left" });


        
        const lowRiskImg = "/pdf/lowRisk.png";
        pdf.addImage(lowRiskImg, "PNG", 15, updateY(15), 36, 18);

        pdf.setTextColor(0, 191, 89);
        pdf.setFontSize(13);
        pdf.setFont("helvetica", "bold");
        pdf.text("Low Risk", 60, currentY + 7, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Transfer from the wallet should be safe.", 60, currentY + 15, { align: "left" });

        
        
        const mediumRiskImg = "/pdf/mediumRisk.png";
        pdf.addImage(mediumRiskImg, "PNG", 15, updateY(25), 36, 18);
        pdf.setTextColor(249, 144, 0);
        pdf.setFontSize(13);
        pdf.setFont("helvetica", "bold");
        pdf.text("Risk zone", 60, currentY + 2, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("From 50% and above, there is a chance that transfers from this wallet can", 60, updateY(0), { align: "left" });
        pdf.text("be blocked by centralized cryptocurrency exchanges (such as Binance,", 60, updateY(-5), { align: "left" });
        pdf.text("Huobi, etc.) or other reputable cryptocurrency businesses.", 60, updateY(-5), { align: "left" });


        
        const dangerRiskImg = "/pdf/dangerRisk.png";
        pdf.addImage(dangerRiskImg, "PNG", 15, updateY(5), 36, 18);
        pdf.setTextColor(231, 76, 60);
        pdf.setFontSize(13);
        pdf.setFont("helvetica", "bold");
        pdf.text("Extreme Danger", 60, currentY + 5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Transfers from this wallet have a big chance of being blocked.", 60, updateY(3), { align: "left" });

        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold");
        pdf.text("Fintracking Identifies 26 Money Laundering Risk Sources:", 15, updateY(20), { align: "left" });
        pdf.setFont("helvetica", "normal");

        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold");
        pdf.text("Danger", 15, updateY(10), { align: "left" });
        pdf.setFont("helvetica", "normal");


        /**Bullet Notes */
        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(0), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Child Exploitation", 20, updateY(-8.5), { align: "left" });
        pdf.text("Child Exploitation", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Persons associated with child exploitation.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Dark Market", 20, updateY(-8.5), { align: "left" });
        pdf.text("Dark Market", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins associated with illegal activities.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Dark Service", 20, updateY(-8.5), { align: "left" });
        pdf.text("Dark Service", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins related to child abuse, terrorist financing or drug trafficking.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Enforcement action", 20, updateY(-8.5), { align: "left" });
        pdf.text("Enforcement action", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("The entity is subject to proceedings with legal authorities.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Fraudulent Exchange", 20, updateY(-8.5), { align: "left" });
        pdf.text("Fraudulent Exchange", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Exchanges involved in exit scams, illegal behavior, or whose funds have been confiscated by", 20, currentY + 7, { align: "left" });
        pdf.text("government authorities.", 20, currentY + 12, { align: "left" });


        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(15), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Gambling", 20, updateY(-8.5), { align: "left" });
        pdf.text("Gambling", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins associated with unlicensed online games.", 20, currentY + 7, { align: "left" });

        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Illegal Service", 20, updateY(-8.5), { align: "left" });
        pdf.text("Illegal Service", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins associated with illegal activities.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Mixer", 20, updateY(-8.5), { align: "left" });
        pdf.text("Mixer", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins that passed through a mixer to make tracking difficult or impossible. Mixers are mainly", 20, currentY + 7, { align: "left" });
        pdf.text("used for money laundering.", 20, currentY + 12, { align: "left" });


        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(15), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Ransom", 20, updateY(-8.5), { align: "left" });
        pdf.text("Ransom", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins obtained through extortion or blackmail.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Sanctions", 20, updateY(-8.5), { align: "left" });
        pdf.text("Sanctions", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Sanctioned entities.", 20, currentY + 7, { align: "left" });
        

        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Scam", 20, updateY(-8.5), { align: "left" });
        pdf.text("Scam", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins that were obtained by deception.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Stolen Coins", 20, updateY(-8.5), { align: "left" });
        pdf.text("Stolen Coins", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins obtained by hijacking someone else's cryptocurrency.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(231, 95, 80);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Terrorism Financing", 20, updateY(-8.5), { align: "left" });
        pdf.text("Terrorism Financing", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Entities associated with terrorism financing.", 20, currentY + 7, { align: "left" });



        /**Suspicious sources Bullet Notes */
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold");
        pdf.text("Suspicious sources", 15, updateY(15), { align: "left" });
        pdf.setFont("helvetica", "normal");


        pdf.setFillColor(249, 144, 0);
        pdf.circle(16, updateY(0), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("ATM", 20, updateY(-8.5), { align: "left" });
        pdf.text("ATM", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins obtained via cryptocurrency ATM operator.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(249, 144, 0);
        pdf.circle(16, updateY(10), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Exchange | High Risk", 20, updateY(-8.5), { align: "left" });
        pdf.text("Exchange | High Risk", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("An entity becomes high-risk based on the following criteria:", 20, currentY + 7, { align: "left" });
        pdf.text("No KYC: Requires absolutely no customer information before allowing any level of deposit/", 20, updateY(7), { align: "left" });
        pdf.text("withdrawal, or makes no attempt to verify that information.", 20, updateY(-5), { align: "left" });
        pdf.text("Criminal Connections: Criminal charges against the legal entity in connection with AML/CFT", 20, updateY(0), { align: "left" });
        pdf.text("violations.", 20, updateY(-5), { align: "left" });
        pdf.text("Impact: High exposure to risky services such as darknet markets, other high-risk exchanges, or", 20, updateY(0), { align: "left" });
        pdf.text("blending is defined as a service whose direct high-risk exposure differs by one standard", 20, updateY(-5), { align: "left" });
        pdf.text("deviation from the average of all identified exchanges over a 12-month period.", 20, updateY(-5), { align: "left" });
        pdf.text("Jurisdiction: based in a jurisdiction with weak AML/CFT measures.", 20, updateY(0), { align: "left" });
        pdf.text("Unlicensed: Does not have any specific license to trade cryptocurrencies.", 20, updateY(0), { align: "left" });


        pdf.setFillColor(249, 144, 0);
        pdf.circle(16, updateY(3), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("P2P Exchange | High Risk", 20, updateY(-8.5), { align: "left" });
        pdf.text("P2P Exchange | High Risk", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("The organization does not have any special license to conduct business related to the provision", 20, currentY + 7, { align: "left" });
        pdf.text("of cryptocurrency exchange services, when participants exchange directly with each other,", 20, updateY(2), { align: "left" });
        pdf.text("without intermediaries.", 20, updateY(-5), { align: "left" });
        pdf.text("It also includes entities that are licensed but located in listed jurisdictions, are listed as non-", 20, updateY(0), { align: "left" });
        pdf.text("cooperating companies by the FATF, or do not provide KYC for large-value transactions, making", 20, updateY(-5), { align: "left" });
        pdf.text("them attractive for money laundering.", 20, updateY(-5), { align: "left" });



        /**Trusted sources Bullet Notes */
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold");
        pdf.text("Trusted sources", 15, updateY(15), { align: "left" });
        pdf.setFont("helvetica", "normal");


        pdf.setFillColor(0, 191, 89);
        pdf.circle(16, updateY(0), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Exchange", 20, updateY(-8.5), { align: "left" });
        pdf.text("Exchange", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("The organization allows users to buy, sell and trade cryptocurrencies by holding trading licenses", 20, currentY + 7, { align: "left" });
        pdf.text("that include the following aspects of the services:", 20, updateY(2), { align: "left" });
        pdf.text("— Depository, brokerage or other related financial services that provide exchange services", 20, updateY(-5), { align: "left" });
        pdf.text("where participants interact with a central party.", 20, updateY(-5), { align: "left" });
        pdf.text("And does not include:", 20, updateY(0), { align: "left" });
        pdf.text("— Licenses for non-specific financial services and jurisdictions included in the FATF non", 20, updateY(-5), { align: "left" });
        pdf.text("cooperative list.", 20, updateY(-5), { align: "left" });
        pdf.text("They represent the most important and most used category of entities in the cryptocurrency", 20, updateY(0), { align: "left" });
        pdf.text("industry, accounting for 90% of all funds sent through these services.", 20, updateY(-5), { align: "left" });


        pdf.setFillColor(0, 191, 89);
        pdf.circle(16, updateY(0), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("ICO", 20, updateY(-8.5), { align: "left" });
        pdf.text("ICO", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("The organization that crowdfunds its project by selling their newly minted cryptocurrency to", 20, currentY + 7, { align: "left" });
        pdf.text("investors in exchange for fiat currency or more common cryptocurrencies such as Bitcoin and", 20, updateY(2), { align: "left" });
        pdf.text("Ether.", 20, updateY(-5), { align: "left" });
        pdf.text("There are many legitimate examples of these offerings, but also many cases where bad actors", 20, updateY(0), { align: "left" });
        pdf.text("raise funds through ICOs, then they take the money and disappear.", 20, updateY(-5), { align: "left" });


        pdf.setFillColor(0, 191, 89);
        pdf.circle(16, updateY(0), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Marketplace", 20, updateY(-8.5), { align: "left" });
        pdf.text("Marketplace", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins that were used to pay for legal activities", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(0, 191, 89);
        pdf.circle(16, updateY(7), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Merchant Services", 20, updateY(-8.5), { align: "left" });
        pdf.text("Merchant Services", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("The entity that allows businesses to accept payments from their customers, also known as", 20, currentY + 7, { align: "left" });
        pdf.text("payment gateways or payment processors.", 20, updateY(2), { align: "left" });
        pdf.text("It often faciliates conversions to local fiat currency and clearing the funds into the merchant's", 20, updateY(0), { align: "left" });
        pdf.text("bank account.", 20, updateY(-5), { align: "left" });


        pdf.setFillColor(0, 191, 89);
        pdf.circle(16, updateY(0), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Miner", 20, updateY(-8.5), { align: "left" });
        pdf.text("Miner", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins mined by miners and not forwarded yet.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(0, 191, 89);
        pdf.circle(16, updateY(7), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Other", 20, updateY(-8.5), { align: "left" });
        pdf.text("Other", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins obtained through airdrops, token sales or other means.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(0, 191, 89);
        pdf.circle(16, updateY(7), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("P2P Exchange", 20, updateY(-8.5), { align: "left" });
        pdf.text("P2P Exchange", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("The entity is licensed to conduct a business that is specific to providing cryptocurrency", 20, currentY + 7, { align: "left" });
        pdf.text("exchange services where participants exchange directly with each other, without a middleman", 20, updateY(2), { align: "left" });
        pdf.text("It does not include non-specific financial services licenses and jurisdictions that are on the non", 20, updateY(0), { align: "left" });
        pdf.text("cooperative FATF list.", 20, updateY(-5), { align: "left" });


        pdf.setFillColor(0, 191, 89);
        pdf.circle(16, updateY(0), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Payment Processor", 20, updateY(-8.5), { align: "left" });
        pdf.text("Payment Processor", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins associated with payment services.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(0, 191, 89);
        pdf.circle(16, updateY(7), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Seized Assets", 20, updateY(-8.5), { align: "left" });
        pdf.text("Seized Assets", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Crypto assets seized by the government.", 20, currentY + 7, { align: "left" });


        pdf.setFillColor(0, 191, 89);
        pdf.circle(16, updateY(7), 1, 'F')
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        // pdf.text("Wallet", 20, updateY(-8.5), { align: "left" });
        pdf.text("Wallet", 20, currentY + 1.5, { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text("Coins stored in verified wallets.", 20, currentY + 7, { align: "left" });


        /**Disclaimer */
        pdf.setFontSize(20);
        pdf.setFont("helvetica", "bold");
        pdf.text("Disclaimer", 15, updateY(19), { align: "left" });
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(12.5);

        pdf.text("It is up to the user to determine their risk appetite. For more information on a specific", 15, updateY(0), { align: "left" });
        pdf.text("transaction or wallet, perform an investigation to determine the specific risks and exposures.", 15, updateY(-4), { align: "left" });
        pdf.text("Fintracking assumes no risk or liability for any transaction or wallet based on these reports.", 15, updateY(-4), { align: "left" });

        pdf.text("This Report is for information purpose only and is valid on the date of its issuance.", 15, updateY(0), { align: "left" });

        pdf.text("Fintracking does not give any express or implied warranty to the validity of any Report", 15, updateY(0), { align: "left" });
        pdf.text("after the date of its’ issuance.", 15, updateY(-4), { align: "left" });

        pdf.text("Fintracking takes all steps necessary to provide an independent, up-to-date analysis and", 15, updateY(0), { align: "left" });
        pdf.text("accurate information in the Report.", 15, updateY(-4), { align: "left" });

        pdf.text("Fintracking is not liable for any changes in assumptions and updates to this report in", 15, updateY(0), { align: "left" });
        pdf.text("case of new facts or circumstances occurring after the date of the Report or facts", 15, updateY(-4), { align: "left" });
        pdf.text("that were not known to Fintracking at the time of generation of this Report.", 15, updateY(-4), { align: "left" });

        pdf.text("Any decision taken by the recipient of this Report is made solely on their own risk.", 15, updateY(0), { align: "left" });
        pdf.text("The liability of Fintracking is hereby excluded to the fullest extent permitted by the", 15, updateY(-4), { align: "left" });
        pdf.text("applicable law.", 15, updateY(-4), { align: "left" });

        pdf.text("The Report does not discharge any obligation of proper internal risk assessment", 15, updateY(0), { align: "left" });
        pdf.text("and/or decision making process. Certain information, due to high risk (e.g. crime", 15, updateY(-4), { align: "left" });
        pdf.text("related), used for analysis, may not be able to be disclosed to the recipient. Fintracking", 15, updateY(-4), { align: "left" });
        pdf.text("services are provided to you “as is” and with all faults and defects without warranty", 15, updateY(-4), { align: "left" });
        pdf.text("of any kind. To the maximum extent permitted under applicable law, Fintracking, on its", 15, updateY(-4), { align: "left" });
        pdf.text("own behalf and on behalf of its affiliates and their respective licensors and service", 15, updateY(-4), { align: "left" });
        pdf.text("providers, expressly disclaims all warranties, whether express, implied, statutory or", 15, updateY(-4), { align: "left" });
        pdf.text("otherwise, with respect to Fintracking services, including all implied warranties of", 15, updateY(-4), { align: "left" });
        pdf.text("merchantability, fitness for a particular purpose, title and non-infringement, and", 15, updateY(-4), { align: "left" });
        pdf.text("warranties that may arise out of course of dealing, course of performance, usage or", 15, updateY(-4), { align: "left" });
        pdf.text("trade practice.", 15, updateY(-4), { align: "left" });

        pdf.text("Without limitation to the foregoing, Fintracking provides no warranty or undertaking, and", 15, updateY(0), { align: "left" });
        pdf.text("makes no representation of any kind that the services will meet your requirements,", 15, updateY(-4), { align: "left" });
        pdf.text("achieve any intended results, be compatible or work with any other software,", 15, updateY(-4), { align: "left" });
        pdf.text("applications, systems or services, operate without interruption, meet any", 15, updateY(-4), { align: "left" });
        pdf.text("performance or reliability standards or be error free or that any errors or defects can", 15, updateY(-4), { align: "left" });
        pdf.text("or will be corrected.", 15, updateY(-4), { align: "left" });

        pdf.text("To the fullest extent permitted by applicable law, in no event will Fintracking services, or", 15, updateY(0), { align: "left" });
        pdf.text("any of its respective service providers, have any liability arising from or related to", 15, updateY(-4), { align: "left" });
        pdf.text("your use of or inability to use the services for:", 15, updateY(-4), { align: "left" });

        pdf.text("a) any act or alleged act, or any omission or alleged omission, that does not", 15, updateY(0), { align: "left" });
        pdf.text("constitute wilful misconduct by Fintracking, as determined in a final, non-appealable", 15, updateY(-4), { align: "left" });
        pdf.text("judgment by a court of competent jurisdiction;", 15, updateY(-4), { align: "left" });

        pdf.text("b) lost profits, cost of substitute goods or services, loss of data, loss of goodwill,", 15, updateY(0), { align: "left" });
        pdf.text("business interruption, computer failure or malfunction or any other consequential,", 15, updateY(-4), { align: "left" });
        pdf.text("incidental, indirect, exemplary, special or punitive damages;", 15, updateY(-4), { align: "left" });

        pdf.text("c) direct damages in amounts that in the aggregate exceed the amount actually paid", 15, updateY(0), { align: "left" });
        pdf.text("by you for the services;", 15, updateY(-4), { align: "left" });

        pdf.text("d) any third-party claims (whether based in statute, contract, tort or otherwise).", 15, updateY(0), { align: "left" });

        pdf.text("The foregoing limitations:", 15, updateY(0), { align: "left" });

        pdf.text("a) will apply whether such damages arise out of breach of contract, tort (including", 15, updateY(0), { align: "left" });
        pdf.text("negligence) or otherwise and regardless of whether such damages were foreseeable", 15, updateY(-4), { align: "left" });
        pdf.text("or Fintracking was advised of the possibility of such damages; and", 15, updateY(-4), { align: "left" });

        pdf.text("b) include any loss caused by the failure of the services to correctly identify", 15, updateY(0), { align: "left" });
        pdf.text("participants in blockchain transactions or the levels of any associated risks such as", 15, updateY(-4), { align: "left" });
        pdf.text("fraudulent activity, and you acknowledge AND AGREE THAT YOU DO NOT RELY ON", 15, updateY(-4), { align: "left" });
        pdf.text("THE SERVICES FOR SUCH PURPOSES.", 15, updateY(-4), { align: "left" });




        // pdf.save("rep.pdf");
        let date = new Date()
        pdf.save(`${data?.serviceType === "Wallet Check" ? "Wallet_Check_Report" : "Transaction_Check_Report"}_${moment(date).format('MMMM DD, YYYY [at] hh:mm:ss A')}.pdf`);

        // Remove the iframe from the document
        // document.body.removeChild(iframe);
    }catch(err){
        console.log("Error while generating Wallet/Txn report ==>", err)
    }
}