import React, { useCallback, useEffect, useState } from "react";
import "./newSignupPage.css";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";

// CUSTOMIZE ANY UI BUTTON
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { HandleSuccess } from "../../helpers/toast.helper";
import {
  GoogleClientId,
  baseApiUrl,
  localBaseApiUrl,
} from "../../constants/environment.constants";
import { ToastContainer } from "react-toastify";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoMdEyeOff } from "react-icons/io";
import { captitalizeEveryField } from "../../helpers/capitalize.helper";
import Loader from "../../components/Loader/Loader";

const NewSignupPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);
  const [facebookProfile, setFacebookProfile] = useState(null);

  // useState for invalid credentials error
  const [invalidCredErr, setInvalidCredErr] = useState("");

  /*useState for show and hide password text */
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  // console.log("profile-->", profile);
  // console.log("user-->", user);


  //   const onLoginStart = useCallback(() => {
  //     alert("login start");
  //   }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const onResolve = async ({ provider, data }) => {
    setProvider(provider);

    setProfile(data);
    // Use the access_token to make an API request to get user information
    const userInfo = await fetchGoogleUserInfo(data.access_token);
  };

  const onReject = (err) => {
    console.log(err);
  };

  const fetchGoogleUserInfo = async (accessToken) => {
    const response = await fetch(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`
      // `https://www.googleapis.com/oauth2/v1/certs?access_token=${accessToken}`
    );
    const userInfo = await response.json();
    setUser(userInfo);
    // return userInfo;
  };

  const postFormDataHandler = async (payload) => {
    try {
      setIsLoading(true);
      const resp = await axios.post(baseApiUrl + "register", payload);

      setTimeout(()=>{
        setIsLoading(false);
      }, 1000);
      
      if (resp?.data?.success === true) {
        HandleSuccess(resp?.data?.message);
        // navigate("/login");
      }
    } catch (err) {
      if (
        err?.response?.data?.isInvalidCreds === true &&
        err?.response?.status === 401
      ) {
        setInvalidCredErr(err?.response?.data?.message);
        setIsLoading(false);
      } else {
        setInvalidCredErr("");
      }
    }
  };

  const signUpForm = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      let payload = {
        username: values?.username,
        email: values?.email,
        password: values?.password,
      };
      // dispatch this payload to api
      postFormDataHandler(payload);
    },
    validate: (values) => {
      let errors = {};
      if (!values.username) {
        errors.username = "Full Name is required";
      } else if (/^\s+|\s+$/.test(values.username)) {
        // Check for extra white spaces in the username
        errors.username = "Username should not contain extra spaces.";
      } else if (!/^[a-zA-Z\s]+$/.test(values.username)) {
        errors.username = "Only letters are allowed for the Full Name";
      }

      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      } else if (/^\s+|\s+$/.test(values.email)) {
        errors.email = "Email should not contain spaces.";
      }

      if (!values.password) {
        errors.password = "Password is required";
      } else if (values.password.length < 8) {
        errors.password = "Password must be at least 8 characters long";
      } else if (/^\s+|\s+$/.test(values.password)) {
        errors.password = "Password should not contain spaces.";
      }else if (/\s/.test(values.password)) {
        errors.password = "Password should not contain spaces.";
      }
      // else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(values.password)) {
      //   errors.password =
      //     "Password must contain at least one uppercase letter, one lowercase letter, and one number";
      // }

      return errors;
    },
  });

  const {values} = signUpForm;
  // console.log("USER NAME===>", values?.username)
  // console.log("USER EMAIL===>", values?.email)

  useEffect(()=>{
    if(profile && user){
      HandleSuccess("Verification completed - please set the password & click on Signup.");

      values.username = user?.name
      values.email = user?.email

    }
  }, [profile, user])

  

  return (
    <div className="signup-bg-blue">
      <Loader isLoading={isLoading}/>
      <ToastContainer />
      <div className="container">
        <div className="row p-3 overlay">
          <div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 my-auto justify-content-center text-center">
            <div className="sectionStartForm px-5 py-3">
              <img
                src="/cobalt-icon.svg"
                alt="fintrack-logo"
                className="form-header img-fluid"
              />
              {/* <p className="font-weight-bold py-2">Merchant Login</p> */}
              {invalidCredErr && invalidCredErr.length ? (
                <div className="signup-error">{invalidCredErr}</div>
              ) : null}

              <form onSubmit={signUpForm.handleSubmit}>
                
                <div className="s-form-label-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    name="username"
                    value={captitalizeEveryField(signUpForm.values.username || user?.name)}
                    onChange={signUpForm.handleChange}
                    onBlur={signUpForm.handleBlur}
                  />
                  <label>Full Name</label>
                </div>
                {signUpForm.touched.username && signUpForm.errors.username ? (
                  <div className="signup-error">
                    {signUpForm.errors.username}
                  </div>
                ) : null}

                
                <div className="s-form-label-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    value={signUpForm.values.email || user?.email}
                    onChange={signUpForm.handleChange}
                    onBlur={signUpForm.handleBlur}
                  />
                  <label>Email Address</label>
                </div>
                {signUpForm.touched.email && signUpForm.errors.email ? (
                  <div className="signup-error">{signUpForm.errors.email}</div>
                ) : null}

                
                <div className="s-form-label-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    style={{paddingRight : "40px"}}
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={signUpForm.values.password}
                    onChange={signUpForm.handleChange}
                    onBlur={signUpForm.handleBlur}
                  />
                  <label>Password</label>
                  <div
                    className="signup-password-toggle-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <MdOutlineRemoveRedEye className="signup-passwod-icon" />
                    ) : (
                      <IoMdEyeOff className="signup-passwod-icon" />
                    )}
                  </div>
                </div>
                {signUpForm.touched.password && signUpForm.errors.password ? (
                  <div className="signup-error">
                    {signUpForm.errors.password}
                  </div>
                ) : null}

                <div className="s-form-label-group mt-4">
                  <button
                    type="submit"
                    className="btn btn-info font-weight-bold text-uppercase w-50"
                  >
                    sign up
                  </button>
                </div>
              </form>
              <div className="center-or-container mt-3">
                <div className="center-or-line" />
                <div className="center-or">or</div>
              </div>

              {/* Google Signup or Login btns */}
              <LoginSocialFacebook
                isOnlyGetToken
                appId="// Paste facebook App_Id here..."
                // onLoginStart={onLoginStart}
                scope="email,public_profile"
                onResolve={({ provider, data }) => {
                  setProvider(provider);
                  setFacebookProfile(data);
                }}
                onReject={(err) => {
                  console.log(err);
                }}
              >
                {/* <FacebookLoginButton
                  style={{ height: "40px", fontSize: "16px" }}
                >
                  Sign Up with Facebook
                </FacebookLoginButton> */}
              </LoginSocialFacebook>

              <LoginSocialGoogle
                isOnlyGetToken
                client_id={GoogleClientId}
                // onLoginStart={onLoginStart}
                scope="email profile"
                onResolve={onResolve}
                // onResolve={({ provider, data }) => {
                //   setProvider(provider)
                //   setProfile(data)
                // }}
                onReject={(err) => {
                  console.log(err);
                }}
              >
                <GoogleLoginButton
                  style={{
                    height: "40px",
                    fontSize: "16px",
                    color: "gray",
                    fontWeight: "bold",
                  }}
                >
                  Sign Up with Google
                </GoogleLoginButton>
              </LoginSocialGoogle>
              <p className="sg-text text-decoration pointer">
                By signing up you accept the <a href="https://fintracking.ca/terms-of-use/" target="_blank" style={{color: "#144A90"}}>Terms of Use </a>and{" "}
                <a href="https://fintracking.ca/privacy-policy/" target="_blank" style={{color: "#144A90"}}>Privacy Policy</a>
              </p>
              <p className="sg-text text-decoration pointer">
                Already have an account?{" "}
                <span onClick={() => navigate("/login")}>Log in</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSignupPage;
